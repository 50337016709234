import React, { Component } from "react";
import Dashboard from "./Dashboard";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import { fetchDummyData } from "../actions/dashboard";

class DashboardContainter extends Component {
  state = {
    jwt: localStorage.token,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchDummyData(this.state));
  }

  render() {
    var { myuser, dashdummy } = this.props;

    if (myuser && myuser.default_dashboard && dashdummy) {
      return <Dashboard myuser={myuser} dashdummy={dashdummy} />;
    } else {
      return <LoaderUi />;
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userloading: state.auth.loading,
  myuser: state.auth.user,
  dashdummy: state.dash.items8,
  loading8: state.dash.loading8,
  error8: state.dash.error8,
});

export default connect(mapStateToProps, null)(DashboardContainter);
