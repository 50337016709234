import React from "react";
import { Link } from "react-router-dom";

import Slider from "@material-ui/core/Slider";
import { connect } from "react-redux";
import Alert from "../layout/Alert";
import {
  deactiveprofile,
  updatesp,
  updatesenderpview,
} from "../actions/senderprofiles";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import "jqwidgets-scripts/jqwidgets/styles/jqx.base.css";
import "jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css";
import JqxKnob from "jqwidgets-scripts/jqwidgets-react-tsx/jqxknob";
import {
  PROFILE_NAME_TOOLTIP,
  FROM_EMAIL_TOOLTIP,
  FOR_DEFAULT_SETTINGS,
  TRADITIONAL_WARMUP,
  DOMAIN_IP_REPAIR,
  REPITATION_BOOST,
  REPITATION_PROTECT,
  FOR_CUSTOM_SETTINGS,
  OPEN_TOOLTIP,
  SCROLL_DOWN_TOOLTIP,
  REMOVE_FROMSPAM_TOOLTIP,
  REMOVE_FROMPROMOTIONS_TOOLTIP,
  REPLY_TOOLTIP,
  MARKIMP_TOOLTIP,
  CLICK_LINK_TOOLTIP,
  EXCEPT_UNSUBCRIBE,
} from "../utils/tooltip";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class ViewSenderprofile extends React.PureComponent<{}, IBarGaugeProps> {
  constructor(props) {
    super(props);

    const styles = {
      fill: {
        color: "#fefefe",
        gradientStops: [
          [0, 1],
          [50, 0.9],
          [100, 1],
        ],
        gradientType: "linear",
      },
      stroke: "#dfe3e9",
      strokeWidth: 3,
    };
    const marks = {
      colorProgress: { border: "#00a4e1", color: "#00a4e1" },
      colorRemaining: { border: "grey", color: "grey" },
      majorInterval: 10,
      majorSize: "9%",
      minorInterval: 2,
      offset: "71%",
      size: "6%",
      thickness: 5,
    };
    const labels = {
      offset: "88%",
      step: 10,
      visible: true,
    };
    const pointer = {
      offset: "49%",
      size: "59%",
      style: { fill: "#00a4e1", stroke: "grey" },
      thickness: 20,
      type: "arrow",
    };
    const progressBar = {
      background: { fill: "grey", stroke: "grey" },
      offset: "60%",
      size: "9%",
      style: { fill: "#00a4e1", stroke: "grey" },
    };
    this.state = {
      labels: labels,
      marks: marks,
      pointer: pointer,
      progressBar: progressBar,
      styles: styles,
      totalemail: "",
      scrolldown: "",
      name: "",
      spaminbox: "",
      promotoinbox: "",
      reply: "",
      clicklink: "",
      marksimp: "",
      emailpercentage: "",
      engagement: "1",
      fromemail: "",
      linkname: "",
      linkrandam: true,
      customoption: false,
      currentstatus: this.props.currentstatus,
      buttonstatus: this.props.buttonstatus,
      userstatus: this.props.userstatus,
      validationErrors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.totalemail !== nextProps.defaultdata.totalemail) {
      return {
        engagement: nextProps.defaultdata.engagement,
        totalemail: nextProps.defaultdata.totalemail,
        scrolldown: nextProps.defaultdata.scrolldown,
        spaminbox: nextProps.defaultdata.spaminbox,
        promotoinbox: nextProps.defaultdata.promotoinbox,
        reply: nextProps.defaultdata.reply,
        clicklink: nextProps.defaultdata.clicklink,
        marksimp: nextProps.defaultdata.marksimp,
        emailpercentage: nextProps.defaultdata.emailpercentage,
        fromemail: nextProps.defaultdata.fromemail,
        linkname: nextProps.defaultdata.linkname,
        linkrandam: nextProps.defaultdata.linkrandam,
        name: nextProps.defaultdata.name,
        customoption: nextProps.defaultdata.engagement === "0" ? true : false,
      };
    }

    return null;
  }

  validators = {
    //name: (str) => this.namevalid(str),
    fromemail: (str) => (str === "" ? "Fromemail is mandatory." : ""),
    linkname: (str) => this.linkvalid(str),
  };

  linkvalid = (str) => {
    if (str === "" && this.state.linkrandam === false) {
      return "Linkname Can Blank.";
    }
  };

  namevalid = (str) => {
    if (str === "") {
      return "Profile Name is mandatory.";
    } else {
      if (this.props.senderprofiles) {
        var senderprofiles = this.props.senderprofiles;
        senderprofiles = senderprofiles.filter((item) => {
          return (
            item.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1
          );
        });

        if (senderprofiles.length > 0) {
          return "Duplicate Profile name.";
        }
      }
    }
  };

  validate = (name) => {
    const value = this.state[name];
    let error = this.validators.hasOwnProperty(name)
      ? this.validators[name](value)
      : "";
    this.setState(({ validationErrors }) => ({
      validationErrors: { ...validationErrors, [name]: error },
    }));
    return error;
  };

  onChangeknob = (e, name) => {
    this.setState({ [name]: e.args.value, customoption: true, engagement: 0 });
  };

  handleInputChange = (event, value, name) => {
    if (name === "name" || name === "fromemail") {
      this.setState({ [name]: event.currentTarget.value }, () =>
        this.validate(name)
      );
    } else if (name === "linkname") {
      this.setState({ [name]: event.currentTarget.value, linkrandam: false });
    } else if (name === "linkrandam") {
      this.setState({ linkrandam: !this.state.linkrandam, linkname: "" });
    } else {
      this.setState({ [name]: value });
    }
  };

  scrollToDiv = (e) => {
    e.preventDefault();
    window.scrollTo(0, 380);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const isValid = Object.keys(this.validators)
      .map(this.validate)
      .every((err) => !err);
    if (isValid) {
      window.scrollTo(0, 0);

      this.props.onUpdate({
        name: this.state.name,
        scrolldown: this.state.scrolldown,
        spaminbox: this.state.spaminbox,
        promotoinbox: this.state.promotoinbox,
        reply: this.state.reply,
        clicklink: this.state.clicklink,
        markimp: this.state.marksimp,
        emailpercentage: this.state.emailpercentage,
        fromemail: this.state.fromemail,
        linkname: this.state.linkname,
        linkrandom: this.state.linkrandam,
        jwt: localStorage.token,
        engagement: this.state.engagement,
        id: this.props.id,
      });
    }
  };

  handleSubmit2 = (e) => {
    e.preventDefault();

    const isValid = Object.keys(this.validators)
      .map(this.validate)
      .every((err) => !err);
    if (isValid) {
      this.props.onUpdateV({
        name: this.state.name,
        scrolldown: this.state.scrolldown,
        spaminbox: this.state.spaminbox,
        promotoinbox: this.state.promotoinbox,
        reply: this.state.reply,
        clicklink: this.state.clicklink,
        markimp: this.state.marksimp,
        emailpercentage: this.state.emailpercentage,
        fromemail: this.state.fromemail,
        linkname: this.state.linkname,
        linkrandom: this.state.linkrandam,
        jwt: localStorage.token,
        engagement: this.state.engagement,
        id: this.props.id,
      });
    }
  };

  handleSubmit3 = (e) => {
    var changestatus;
    if (this.state.currentstatus === "1") {
      changestatus = "deactivate";
    }
    if (this.state.currentstatus === "0") {
      changestatus = "activate";
    }

    const isValid = Object.keys(this.validators)
      .map(this.validate)
      .every((err) => !err);
    if (isValid) {
      confirmAlert({
        title: "",
        message:
          "Are you sure you want to " +
          changestatus +
          " this sender profile ? ",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.onDelete({
                id: this.props.id,
                jwt: localStorage.token,
              });
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  handleradioChange = (e) => {
    var value = e.currentTarget.value;

    if (value === 1) {
      this.refs.myscrolldownKnob.val(this.props.preset[0][1].scrolldown);
      this.refs.myspaminboxKnob.val(this.props.preset[0][1].spaminbox);
      this.refs.mypromotoinboxKnob.val(this.props.preset[0][1].promotoinbox);
      this.refs.myreplyKnob.val(this.props.preset[0][1].reply);
      this.refs.mymarksimpKnob.val(this.props.preset[0][1].marksimp);
      this.refs.myclicklinkKnob.val(this.props.preset[0][1].clicklink);
      this.setState({
        totalemail: this.props.preset[0][1].totalemail,
        scrolldown: this.props.preset[0][1].scrolldown,
        spaminbox: this.props.preset[0][1].spaminbox,
        promotoinbox: this.props.preset[0][1].promotoinbox,
        reply: this.props.preset[0][1].reply,
        clicklink: this.props.preset[0][1].clicklink,
        marksimp: this.props.preset[0][1].marksimp,
        emailpercentage: this.props.preset[0][1].emailpercentage,
        engagement: 1,
        customoption: false,
      });
    }

    if (value === 2) {
      this.refs.myscrolldownKnob.val(this.props.preset[1][2].scrolldown);
      this.refs.myspaminboxKnob.val(this.props.preset[1][2].spaminbox);
      this.refs.mypromotoinboxKnob.val(this.props.preset[1][2].promotoinbox);
      this.refs.myreplyKnob.val(this.props.preset[1][2].reply);
      this.refs.mymarksimpKnob.val(this.props.preset[1][2].marksimp);
      this.refs.myclicklinkKnob.val(this.props.preset[1][2].clicklink);
      this.setState({
        totalemail: this.props.preset[1][2].totalemail,
        scrolldown: this.props.preset[1][2].scrolldown,
        spaminbox: this.props.preset[1][2].spaminbox,
        promotoinbox: this.props.preset[1][2].promotoinbox,
        reply: this.props.preset[1][2].reply,
        clicklink: this.props.preset[1][2].clicklink,
        marksimp: this.props.preset[1][2].marksimp,
        emailpercentage: this.props.preset[1][2].emailpercentage,
        engagement: 2,
        customoption: false,
      });
    }

    if (value === 3) {
      this.refs.myscrolldownKnob.val(this.props.preset[2][3].scrolldown);
      this.refs.myspaminboxKnob.val(this.props.preset[2][3].spaminbox);
      this.refs.mypromotoinboxKnob.val(this.props.preset[2][3].promotoinbox);
      this.refs.myreplyKnob.val(this.props.preset[2][3].reply);
      this.refs.mymarksimpKnob.val(this.props.preset[2][3].marksimp);
      this.refs.myclicklinkKnob.val(this.props.preset[2][3].clicklink);
      this.setState({
        totalemail: this.props.preset[2][3].totalemail,
        scrolldown: this.props.preset[2][3].scrolldown,
        spaminbox: this.props.preset[2][3].spaminbox,
        promotoinbox: this.props.preset[2][3].promotoinbox,
        reply: this.props.preset[2][3].reply,
        clicklink: this.props.preset[2][3].clicklink,
        marksimp: this.props.preset[2][3].marksimp,
        emailpercentage: this.props.preset[2][3].emailpercentage,
        engagement: 3,
        customoption: false,
      });
    }
    if (value === 4) {
      this.refs.myscrolldownKnob.val(this.props.preset[3][4].scrolldown);
      this.refs.myspaminboxKnob.val(this.props.preset[3][4].spaminbox);
      this.refs.mypromotoinboxKnob.val(this.props.preset[3][4].promotoinbox);
      this.refs.myreplyKnob.val(this.props.preset[3][4].reply);
      this.refs.mymarksimpKnob.val(this.props.preset[3][4].marksimp);
      this.refs.myclicklinkKnob.val(this.props.preset[3][4].clicklink);
      this.setState({
        totalemail: this.props.preset[3][4].totalemail,
        scrolldown: this.props.preset[3][4].scrolldown,
        spaminbox: this.props.preset[3][4].spaminbox,
        promotoinbox: this.props.preset[3][4].promotoinbox,
        reply: this.props.preset[3][4].reply,
        clicklink: this.props.preset[3][4].clicklink,
        marksimp: this.props.preset[3][4].marksimp,
        emailpercentage: this.props.preset[3][4].emailpercentage,
        engagement: 4,
        customoption: false,
      });
    }

    if (value === 5) {
      this.refs.myscrolldownKnob.val(this.props.preset[4][5].scrolldown);
      this.refs.myspaminboxKnob.val(this.props.preset[4][5].spaminbox);
      this.refs.mypromotoinboxKnob.val(this.props.preset[4][5].promotoinbox);
      this.refs.myreplyKnob.val(this.props.preset[4][5].reply);
      this.refs.mymarksimpKnob.val(this.props.preset[4][5].marksimp);
      this.refs.myclicklinkKnob.val(this.props.preset[4][5].clicklink);
      this.setState({
        totalemail: this.props.preset[4][5].totalemail,
        scrolldown: this.props.preset[4][5].scrolldown,
        spaminbox: this.props.preset[4][5].spaminbox,
        promotoinbox: this.props.preset[4][5].promotoinbox,
        reply: this.props.preset[4][5].reply,
        clicklink: this.props.preset[4][5].clicklink,
        marksimp: this.props.preset[4][5].marksimp,
        emailpercentage: this.props.preset[4][5].emailpercentage,
        engagement: 5,
        customoption: false,
      });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.name !== undefined) {
      window.userengage("event.Viewed_view_sender_profile", {
        Sender_profile_name: this.state.name,
      });
    }

    this.refs.myscrolldownKnob.val(this.state.scrolldown);
    this.refs.myspaminboxKnob.val(this.state.spaminbox);
    this.refs.mypromotoinboxKnob.val(this.state.promotoinbox);
    this.refs.myreplyKnob.val(this.state.reply);
    this.refs.mymarksimpKnob.val(this.state.marksimp);
    this.refs.myclicklinkKnob.val(this.state.clicklink);
    this.setState({
      engagement: this.props.defaultdata.engagement,
      customoption: this.props.defaultdata.engagement === "0" ? true : false,
    });
  }

  render() {
    if (this.props.preset[5]) {
      var fromemaildata = this.props.preset[6].email;
      fromemaildata.push(this.state.fromemail);
    }

    const marks = [
      {
        value: 0,
        label: "0%",
      },

      {
        value: 100,
        label: "100%",
      },
    ];

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> View Sender Profile</h3>
          <Link to="/senderprofiles" className="btn btn-primary float-right">
            Back to Sender Profiles
          </Link>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row grid-margin">
                  <div className="col-7 mb-1">
                    <Alert msgdivid="updatesenderp" />
                  </div>

                  <div className="col-md-12 col-sm-12">
                    <p>
                      A sender profile is used to specify engagement rules for
                      your emails when we receive them. Typically, you should
                      have a separate profile for every email you send from and
                      if you have dedicated IPs, perhaps one for each IP as
                      well.
                    </p>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-5 col-sm-12">
                    <input
                      type="text"
                      className="form-control form-width"
                      name="name"
                      placeholder="Enter Sender profile name"
                      onChange={(e, val) =>
                        this.handleInputChange(e, val, "name")
                      }
                      value={this.state.name}
                      readOnly
                    />
                    <HtmlTooltip
                      title={PROFILE_NAME_TOOLTIP}
                      placement="top-start"
                    >
                      <span className="">
                        <InfoOutlinedIcon className="toottipcls" />
                      </span>
                    </HtmlTooltip>

                    <div style={{ fontSize: 14, color: "red" }}>
                      {this.state.validationErrors.name}
                    </div>
                  </div>

                  <div className="col-md-5 col-sm-12 ">
                    <input
                      type="text"
                      className="form-control form-width"
                      name="fromemail"
                      placeholder="Enter Profile Name"
                      value={this.state.fromemail}
                      readOnly
                    />

                    <HtmlTooltip
                      title={FROM_EMAIL_TOOLTIP}
                      placement="top-start"
                    >
                      <span className="">
                        <InfoOutlinedIcon className="toottipcls" />
                      </span>
                    </HtmlTooltip>

                    <div style={{ fontSize: 14, color: "red" }}>
                      {this.state.validationErrors.fromemail}
                    </div>

                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.scrollToDiv}
                      >
                        Confirm above information is correct
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 float-center">
                <span ref={this.myRef}></span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body margin7padding">
                <h4>Select Engagement Rules </h4>
                <p>
                  Specify what engagement actions you want taken for the seed
                  emails in your broadcasts. Just choose one of the presets
                  below which best suits your needs. Then, you can further tweak
                  the settings. You can also just skip the presets and jump
                  right to the settings.
                </p>
                <b>Presets </b>
                <div className="row mt-3 mb-4">
                  <div className="ml-3" style={{ marginRight: "2%" }}>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="engagement"
                      value="1"
                      id="membershipRadios1"
                      checked={this.state.engagement === "1"}
                    />
                    <b className="ml-3">Default&nbsp;</b>
                    <HtmlTooltip
                      title={FOR_DEFAULT_SETTINGS}
                      placement="top-start"
                    >
                      <span className="">
                        <InfoOutlinedIcon className="toottipcls" />
                      </span>
                    </HtmlTooltip>
                  </div>

                  <div className="" style={{ marginRight: "2%" }}>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="engagement"
                      value="2"
                      id="membershipRadios2"
                      checked={this.state.engagement === "2"}
                    />
                    <b className="ml-3">Traditional warmup</b>
                    <HtmlTooltip
                      title={TRADITIONAL_WARMUP}
                      placement="top-start"
                    >
                      <span className="">
                        {" "}
                        <InfoOutlinedIcon className="toottipcls" />
                      </span>
                    </HtmlTooltip>
                  </div>
                  <div className="" style={{ marginRight: "2%" }}>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="engagement"
                      value="3"
                      id="membershipRadios3"
                      checked={this.state.engagement === "3"}
                    />
                    <b className="ml-3">Domain & Ip reputation repair</b>
                    <HtmlTooltip title={DOMAIN_IP_REPAIR} placement="top-start">
                      <span className="">
                        {" "}
                        <InfoOutlinedIcon className="toottipcls" />
                      </span>
                    </HtmlTooltip>
                  </div>

                  <div className="" style={{ marginRight: "2%" }}>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="engagement"
                      value="4"
                      id="membershipRadios4"
                      checked={this.state.engagement === "4"}
                    />
                    <b className="ml-3">Reputation boost</b>
                    <HtmlTooltip title={REPITATION_BOOST} placement="top-start">
                      <span className="">
                        {" "}
                        <InfoOutlinedIcon className="toottipcls" />{" "}
                      </span>
                    </HtmlTooltip>
                  </div>
                  <div className="" style={{ marginRight: "2%" }}>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="engagement"
                      value="5"
                      id="membershipRadios5"
                      checked={this.state.engagement === "5"}
                    />
                    <b className="ml-3">Reputation protect</b>
                    <HtmlTooltip
                      title={REPITATION_PROTECT}
                      placement="top-start"
                    >
                      <span className="">
                        {" "}
                        <InfoOutlinedIcon className="toottipcls" />{" "}
                      </span>
                    </HtmlTooltip>
                  </div>

                  {this.state.customoption && (
                    <div className="" style={{ marginRight: "2%" }}>
                      <input
                        type="radio"
                        className="form-check-input"
                        name="engagement"
                        value="0"
                        id="membershipRadios0"
                        checked={this.state.engagement === "0"}
                      />
                      <b className="ml-3">Custom&nbsp;</b>
                      <HtmlTooltip
                        title={FOR_CUSTOM_SETTINGS}
                        placement="top-start"
                      >
                        <span className="">
                          <InfoOutlinedIcon className="toottipcls" />
                        </span>
                      </HtmlTooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="row mt-2">
                <div className="col-md-6 col-sm-6 ">
                  <p className="margin7padding">
                    <b>
                      Open{" "}
                      <HtmlTooltip title={OPEN_TOOLTIP} placement="top-start">
                        <span className="">
                          <InfoOutlinedIcon className="toottipcls" />
                        </span>
                      </HtmlTooltip>
                    </b>{" "}
                    &nbsp; - &nbsp; Choose the percentage of your{" "}
                    {this.state.totalemail}* emails to be opened.
                  </p>
                </div>
                <div className="col-md-6 col-sm-6">
                  <p className=" margin7padding">
                    <Slider
                      value={
                        this.state.emailpercentage
                          ? this.state.emailpercentage
                          : 0
                      }
                      aria-labelledby="discrete-slider-always"
                      step={10}
                      marks={marks}
                      valueLabelDisplay="on"
                      onChange={(e, val) =>
                        this.handleInputChange(e, val, "emailpercentage")
                      }
                      disabled
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body margin4padding">
                <div className="col-md-12 text-center col-sm-12 stretch-card">
                  <div className="mb-3 mt-2" style={{ "margin-left": "5%" }}>
                    <h6 className="text-center zeromarginpadding">
                      <b>
                        {" "}
                        Choose the percentage of each engagement type to be
                        applied to the{" "}
                        {Math.round(
                          (this.state.totalemail * this.state.emailpercentage) /
                            100
                        )}{" "}
                        emails (as specified above) that will be opened.
                      </b>
                    </h6>
                  </div>
                </div>
                <b className="mt-2 ml-1">Settings</b>
              </div>
              <div className="row mt-2">
                <div className="col-md-2 col-sm-6 grid-margin stretch-card">
                  <div className="">
                    <div className="">
                      <div className="col-md-12">
                        <p className="pfont-center">
                          <b>
                            Scroll Down{" "}
                            <HtmlTooltip
                              title={SCROLL_DOWN_TOOLTIP}
                              placement="top-start"
                            >
                              <span className="">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </b>
                        </p>
                        <p className="text-center">
                          <JqxKnob
                            ref="myscrolldownKnob"
                            width={"100%"}
                            height={"150px"}
                            min={0}
                            max={100}
                            startAngle={120}
                            endAngle={420}
                            snapToStep={true}
                            rotation={"clockwise"}
                            styles={this.state.styles}
                            marks={this.state.marks}
                            labels={this.state.labels}
                            pointer={this.state.pointer}
                            progressBar={this.state.progressBar}
                            name="scrolldown"
                            disabled={true}
                          />
                          <img
                            alt="scrolldown"
                            src={require("../../assets/images/scroll_down.svg")}
                            className="jqximg1"
                            style={{ width: 50 }}
                          />
                          Will be applied to{" "}
                          {Math.round(
                            (((this.state.totalemail *
                              this.state.emailpercentage) /
                              100) *
                              this.state.scrolldown) /
                              100
                          )}{" "}
                          emails*.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2 col-sm-6 grid-margin stretch-card">
                  <div className="">
                    <div className="">
                      <div className="col-md-12">
                        <p className="pfont-center">
                          <b>
                            Remove from Spam{" "}
                            <HtmlTooltip
                              title={REMOVE_FROMSPAM_TOOLTIP}
                              placement="top-start"
                            >
                              <span className="">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </b>
                        </p>

                        <p className="text-center">
                          <JqxKnob
                            ref="myspaminboxKnob"
                            width={"100%"}
                            height={"150px"}
                            min={0}
                            max={100}
                            startAngle={120}
                            endAngle={420}
                            snapToStep={true}
                            rotation={"clockwise"}
                            styles={this.state.styles}
                            marks={this.state.marks}
                            labels={this.state.labels}
                            pointer={this.state.pointer}
                            progressBar={this.state.progressBar}
                            name="scrolldown"
                            disabled={true}
                          />
                          <img
                            alt="movefromspam"
                            src={require("../../assets/images/move_from_spam.svg")}
                            className="jqximg1"
                            style={{ width: 50 }}
                          />
                          Will be applied to{" "}
                          {Math.round(
                            (((this.state.totalemail *
                              this.state.emailpercentage) /
                              100) *
                              this.state.spaminbox) /
                              100
                          )}{" "}
                          emails*.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2 col-sm-6 grid-margin stretch-card">
                  <div className="">
                    <div className="">
                      <div className="col-md-12">
                        <p className="pfont-center">
                          <b>
                            Remove from Promotions{" "}
                            <HtmlTooltip
                              title={REMOVE_FROMPROMOTIONS_TOOLTIP}
                              placement="top-start"
                            >
                              <span className="">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </b>
                        </p>

                        <p className="text-center">
                          <JqxKnob
                            ref="mypromotoinboxKnob"
                            width={"100%"}
                            height={"150px"}
                            min={0}
                            max={100}
                            startAngle={120}
                            endAngle={420}
                            snapToStep={true}
                            rotation={"clockwise"}
                            styles={this.state.styles}
                            marks={this.state.marks}
                            labels={this.state.labels}
                            pointer={this.state.pointer}
                            progressBar={this.state.progressBar}
                            name="scrolldown"
                            disabled={true}
                          />
                          <img
                            alt="movefrompromo"
                            src={require("../../assets/images/move_from_promo.svg")}
                            className="jqximg1"
                            style={{ width: 50 }}
                          />
                          Will be applied to{" "}
                          {Math.round(
                            (((this.state.totalemail *
                              this.state.emailpercentage) /
                              100) *
                              this.state.promotoinbox) /
                              100
                          )}{" "}
                          emails*.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2 col-sm-6 grid-margin stretch-card">
                  <div className="">
                    <div className="">
                      <div className="col-md-12">
                        <p className="pfont-center">
                          <b>
                            Reply{" "}
                            <HtmlTooltip
                              title={REPLY_TOOLTIP}
                              placement="top-start"
                            >
                              <span className="">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </b>
                        </p>
                        <p className="text-center">
                          <JqxKnob
                            ref="myreplyKnob"
                            width={"100%"}
                            height={"150px"}
                            min={0}
                            max={100}
                            startAngle={120}
                            endAngle={420}
                            snapToStep={true}
                            rotation={"clockwise"}
                            styles={this.state.styles}
                            marks={this.state.marks}
                            labels={this.state.labels}
                            pointer={this.state.pointer}
                            progressBar={this.state.progressBar}
                            name="reply"
                            disabled={true}
                          />
                          <img
                            alt="reply"
                            src={require("../../assets/images/reply.svg")}
                            className="jqximg1"
                            style={{ width: 50 }}
                          />
                          Will be applied to{" "}
                          {Math.round(
                            (((this.state.totalemail *
                              this.state.emailpercentage) /
                              100) *
                              this.state.reply) /
                              100
                          )}{" "}
                          emails*.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2 col-sm-6 grid-margin stretch-card">
                  <div className="">
                    <div className="">
                      <div className="col-md-12">
                        <p className="pfont-center">
                          <b>
                            Mark Important{" "}
                            <HtmlTooltip
                              title={MARKIMP_TOOLTIP}
                              placement="top-start"
                            >
                              <span className="">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </b>
                        </p>

                        <p className="text-center">
                          <JqxKnob
                            ref="mymarksimpKnob"
                            width={"100%"}
                            height={"150px"}
                            min={0}
                            max={100}
                            startAngle={120}
                            endAngle={420}
                            snapToStep={true}
                            rotation={"clockwise"}
                            styles={this.state.styles}
                            marks={this.state.marks}
                            labels={this.state.labels}
                            pointer={this.state.pointer}
                            progressBar={this.state.progressBar}
                            name="marksimp"
                            disabled={true}
                          />
                          <img
                            alt="markimp"
                            src={require("../../assets/images/mark_important.svg")}
                            className="jqximg1"
                            style={{ width: 50 }}
                          />
                          Will be applied to{" "}
                          {Math.round(
                            (((this.state.totalemail *
                              this.state.emailpercentage) /
                              100) *
                              this.state.marksimp) /
                              100
                          )}{" "}
                          emails*.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-sm-6 grid-margin stretch-card">
                  <div>
                    <div>
                      <div className="col-md-12">
                        <p className="pfont-center">
                          <b>
                            Click Link{" "}
                            <HtmlTooltip
                              title={CLICK_LINK_TOOLTIP}
                              placement="top-start"
                            >
                              <span className="">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </b>
                        </p>
                        <p className="text-center">
                          <JqxKnob
                            ref="myclicklinkKnob"
                            width={"100%"}
                            height={"150px"}
                            min={0}
                            max={100}
                            startAngle={120}
                            endAngle={420}
                            snapToStep={true}
                            rotation={"clockwise"}
                            styles={this.state.styles}
                            marks={this.state.marks}
                            labels={this.state.labels}
                            pointer={this.state.pointer}
                            progressBar={this.state.progressBar}
                            name="clicklink"
                            disabled={true}
                          />
                          <img
                            alt="clicklink"
                            src={require("../../assets/images/click_link.svg")}
                            className="jqximg1"
                            style={{ width: 50 }}
                          />
                          Will be applied to{" "}
                          {Math.round(
                            (((this.state.totalemail *
                              this.state.emailpercentage) /
                              100) *
                              this.state.clicklink) /
                              100
                          )}{" "}
                          emails*.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body margin4padding">
                <div className="row col-md-12">
                  <p
                    className="row col-md-12 "
                    style={{ "text-align": "center", "margin-left": "20%" }}
                  >
                    <b>
                      For engagement type "click link", Please uncheck the box
                      below to specify a specific link to click.
                    </b>
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      type="checkbox"
                      className="form-check-input ml-4"
                      name="linkrandam"
                      checked={this.state.linkrandam}
                      onChange={(e, val) =>
                        this.handleInputChange(e, val, "linkrandam")
                      }
                    />
                    <span className="float-left ml-5">
                      Have a link choosen randomly
                      <HtmlTooltip
                        title={EXCEPT_UNSUBCRIBE}
                        placement="top-start"
                      >
                        <span className="">
                          <InfoOutlinedIcon className="toottipcls ml-1" />
                        </span>
                      </HtmlTooltip>
                    </span>
                    <div style={{ fontSize: 14, color: "red" }}>
                      {this.state.validationErrors.linkrandam}
                    </div>
                  </div>
                  <div className="col-md-2 text-center">OR</div>
                  <div className="col-md-6">
                    <div className="form-row form-group ml-2 mr-5">
                      <p>
                        Enter any text contained within the link you wish to
                        click
                      </p>
                      <input
                        type="text"
                        className="form-control form-control"
                        name="linkname"
                        placeholder=""
                        value={this.state.linkname}
                        onChange={(e, val) =>
                          this.handleInputChange(e, val, "linkname")
                        }
                        readOnly
                      />
                      <div style={{ fontSize: 14, color: "red" }}>
                        {this.state.validationErrors.linkname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body margin15padding">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>

                  {this.state.buttonstatus &&
                    this.state.userstatus &&
                    this.state.currentstatus === "0" && (
                      <div className="col-md-4 btnpd text-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleSubmit3}
                        >
                          Activate Profile
                        </button>
                      </div>
                    )}

                  {this.state.currentstatus === "1" && this.state.userstatus && (
                    <div className="col-md-4 btnpd text-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleSubmit3}
                      >
                        Deactivate Profile
                      </button>
                    </div>
                  )}

                  <div className="col-md-12 mt-2">
                    <p>
                      {" "}
                      <span>* </span> These numbers are not 100% accurate as
                      real numbers can be less than what is being displayed
                      since our engagement algorithm is not 100% accurate.We are
                      continuously working on improving it accuracy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (userdata) => {
      dispatch(updatesp(userdata));
    },

    onDelete: (userdata) => {
      dispatch(deactiveprofile(userdata));
    },
    onUpdateV: (userdata) => {
      dispatch(updatesenderpview(userdata));
    },

    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(ViewSenderprofile);
