import React, { Component } from "react";
import { connect } from "react-redux";
import { inviteCodeCheck } from "../actions/invitecode";
import Alert from "../layout/Alert";
import {
  BUSINESS_EMAIL_CONTINUE_VALIDATION,
  VALID_BUSINESS_EMAIL_VALIDATION,
  ONLY_BUSINESS_EMAIL_VALIDATION,
} from "../utils/message";

export class InviteContainter extends Component {
  state = {
    invitecode: "",
    plan_id: this.props.plan_id,
    email: "",
    validationErrors: {},
  };

  validators = {
    invitecode: (str) => (str === "" ? "Enter your invite code" : ""),
    email: (str) => this.emailvalid(str),
  };

  emailvalid = (str) => {
    const re = /.+@.+\..+/;
    if (str === "") {
      return BUSINESS_EMAIL_CONTINUE_VALIDATION;
    } else if (!str.match(re)) {
      return VALID_BUSINESS_EMAIL_VALIDATION;
    } else {
      var stru = str.split("@")[1];

      if (this.props.blockeddomain) {
        var blockeddomain = this.props.blockeddomain;
        blockeddomain = blockeddomain.filter((item) => {
          return (
            item.domain_name
              .toString()
              .toLowerCase()
              .indexOf(stru.toLowerCase()) !== -1
          );
        });

        if (blockeddomain.length > 0) {
          return ONLY_BUSINESS_EMAIL_VALIDATION;
        }
      }
    }
  };

  validate = (name) => {
    const value = this.state[name];
    let error = this.validators.hasOwnProperty(name)
      ? this.validators[name](value)
      : "";
    this.setState(({ validationErrors }) => ({
      validationErrors: { ...validationErrors, [name]: error },
    }));
    return error;
  };

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value }, () =>
      this.validate(fieldName)
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const isValid = Object.keys(this.validators)
      .map(this.validate)
      .every((err) => !err);
    if (isValid) {
      this.props.onCheckCode(this.state);
    }
  };

  handleReset = () => {
    this.setState({
      email: "",
      password: "",
      validationErrors: {},
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-5 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="text-center">
                  <img
                    src={require("../../assets/images/" +
                      process.env.REACT_APP_NAME_REACT_LOGO)}
                    width="75%"
                    className="center"
                    alt="logo"
                  />
                </div>
                <h4 className="text-center mt-1">Register for free trial</h4>
                <p className="text-center" style={{ paddingBottom: "0px" }}>
                  This registration is limited for users with invitation code.{" "}
                  <br /> Signing up is easy. It only takes a few seconds.
                  <br />
                  No credit card needed.
                </p>

                {/* <h4 className="text-center mt-1">Enter your invite code</h4> */}

                <Alert msgdivid="invitediv" />
                <br />
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="email"
                      placeholder="Enter your business email"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                    />
                    <div style={{ fontSize: 14, color: "red" }}>
                      {this.state.validationErrors.email}
                    </div>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="invitecode"
                      placeholder="Enter your invite code"
                      onChange={this.handleInputChange}
                      value={this.state.invitecode}
                    />
                    <div style={{ fontSize: 14, color: "red" }}>
                      {this.state.validationErrors.invitecode}
                    </div>
                  </div>

                  <div className="mt-3">
                    <button type="submit" className="btn btn-sm btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckCode: (userdata) => {
      dispatch(inviteCodeCheck(userdata));
    },
  };
};

export default connect(null, mapDispatchToProps)(InviteContainter);
