import React, { Component } from "react";
import Senderemail from "./Senderemail";
import { fetchBD } from "../actions/blockeddomain";
import { connect } from "react-redux";

class SenderEmailContainter extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchBD());
  }

  render() {
    var { blockeddomain } = this.props;

    var id = this.props.match.params.q ? this.props.match.params.q : "";

    return <Senderemail blockeddomain={blockeddomain} id={id} />;
  }
}

const mapStateToProps = (state) => ({
  blockeddomain: state.blockeddomain.items,
  loading: state.blockeddomain.loading,
  error: state.blockeddomain.error,
});

export default connect(mapStateToProps, null)(SenderEmailContainter);
