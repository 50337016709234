import {
  FETCH_MYEMAIL_BEGIN,
  FETCH_MYEMAIL_SUCCESS,
  FETCH_MYEMAIL_FAILURE,
  SHUFFLE_BEGIN,
  SHUFFLE_SUCCESS,
  SHUFFLE_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
  items2: [],
  error2: null,
};

export default function myEmailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MYEMAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_MYEMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.myemails,
      };

    case FETCH_MYEMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case SHUFFLE_BEGIN:
      return {
        ...state,
        loading: true,
        error2: null,
      };

    case SHUFFLE_SUCCESS:
      return {
        ...state,
        loading: false,
        items2: action.payload.shuffle,
      };

    case SHUFFLE_FAILURE:
      return {
        ...state,
        loading: false,
        error2: action.payload.error,
        items2: [],
      };

    default:
      return state;
  }
}
