import {
  FETCH_BROADCAST_BEGIN,
  FETCH_BROADCAST_SUCCESS,
  FETCH_BROADCAST_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
};

export default function mybroadcastReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BROADCAST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_BROADCAST_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.broadcast,
      };

    case FETCH_BROADCAST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
