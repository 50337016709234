import {
  FETCH_BLOCKED_DOMAIN_BEGIN,
  FETCH_BLOCKED_DOMAIN_SUCCESS,
  FETCH_BLOCKED_DOMAIN_FAILURE,
  BASE_URL_NODE_LIVE
} from "./types";
import { setAlert } from "./alert";
import axios from "axios";
const qs = require('qs');
const apiUrlLive = BASE_URL_NODE_LIVE;
export const fetchBDBegin = () => ({
  type: FETCH_BLOCKED_DOMAIN_BEGIN,
});

export const fetchBDSuccess = (blockeddomain) => {
  return {
    type: FETCH_BLOCKED_DOMAIN_SUCCESS,
    payload: { blockeddomain },
  };
};

export const fetchBDFailure = (error) => ({
  type: FETCH_BLOCKED_DOMAIN_FAILURE,
  payload: { error },
});

export const fetchBD = () => {
  return (dispatch) => {
    dispatch(fetchBDBegin());
   dispatch(setAlert("", "", "registerdiv"));
    return axios
      .post(apiUrlLive + "table_columnsdata.php",qs.stringify({table_name:'blocked_domain'}))
      .then((response) => {
        dispatch(fetchBDSuccess(response.data.data));
      })
      .catch((error) => dispatch(fetchBDFailure(error)));
  };
};
