import React, { Component } from "react";
import AutomateSenderprofile from "./AutomateSenderprofile";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import { fetchMyemails } from "../actions/myemails";

class AutomateSenderprofileContainter extends Component {
  state = {
    jwt: localStorage.token,
    id: this.props.match.params.id,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.dispatch(fetchMyemails(this.state));
  }

  render() {
    var { loading5, myemails } = this.props;

    if (loading5) {
      return <LoaderUi />;
    }

    return <AutomateSenderprofile myemails={myemails} />;
  }
}

const mapStateToProps = (state) => ({
  myemails: state.myemails.items,
  loading5: state.myemails.loading,
  error5: state.myemails.error,
});

export default connect(mapStateToProps, null)(AutomateSenderprofileContainter);
