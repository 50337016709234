import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "../utils/theme";
import { fetchBroadcast, fetchFilterBroadcast } from "../actions/broadcast";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { fetchDummyData } from "../actions/dashboard";
import {
  SENDER_PROFILE_TOOLTIP,
  SENDER_PRIMARYINBOXEDBROD,
} from "../utils/tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { fetchMyemails } from "../actions/myemails";
import { CSVLink } from "react-csv";
import { fetchChartReports } from "../actions/reports";
import Multilinechart from "../charts/MultilinereportchartContainter";
import { Doughnut, HorizontalBar } from "react-chartjs-2";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export class DeliverabilityTracker extends Component {
  constructor() {
    super();
    this.state = {
      showHide: false,
      jwt: localStorage.token,
      email_type: "",
      type: "All",
      value: 0,
      tracking: "Yes",
    };
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchBroadcast(this.state));
    this.props.dispatch(fetchDummyData(this.state));
    this.props.dispatch(fetchMyemails(this.state));
    this.props.dispatch(fetchChartReports(this.state));
  }

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value });
  };

  handleChange2 = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.email_type !== prevState.email_type) {
      this.props.onFilter(this.state);
    }
  }

  render() {
    var {
      senderprofiles,
      senderprofilesloading,
      loading,
      broadcast,
      dashdummy,
      loading8,
      myemails,
      myemailsloading,
      chartreports,
      classes,
    } = this.props;

    if (broadcast.data && dashdummy.message) {
      var broadcastupdated = broadcast.data.map((item, key) => {
        return item;
      });
    }

    const seedcolumns = ["Email", "First Name", "Last Name"];

    const columns = [
      {
        name: "Sender profile",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[0]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[0]
                      ? tableMeta.rowData[0].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              key={2}
              className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root MUIDataTableHeadCell-fixedHeader"
              style={{ cursor: "pointer" }}
            >
              <HtmlTooltip title={SENDER_PROFILE_TOOLTIP} placement="top-start">
                <span className="">{columnMeta.name}</span>
              </HtmlTooltip>
            </th>
          ),
        },
      },
      {
        name: "Subject",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[1]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[1]
                      ? tableMeta.rowData[1].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      {
        name: "From Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[2]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[2]
                      ? tableMeta.rowData[2].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      "Date",
      "Number Emails",
      "Inboxed Primary",
      "Inboxed Promo",
      "In Spam",

      {
        name: "Percent Inboxed",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[8]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[8] ? tableMeta.rowData[8] : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              key={5}
              className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root MUIDataTableHeadCell-fixedHeader"
              style={{ cursor: "pointer" }}
            >
              <HtmlTooltip
                title={SENDER_PRIMARYINBOXEDBROD}
                placement="top-start"
              >
                <span className="">
                  Percent Inboxed <InfoOutlinedIcon className="toottipcls" />
                </span>
              </HtmlTooltip>
            </th>
          ),
        },
      },

      {
        name: "1st Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[9]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[9]
                      ? tableMeta.rowData[9].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      {
        name: "Last Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip
                  title={tableMeta.rowData[10]}
                  placement="top-start"
                >
                  <span className="">
                    {tableMeta.rowData[10]
                      ? tableMeta.rowData[10].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
    ];

    if (loading || loading8 || myemailsloading || senderprofilesloading) {
      return <LoaderUi />;
    }

    const options = {
      filterType: "checkbox",
      filter: false,
      viewColumns: false,
      elevation: 0,
      selectableRows: "none",
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: "450px",
      download: false,
      print: false,
    };

    if (myemails.data) {
      var farray = myemails.data.map((obj) => Object.values(obj));

      var uniquearrayresult = new Set();
      var key = "type";
      myemails.data.forEach(function (item) {
        if (item.hasOwnProperty(key)) {
          uniquearrayresult.add(item[key]);
        }
      });

      var uniqueservice_pro = [...uniquearrayresult];
      var countservice_pro = uniqueservice_pro.length;
    }
    if (this.state.type !== "All") {
      var filterdata = [];
      for (let i = 0; i < myemails.data.length; i++) {
        if (myemails.data[i].type === this.state.type) {
          filterdata.push(myemails.data[i]);
        }
      }
    }

    const headers = [
      { label: "Email", key: "email" },
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Status", key: "status" },
    ];

    var doughnutPieData = {
      datasets: [
        {
          data: [
            chartreports.total_primary_inbox,
            chartreports.total_promotions,
            chartreports.total_spam,
          ],
          backgroundColor: [
            "rgb(93, 146, 244)",
            "rgba(255,183,15,1)",
            "rgba(255,99,88,1)",
          ],
        },
      ],
      labels: ["Primary Inbox", "Promotions", "Spam"],
    };

    const horizontalbar = {
      labels: ["Inbox", "Primary Inbox", "Promotions", "Spam", "Total Emails"],

      datasets: [
        {
          label: "",
          backgroundColor: [
            "rgb(0, 208, 189)",
            "rgb(93, 146, 244)",
            "rgba(255,183,15,1)",
            "rgba(255,99,88,1)",
            "rgb(128, 128, 128)",
          ],
          data: [
            chartreports.total_inbox,
            chartreports.total_primary_inbox,
            chartreports.total_promotions,
            chartreports.total_spam,
            chartreports.total_email,
          ],
        },
      ],
    };

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Deliverability Tester</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              Deliverability Tester helps you track where your emails are
              landing in real-world emails as these tester seed emails are
              configured to not engage with your campaigns.{" "}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <AppBar position="static" color="default" className={classes.root}>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange2}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
              >
                <Tab label="Brodcasts For Tester" />
                <Tab label="Reports For Tester" />
                <Tab label="Tester Seed Emails" />
              </Tabs>
            </AppBar>
          </div>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card mycard">
              <div className="card-body">
                <TabPanel value={this.state.value} index={0}>
                  <div className="row">
                    <div className="col-12">
                      <p className="mt-1">
                        Our platform autodetects emails that we receive on
                        deliverability tester seed emails and groups them into
                        broadcasts. We do this based on Active sender profile,
                        Subject, From email, and date. Please note that we do
                        not perform any engagement actions for the emails that
                        are received by these tester seed emails.
                      </p>

                      {/* {dashdummy.multiple_email_type && <div className="row  ">
<div className="col">
<div 
className="float-right mb-2">
<div className="form-inline">
<div 
className="form-group">
<label for="exampleInputPassword1" style={{'marginRight':'7px',marginTop:'5px'}}>Email Provider</label>
<select  
className="form-control" name="email_type" onChange={this.handleInputChange} value={this.state.email_type}>
<option value="">All</option>
<option value="Gmail">Gmail</option>
<option value="Yahoo">Yahoo</option>
</select>
</div>
</div>
</div>  
</div>
</div>} */}

                      <form className="form-sample">
                        <div className="row">
                          <div className="col-md-12">
                            <MuiThemeProvider theme={getMuiTheme()}>
                              <MUIDataTable
                                title={"Broadcasts"}
                                data={broadcastupdated}
                                columns={columns}
                                options={options}
                              />
                            </MuiThemeProvider>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                  <div className="row">
                    <div className="col-12">
                      {chartreports.total_email > 0 && (
                        <div className="row mt-5">
                          <div className="col-md-6  ">
                            <Doughnut data={doughnutPieData} height={250} />
                          </div>
                          <div className="col-md-6  ">
                            <HorizontalBar
                              data={horizontalbar}
                              height={250}
                              options={{ legend: { display: false } }}
                            />
                          </div>

                          <div className="col-md-12">
                            <Multilinechart
                              allsenderprofile={chartreports.by_date}
                              senderprofile={chartreports.by_profile}
                              senderprofiles={senderprofiles}
                              selectedprofile={
                                this.state.sender_profile_name
                                  ? this.state.sender_profile_name
                                  : "All"
                              }
                            />
                          </div>
                        </div>
                      )}

                      {chartreports.total_email === 0 && (
                        <div className="row mt-5">
                          <div className="col-md-12 text-center">
                            <h4>Sorry, no matching records found.</h4>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </TabPanel>

                <TabPanel value={this.state.value} index={2}>
                  <div className="row">
                    <div className="col-12">
                      <div className="row ">
                        <div className="col-md-8">
                          <MuiThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                              title={""}
                              data={farray}
                              columns={seedcolumns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </div>

                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-12 ">
                              <h4 className="text-center">Download as CSV</h4>
                            </div>

                            {countservice_pro > 4 && (
                              <React.Fragment>
                                <div className="col-md-6">
                                  <span className="float-left mt-2">
                                    Email Provider
                                  </span>
                                </div>

                                <div className="col-md-6">
                                  <select
                                    name="type"
                                    className="form-control"
                                    onChange={this.handleInputChange}
                                    defaultValue={this.state.type}
                                  >
                                    <option value="All">All</option>
                                    <option value="Gmail">Gmail</option>
                                    <option value="Yahoo">Yahoo</option>
                                  </select>
                                </div>
                              </React.Fragment>
                            )}

                            <div className="col-md-12 grid-margin ">
                              <div className="col text-center mt-3">
                                {this.state.type === "All" && (
                                  <CSVLink
                                    data={myemails.data ? myemails.data : ""}
                                    headers={headers}
                                    filename={"alltesterseedemails.csv"}
                                    className="btn btn-primary"
                                    onClick={this.onDownloadEvent}
                                  >
                                    Download {this.state.type} Tester Seed
                                    Emails
                                  </CSVLink>
                                )}
                                {this.state.type !== "All" && (
                                  <CSVLink
                                    data={filterdata ? filterdata : ""}
                                    headers={headers}
                                    filename={
                                      this.state.type.toLowerCase() +
                                      "testerseedemails.csv"
                                    }
                                    className="btn btn-primary"
                                    onClick={this.onDownloadEvent}
                                  >
                                    Download {this.state.type} Seed Emails
                                  </CSVLink>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFilter: (keyword) => {
      dispatch(fetchFilterBroadcast(keyword));
    },
    dispatch,
  };
};

const mapStateToProps = (state) => ({
  broadcast: state.broadcast.items,
  loading: state.broadcast.loading,
  dashdummy: state.dash.items8,
  loading8: state.dash.loading8,
  error8: state.dash.error8,
  myemails: state.myemails.items,
  myemailsloading: state.myemails.loading,
  myemailserror: state.myemails.error,
  chartreports: state.reports.items2,
  chartloading: state.reports.loading,
  senderprofiles: state.senderprofiles.items.data,
  senderprofilesloading: state.senderprofiles.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(DeliverabilityTracker));
