import {
  FETCH_LBS_BEGIN,
  FETCH_LBS_SUCCESS,
  FETCH_LBS_FAILURE,
  FETCH_GAUGE_BEGIN,
  FETCH_GAUGE_SUCCESS,
  FETCH_GAUGE_FAILURE,
  FETCH_LER_BEGIN,
  FETCH_LER_SUCCESS,
  FETCH_LER_FAILURE,
  FETCH_DASH30_BEGIN,
  FETCH_DASH30_SUCCESS,
  FETCH_DASH30_FAILURE,
  FETCH_DONUT_BEGIN,
  FETCH_DONUT_SUCCESS,
  FETCH_DONUT_FAILURE,
  FETCH_AVEINBOXED_BEGIN,
  FETCH_AVEINBOXED_SUCCESS,
  FETCH_AVEINBOXED_FAILURE,
  FETCH_DELIMPROVE_BEGIN,
  FETCH_DELIMPROVE_SUCCESS,
  FETCH_DELIMPROVE_FAILURE,
  FETCH_DUMMYDATA_BEGIN,
  FETCH_DUMMYDATA_SUCCESS,
  FETCH_DUMMYDATA_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
  items2: [],
  error2: null,
  items3: [],
  error3: null,
  items4: [],
  error4: null,
  items5: [],
  error5: null,
  items6: [],
  error6: null,
  items7: [],
  error7: null,
  items8: [],
  error8: null,
};

export default function dashReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LBS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_LBS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.lbs,
      };

    case FETCH_LBS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    case FETCH_GAUGE_BEGIN:
      return {
        ...state,
        loading2: true,
        error2: null,
      };

    case FETCH_GAUGE_SUCCESS:
      return {
        ...state,
        loading2: false,
        items2: action.payload.dg,
      };

    case FETCH_GAUGE_FAILURE:
      return {
        ...state,
        loading2: false,
        error2: action.payload.error,
        items2: [],
      };
    case FETCH_LER_BEGIN:
      return {
        ...state,
        loading3: true,
        error3: null,
      };

    case FETCH_LER_SUCCESS:
      return {
        ...state,
        loading3: false,
        items3: action.payload.ler,
      };

    case FETCH_LER_FAILURE:
      return {
        ...state,
        loading3: false,
        error3: action.payload.error,
        items3: [],
      };

    case FETCH_DASH30_BEGIN:
      return {
        ...state,
        loading4: true,
        error4: null,
      };

    case FETCH_DASH30_SUCCESS:
      return {
        ...state,
        loading4: false,
        items4: action.payload.ds30,
      };

    case FETCH_DASH30_FAILURE:
      return {
        ...state,
        loading4: false,
        error4: action.payload.error,
        items4: [],
      };

    case FETCH_DONUT_BEGIN:
      return {
        ...state,
        loading5: true,
        error5: null,
      };

    case FETCH_DONUT_SUCCESS:
      return {
        ...state,
        loading5: false,
        items5: action.payload.dashddn,
      };

    case FETCH_DONUT_FAILURE:
      return {
        ...state,
        loading5: false,
        error5: action.payload.error,
        items5: [],
      };

    case FETCH_AVEINBOXED_BEGIN:
      return {
        ...state,
        loading6: true,
        error6: null,
      };

    case FETCH_AVEINBOXED_SUCCESS:
      return {
        ...state,
        loading6: false,
        items6: action.payload.avinbox,
      };

    case FETCH_AVEINBOXED_FAILURE:
      return {
        ...state,
        loading6: false,
        error6: action.payload.error,
        items6: [],
      };

    case FETCH_DELIMPROVE_BEGIN:
      return {
        ...state,
        loading7: true,
        error7: null,
      };

    case FETCH_DELIMPROVE_SUCCESS:
      return {
        ...state,
        loading7: false,
        items7: action.payload.delimp,
      };

    case FETCH_DELIMPROVE_FAILURE:
      return {
        ...state,
        loading7: false,
        error7: action.payload.error,
        items7: [],
      };

    case FETCH_DUMMYDATA_BEGIN:
      return {
        ...state,
        loading8: true,
        error8: null,
      };

    case FETCH_DUMMYDATA_SUCCESS:
      return {
        ...state,
        loading8: false,
        items8: action.payload.dummydata,
      };

    case FETCH_DUMMYDATA_FAILURE:
      return {
        ...state,
        loading8: false,
        error8: action.payload.error,
        items8: [],
      };

    default:
      return state;
  }
}
