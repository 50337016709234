import React, { Component } from "react";
import { Spinner } from "reactstrap";
class LoaderUi extends Component {
  render() {
    const style = {
      position: "fixed",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
    };
    return (
      <div style={style}>
        <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
}

export default LoaderUi;
