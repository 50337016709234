import {
  FETCH_PLAN_BEGIN,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILURE,
  FETCH_APLAN_BEGIN,
  FETCH_APLAN_SUCCESS,
  FETCH_APLAN_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
  items2: [],
  error2: null,
};

export default function planReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PLAN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.plan,
      };

    case FETCH_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case FETCH_APLAN_BEGIN:
      return {
        ...state,
        loading2: true,
        error2: null,
      };

    case FETCH_APLAN_SUCCESS:
      return {
        ...state,
        loading2: false,
        items2: action.payload.plans,
      };

    case FETCH_APLAN_FAILURE:
      return {
        ...state,
        loading2: false,
        error2: action.payload.error,
        items2: [],
      };

    default:
      return state;
  }
}
