import React, { Component } from "react";
import { fetchAllplandetail } from "../actions/plan";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import { fetchMyplandetail } from "../actions/plan";
import { BASE_URL_NODE_LIVE } from "../actions/types";
import Alert from "../layout/Alert";

export class Plan extends Component {
  state = {
    jwt: localStorage.token,
    id: this.props.match.params.id,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchAllplandetail(this.props.match.params.id));
    this.props.dispatch(fetchMyplandetail(this.state));
  }

  render() {
    var { loading2, allplan, loading, currentplan } = this.props;

    if (loading2 || loading) {
      return <LoaderUi />;
    }

    currentplan = currentplan ? currentplan[0] : "";
    var bronze = allplan ? allplan[0] : "";
    var sliver = allplan ? allplan[1] : "";
    var gold = allplan ? allplan[2] : "";

    var paymenttext =
      currentplan.paymentstutus === "1" ? "Contact Us" : "Subscribe now";

    var bronzeURL =
      bronze.id !== currentplan.id && currentplan.paymentstutus !== "1"
        ? BASE_URL_NODE_LIVE +
          "stripe_payment.php?plan_id=" +
          bronze.id +
          "&jwt=" +
          localStorage.token
        : "/pricing";
    var sliverURL =
      sliver.id !== currentplan.id && currentplan.paymentstutus !== "1"
        ? BASE_URL_NODE_LIVE +
          "stripe_payment.php?plan_id=" +
          sliver.id +
          "&jwt=" +
          localStorage.token
        : "/pricing";
    var goldURL =
      gold.id !== currentplan.id && currentplan.paymentstutus !== "1"
        ? BASE_URL_NODE_LIVE +
          "stripe_payment.php?plan_id=" +
          gold.id +
          "&jwt=" +
          localStorage.token
        : "/pricing";

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">Pricing </h3>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="container text-center ">
                  <h4 className="mb-3 mt-0">Start up your business today</h4>
                  <p className="w-75 mx-auto ">
                    Choose a plan that best suits you.
                  </p>

                  <div className="row  mt-1 mb-3">
                    <div className="col-12">
                      <Alert msgdivid="plan" />
                    </div>
                  </div>

                  <div className="row pricing-table">
                    <div className="col-md-6 col-xl-4 grid-margin stretch-card pricing-card">
                      <div className="card border-primary border pricing-card-body">
                        <div className="text-center pricing-card-head">
                          <h3>{bronze.plan_name}</h3>

                          <h1 className="font-weight-normal mb-4">
                            ${bronze.plan_monthly_price}
                          </h1>
                        </div>
                        <ul className="list-unstyled plan-features">
                          <li>{bronze.number_of_emails} Unique emails </li>
                          <li>
                            {bronze.number_of_emails_per_day} Engagements per
                            day{" "}
                          </li>
                          <li>
                            {bronze.number_of_emails_per_month} Engagement per
                            month{" "}
                          </li>
                          <li>
                            {bronze.number_of_sender_profiles} Different sender
                            profiles{" "}
                          </li>
                        </ul>
                        <p>
                          For more information on plans, please{" "}
                          <a
                            rel="noopener noreferrer"
                            href="https://.inboxally.com/pricing"
                            target="_blank"
                          >
                            click here
                          </a>
                        </p>
                        <div className="wrapper">
                          <a
                            href={bronzeURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-block"
                          >
                            {bronze.id === currentplan.id
                              ? "Current Plan"
                              : paymenttext}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4 grid-margin stretch-card pricing-card">
                      <div className="card border border-success pricing-card-body">
                        <div className="text-center pricing-card-head">
                          <h3 className="text-success">{sliver.plan_name}</h3>

                          <h1 className="font-weight-normal mb-4">
                            ${sliver.plan_monthly_price}
                          </h1>
                        </div>
                        <ul className="list-unstyled plan-features">
                          <li>{sliver.number_of_emails} Unique emails </li>
                          <li>
                            {sliver.number_of_emails_per_day} Engagements per
                            day{" "}
                          </li>
                          <li>
                            {sliver.number_of_emails_per_month} Engagement per
                            month{" "}
                          </li>
                          <li>
                            {sliver.number_of_sender_profiles} Different sender
                            profiles{" "}
                          </li>
                        </ul>
                        <p>
                          For more information on plans, please{" "}
                          <a
                            rel="noopener noreferrer"
                            href="https://.inboxally.com/pricing"
                            target="_blank"
                          >
                            click here
                          </a>
                        </p>

                        <div className="wrapper">
                          <a
                            href={sliverURL}
                            target="_blank"
                            className="btn btn-primary btn-block"
                            rel="noopener noreferrer"
                          >
                            {sliver.id === currentplan.id
                              ? "Current Plan"
                              : paymenttext}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4 grid-margin stretch-card pricing-card">
                      <div className="card border-primary border pricing-card-body">
                        <div className="text-center pricing-card-head">
                          <h3>{gold.plan_name}</h3>

                          <h1 className="font-weight-normal mb-4">
                            ${gold.plan_monthly_price}
                          </h1>
                        </div>
                        <ul className="list-unstyled plan-features">
                          <li>{gold.number_of_emails} Unique emails </li>
                          <li>
                            {gold.number_of_emails_per_day} Engagements per day{" "}
                          </li>
                          <li>
                            {gold.number_of_emails_per_month} Engagement per
                            month{" "}
                          </li>
                          <li>
                            {gold.number_of_sender_profiles} Different sender
                            profiles{" "}
                          </li>
                        </ul>
                        <p>
                          For more information on plans, please{" "}
                          <a
                            rel="noopener noreferrer"
                            href="https://.inboxally.com/pricing"
                            target="_blank"
                          >
                            click here
                          </a>
                        </p>

                        <div className="wrapper">
                          <a
                            href={goldURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-block"
                          >
                            {gold.id === currentplan.id
                              ? "Current Plan"
                              : paymenttext}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allplan: state.plan.items2.data,
  loading2: state.plan.loading2,
  error2: state.plan.error2,

  currentplan: state.plan.items.data,
  loading: state.plan.loading,
  error: state.plan.error,
});

export default connect(mapStateToProps, null)(Plan);
