import {
  FETCH_SENDERP_BEGIN,
  FETCH_SENDERP_SUCCESS,
  FETCH_SENDERP_FAILURE,
  FETCH_SENDERPRE_BEGIN,
  FETCH_SENDERPRE_SUCCESS,
  FETCH_SENDERPRE_FAILURE,
  FETCH_SENDEREACT_BEGIN,
  FETCH_SENDEREACT_SUCCESS,
  FETCH_SENDEREACT_FAILURE,
  CREATE_SENDERPR_BEGIN,
  CREATE_SENDERPR_SUCCESS,
  CREATE_SENDERPR_FAILURE,
  CREATE_SENDERPRV_BEGIN,
  CREATE_SENDERPRV_SUCCESS,
  CREATE_SENDERPRV_FAILURE,
  DEACTIVE_PRO_BEGIN,
  DEACTIVE_PRO_SUCCESS,
  DEACTIVE_PRO_FAILURE,
  FETCH_USENDERPRE_BEGIN,
  FETCH_USENDERPRE_SUCCESS,
  FETCH_USENDERPRE_FAILURE,
  SET_ENGAGEMENT_BEGIN,
  SET_ENGAGEMENT_SUCCESS,
  SET_ENGAGEMENT_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
  items2: [],
  error2: null,
  items3: [],
  error3: null,
  items4: [],
  error4: null,
  items5: [],
  error5: null,
  items6: [],
  error6: null,
  redirecttoview: false,
};

export default function senderProfilesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SENDERP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        redirecttoview: false,
      };

    case FETCH_SENDERP_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.senderprofiles,
        redirecttoview: false,
      };

    case FETCH_SENDERP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
        redirecttoview: false,
      };

    case FETCH_SENDERPRE_BEGIN:
      return {
        ...state,
        loading2: true,
        error2: null,
        redirecttoview: false,
      };

    case FETCH_SENDERPRE_SUCCESS:
      return {
        ...state,
        loading2: false,
        items2: action.payload.senderpre,
        redirecttoview: false,
      };

    case FETCH_SENDERPRE_FAILURE:
      return {
        ...state,
        loading2: false,
        error2: action.payload.error,
        items2: [],
        redirecttoview: false,
      };

    case FETCH_SENDEREACT_BEGIN:
      return {
        ...state,
        loading3: true,
        error3: null,
        redirecttoview: false,
      };

    case FETCH_SENDEREACT_SUCCESS:
      return {
        ...state,
        loading3: false,
        items3: action.payload.senderemailsa,
        redirecttoview: false,
      };

    case FETCH_SENDEREACT_FAILURE:
      return {
        ...state,
        loading3: false,
        error3: action.payload.error,
        items3: [],
        redirecttoview: false,
      };

    case CREATE_SENDERPR_BEGIN:
      return {
        ...state,
        loading4: true,
        error: null,
        items4: [],
        redirecttoview: false,
      };
    case CREATE_SENDERPR_SUCCESS:
      return {
        ...state,
        loading4: false,
        items4: action.payload.createpro,
        redirecttoview: false,
      };
    case CREATE_SENDERPR_FAILURE:
      return {
        ...state,
        loading4: false,
        error: action.payload.error,
        items4: [],
        redirecttoview: false,
      };

    case DEACTIVE_PRO_BEGIN:
      return {
        ...state,
        loading4: true,
        error: null,
        items4: [],
        redirecttoview: false,
      };
    case DEACTIVE_PRO_SUCCESS:
      return {
        ...state,
        loading4: false,
        items4: action.payload.deprofiles,
        redirecttoview: false,
      };
    case DEACTIVE_PRO_FAILURE:
      return {
        ...state,
        loading4: false,
        error: action.payload.error,
        items4: [],
        redirecttoview: false,
      };

    case CREATE_SENDERPRV_BEGIN:
      return {
        ...state,
        loading4: true,
        error: null,
        items4: [],
        redirecttoview: false,
      };
    case CREATE_SENDERPRV_SUCCESS:
      return {
        ...state,
        loading4: false,
        items4: action.payload.createpro,
        redirecttoview: true,
      };
    case CREATE_SENDERPRV_FAILURE:
      return {
        ...state,
        loading4: false,
        error: action.payload.error,
        items4: [],
        redirecttoview: false,
      };

    case FETCH_USENDERPRE_BEGIN:
      return {
        ...state,
        loading5: true,
        error5: null,
        redirecttoview: false,
      };

    case FETCH_USENDERPRE_SUCCESS:
      return {
        ...state,
        loading5: false,
        items5: action.payload.senderpre,
        redirecttoview: false,
      };

    case FETCH_USENDERPRE_FAILURE:
      return {
        ...state,
        loading5: false,
        error5: action.payload.error,
        items5: [],
        redirecttoview: false,
      };

    case SET_ENGAGEMENT_BEGIN:
      return {
        ...state,
        loading6: true,
        error: null,
        items6: [],
        redirecttoview: false,
      };
    case SET_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        loading6: false,
        items6: action.payload.setengagement,
        redirecttoview: false,
      };
    case SET_ENGAGEMENT_FAILURE:
      return {
        ...state,
        loading6: false,
        error: action.payload.error,
        items6: [],
        redirecttoview: false,
      };

    default:
      return state;
  }
}
