import {
  FETCH_GAUGE_BEGIN,
  FETCH_GAUGE_SUCCESS,
  FETCH_GAUGE_FAILURE,
  FETCH_LER_BEGIN,
  FETCH_LER_SUCCESS,
  FETCH_LER_FAILURE,
  FETCH_DASH30_BEGIN,
  FETCH_DASH30_SUCCESS,
  FETCH_DASH30_FAILURE,
  BASE_URL_NODE_LIVE,
  FETCH_DONUT_BEGIN,
  FETCH_DONUT_SUCCESS,
  FETCH_DONUT_FAILURE,
  FETCH_AVEINBOXED_BEGIN,
  FETCH_AVEINBOXED_SUCCESS,
  FETCH_AVEINBOXED_FAILURE,
  FETCH_DELIMPROVE_BEGIN,
  FETCH_DELIMPROVE_SUCCESS,
  FETCH_DELIMPROVE_FAILURE,
  FETCH_DUMMYDATA_BEGIN,
  FETCH_DUMMYDATA_SUCCESS,
  FETCH_DUMMYDATA_FAILURE,
} from "./types";
import axios from "axios";
import axiosRetry from "axios-retry";
axiosRetry(axios, { retries: 4 });

//const apiUrl = BASE_URL_NODE;
const apiUrlLive = BASE_URL_NODE_LIVE;

export const fetchDGBegin = () => ({
  type: FETCH_GAUGE_BEGIN,
});

export const fetchDGSSuccess = (dg) => {
  return {
    type: FETCH_GAUGE_SUCCESS,
    payload: { dg },
  };
};

export const fetchDGFailure = (error) => ({
  type: FETCH_GAUGE_FAILURE,
  payload: { error },
});

export const fetchDG = (jwt) => {
  var urlparams = jwt.email_type ? "&email_type=" + jwt.email_type : "";
  var querystringvar = "?jwt=";

  return (dispatch) => {
    dispatch(fetchDGBegin());
    return axios
      .get(
        `${apiUrlLive}emails_received.php${querystringvar}${jwt.jwt}${urlparams}`
      )
      .then((response) => {
        dispatch(fetchDGSSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDGFailure(error));
      });
  };
};

export const fetchLERBegin = () => ({
  type: FETCH_LER_BEGIN,
});

export const fetchLERSSuccess = (ler) => {
  return {
    type: FETCH_LER_SUCCESS,
    payload: { ler },
  };
};

export const fetchLERFailure = (error) => ({
  type: FETCH_LER_FAILURE,
  payload: { error },
});

export const fetchLER = (jwt) => {
  var urlparams = jwt.sender_profile_id
    ? "&sender_profile_id=" + jwt.sender_profile_id
    : "";
  var urlparams2 = jwt.email_type ? "&email_type=" + jwt.email_type : "";
  var querystringvar = "?jwt=";

  return (dispatch) => {
    dispatch(fetchLERBegin());
    return axios
      .get(
        `${apiUrlLive}broadcast_actions.php${querystringvar}${jwt.jwt}${urlparams}${urlparams2}`
      )
      .then((response) => {
        dispatch(fetchLERSSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchLERFailure(error));
      });
  };
};

export const fetchDS30Begin = () => ({
  type: FETCH_DASH30_BEGIN,
});

export const fetchDS30Success = (ds30) => {
  return {
    type: FETCH_DASH30_SUCCESS,
    payload: { ds30 },
  };
};

export const fetchDS30Failure = (error) => ({
  type: FETCH_DASH30_FAILURE,
  payload: { error },
});

export const fetchDS30 = (jwt) => {
  var urlparams = jwt.sender_profile_id
    ? "&sender_profile_id=" + jwt.sender_profile_id
    : "";
  var urlparams2 = urlparams + "&email_type=" + jwt.email_type;
  var querystringvar = "?jwt=";

  return (dispatch) => {
    dispatch(fetchDS30Begin());
    return axios
      .get(
        `${apiUrlLive}fetchdash30.php${querystringvar}${jwt.jwt}${urlparams}${urlparams2}`
      )
      .then((response) => {
        dispatch(fetchDS30Success(response.data));
      })
      .catch((error) => {
        dispatch(fetchDS30Failure(error));
      });
  };
};

export const fetchDNBegin = () => ({
  type: FETCH_DONUT_BEGIN,
});

export const fetchDNSSuccess = (dashddn) => {
  return {
    type: FETCH_DONUT_SUCCESS,
    payload: { dashddn },
  };
};

export const fetchDNFailure = (error) => ({
  type: FETCH_DONUT_FAILURE,
  payload: { error },
});

export const fetchDN = (jwt) => {
  var urlparams = jwt.sender_profile_id
    ? "&sender_profile_id=" + jwt.sender_profile_id
    : "";
  var urlparams2 = urlparams + "&email_type=" + jwt.email_type;
  var querystringvar = "?jwt=";
  return (dispatch) => {
    dispatch(fetchDNBegin());
    return axios
      .get(
        `${apiUrlLive}fetchlbs.php${querystringvar}${jwt.jwt}${urlparams}${urlparams2}`
      )
      .then((response) => {
        dispatch(fetchDNSSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDNFailure(error));
      });
  };
};

// AVEINBOXED

export const fetchAvInboxBegin = () => ({
  type: FETCH_AVEINBOXED_BEGIN,
});

export const fetchAvInboxSuccess = (avinbox) => {
  return {
    type: FETCH_AVEINBOXED_SUCCESS,
    payload: { avinbox },
  };
};

export const fetchAvInboxFailure = (error) => ({
  type: FETCH_AVEINBOXED_FAILURE,
  payload: { error },
});

export const fetchAvInbox = (jwt) => {
  var urlparams = jwt.sender_profile_id
    ? "&sender_profile_id=" + jwt.sender_profile_id
    : "";
  var urlparams2 = urlparams + "&email_type=" + jwt.email_type;
  var querystringvar = "?jwt=";
  return (dispatch) => {
    dispatch(fetchAvInboxBegin());
    return axios
      .get(
        `${apiUrlLive}average_inboxed.php${querystringvar}${jwt.jwt}${urlparams}${urlparams2}`
      )
      .then((response) => {
        dispatch(fetchAvInboxSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchAvInboxFailure(error));
      });
  };
};

export const fetchDelImpBegin = () => ({
  type: FETCH_DELIMPROVE_BEGIN,
});

export const fetchDelImpSuccess = (delimp) => {
  return {
    type: FETCH_DELIMPROVE_SUCCESS,
    payload: { delimp },
  };
};

export const fetchDelImpFailure = (error) => ({
  type: FETCH_DELIMPROVE_FAILURE,
  payload: { error },
});

export const fetchDelImp = (jwt) => {
  var urlparams = jwt.sender_profile_id
    ? "&sender_profile_id=" + jwt.sender_profile_id
    : "";
  var urlparams2 = urlparams + "&email_type=" + jwt.email_type;
  var querystringvar = "?jwt=";
  return (dispatch) => {
    dispatch(fetchDelImpBegin());
    return axios
      .get(
        `${apiUrlLive}delivery_improvement.php${querystringvar}${jwt.jwt}${urlparams}${urlparams2}`
      )
      .then((response) => {
        dispatch(fetchDelImpSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDelImpFailure(error));
      });
  };
};

export const fetchDummyDataBegin = () => ({
  type: FETCH_DUMMYDATA_BEGIN,
});

export const fetchDummyDataSuccess = (dummydata) => {
  return {
    type: FETCH_DUMMYDATA_SUCCESS,
    payload: { dummydata },
  };
};

export const fetchDummyDataFailure = (error) => ({
  type: FETCH_DUMMYDATA_FAILURE,
  payload: { error },
});

export const fetchDummyData = (jwt) => {
  var querystringvar = "?jwt=";

  return (dispatch) => {
    dispatch(fetchDummyDataBegin());
    return axios
      .get(`${apiUrlLive}dummy_data.php${querystringvar}${jwt.jwt}`)
      .then((response) => {
        dispatch(fetchDummyDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchDummyDataFailure(error));
      });
  };
};
