//register
export const BASE_URL_NODE = `http://152.44.41.26:4005/`;
export const BASE_URL_NODE_LIVE = `https://cas.inboxally.com/API/`;

export const USER_REG_BEGIN = "USER_REG_BEGIN";
export const USER_REG_SUCCESS = "USER_REG_SUCCESS";
export const USER_REG_FAILURE = "USER_REG_FAILURE";

export const USER_REGCOM_BEGIN = "USER_REGCOM_BEGIN";
export const USER_REGCOM_SUCCESS = "USER_REGCOM_SUCCESS";
export const USER_REGCOM_FAILURE = "USER_REGCOM_FAILURE";
export const USER_REGRESET_STATE = "USER_REGRESET_STATE";
export const USER_REGRESETC_STATE = "USER_REGRESETC_STATE";

//sign in
export const LOADLOGIN = "LOADLOGIN";
export const LOGIN = "LOGIN";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGIN_SUBMIT = "LOGIN_SUBMIT";
export const AUTH_CHECK = "AUTH_CHECK";
export const LOGOUT = "LOGOUT";

//blockeddomain

export const FETCH_BLOCKED_DOMAIN_BEGIN = "FETCH_BLOCKED_DOMAIN_BEGIN";
export const FETCH_BLOCKED_DOMAIN_SUCCESS = "FETCH_BLOCKED_DOMAIN_SUCCESS";
export const FETCH_BLOCKED_DOMAIN_FAILURE = "FETCH_BLOCKED_DOMAIN_FAILURE";

// chanage password

export const CHANGEPASS_BEGIN = "CHANGEPASS_BEGIN";
export const CHANGEPASS_SUCCESS = "CHANGEPASS_SUCCESS";
export const CHANGEPASS_FAILURE = "CHANGEPASS_FAILURE";

//dashboard

export const FETCH_LBS_BEGIN = "FETCH_LBS_BEGIN";
export const FETCH_LBS_SUCCESS = "FETCH_LBS_SUCCESS";
export const FETCH_LBS_FAILURE = "FETCH_LBS_FAILURE";

export const FETCH_LER_BEGIN = "FETCH_LER_BEGIN";
export const FETCH_LER_SUCCESS = "FETCH_LER_SUCCESS";
export const FETCH_LER_FAILURE = "FETCH_LER_FAILURE";

export const FETCH_DASH30_BEGIN = "FETCH_DASH30_BEGIN";
export const FETCH_DASH30_SUCCESS = "FETCH_DASH30_SUCCESS";
export const FETCH_DASH30_FAILURE = "FETCH_DASH30_SUCCESS";

export const FETCH_GAUGE_BEGIN = "FETCH_GAUGE_BEGIN";
export const FETCH_GAUGE_SUCCESS = "FETCH_GAUGE_SUCCESS";
export const FETCH_GAUGE_FAILURE = "FETCH_GAUGE_FAILURE";

export const FETCH_DONUT_BEGIN = "FETCH_DONUT_BEGIN";
export const FETCH_DONUT_SUCCESS = "FETCH_DONUT_SUCCESS";
export const FETCH_DONUT_FAILURE = "FETCH_DONUT_FAILURE";

export const FETCH_AVEINBOXED_BEGIN = "FETCH_AVEINBOXED_BEGIN";
export const FETCH_AVEINBOXED_SUCCESS = "FETCH_AVEINBOXED_SUCCESS";
export const FETCH_AVEINBOXED_FAILURE = "FETCH_AVEINBOXED_FAILURE";

export const FETCH_DELIMPROVE_BEGIN = "FETCH_DELIMPROVE_BEGIN";
export const FETCH_DELIMPROVE_SUCCESS = "FETCH_DELIMPROVE_SUCCESS";
export const FETCH_DELIMPROVE_FAILURE = "FETCH_DELIMPROVE_FAILURE";

export const FETCH_DUMMYDATA_BEGIN = "FETCH_DUMMYDATA_BEGIN";
export const FETCH_DUMMYDATA_SUCCESS = "FETCH_DUMMYDATA_SUCCESS";
export const FETCH_DUMMYDATA_FAILURE = "FETCH_DUMMYDATA_FAILURE";

//alert

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//Forgot Password

export const FORGOT_PASS_BEGIN = "FORGOT_PASS_BEGIN";
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export const FORGOT_PASS_FAILURE = "FORGOT_PASS_FAILURE";

//Sender Emails

export const FETCH_SENDERE_BEGIN = "FETCH_SENDERE_BEGIN";
export const FETCH_SENDERE_SUCCESS = "FETCH_SENDERE_SUCCESS";
export const FETCH_SENDERE_FAILURE = "FETCH_SENDERE_FAILURE";

export const SAVE_SENDERE_BEGIN = "SAVE_SENDERE_BEGIN";
export const SAVE_SENDERE_SUCCESS = "SAVE_SENDERE_SUCCESS";
export const SAVE_SENDERE_FAILURE = "SAVE_SENDERE_FAILURE";

export const VERIFY_SENDERE_BEGIN = "VERIFY_SENDERE_BEGIN";
export const VERIFY_SENDERE_SUCCESS = "VERIFY_SENDERE_SUCCESS";
export const VERIFY_SENDERE_FAILURE = "VERIFY_SENDERE_FAILURE";

export const DELETE_SENDERE_BEGIN = "DELETE_SENDERE_BEGIN";
export const DELETE_SENDERE_SUCCESS = "DELETE_SENDERE_SUCCESS";
export const DELETE_SENDERE_FAILURE = "DELETE_SENDERE_FAILURE";

// My Emails

export const FETCH_MYEMAIL_BEGIN = "FETCH_MYEMAIL_BEGIN";
export const FETCH_MYEMAIL_SUCCESS = "FETCH_MYEMAIL_SUCCESS";
export const FETCH_MYEMAIL_FAILURE = "FETCH_MYEMAIL_FAILURE";

// Sender Profiles

export const FETCH_SENDERP_BEGIN = "FETCH_SENDERP_BEGIN";
export const FETCH_SENDERP_SUCCESS = "FETCH_SENDERP_SUCCESS";
export const FETCH_SENDERP_FAILURE = "FETCH_SENDERP_FAILURE";

export const FETCH_SENDERPRE_BEGIN = "FETCH_SENDERPRE_BEGIN";
export const FETCH_SENDERPRE_SUCCESS = "FETCH_SENDERPRE_SUCCESS";
export const FETCH_SENDERPRE_FAILURE = "FETCH_SENDERPRE_FAILURE";

export const FETCH_USENDERPRE_BEGIN = "FETCH_USENDERPRE_BEGIN";
export const FETCH_USENDERPRE_SUCCESS = "FETCH_USENDERPRE_SUCCESS";
export const FETCH_USENDERPRE_FAILURE = "FETCH_USENDERPRE_FAILURE";

export const CREATE_SENDERPR_BEGIN = "CREATE_SENDERPR_BEGIN";
export const CREATE_SENDERPR_SUCCESS = "CREATE_SENDERPR_SUCCESS";
export const CREATE_SENDERPR_FAILURE = "CREATE_SENDERPR_FAILURE";

export const CREATE_SENDERPRV_BEGIN = "CREATE_SENDERPRV_BEGIN";
export const CREATE_SENDERPRV_SUCCESS = "CREATE_SENDERPRV_SUCCESS";
export const CREATE_SENDERPRV_FAILURE = "CREATE_SENDERPRV_FAILURE";

export const FETCH_SENDEREACT_BEGIN = "FETCH_SENDEREACT_BEGIN";
export const FETCH_SENDEREACT_SUCCESS = "FETCH_SENDEREACT_SUCCESS";
export const FETCH_SENDEREACT_FAILURE = "FETCH_SENDEREACT_FAILURE";

export const DEACTIVE_PRO_BEGIN = "DEACTIVE_PRO_BEGIN";
export const DEACTIVE_PRO_SUCCESS = "DEACTIVE_PRO_SUCCESS";
export const DEACTIVE_PRO_FAILURE = "DEACTIVE_PRO_FAILURE";

// Plan

export const FETCH_PLAN_BEGIN = "FETCH_PLAN_BEGIN";
export const FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS";
export const FETCH_PLAN_FAILURE = "FETCH_PLAN_FAILURE";

// All Plan

export const FETCH_APLAN_BEGIN = "FETCH_APLAN_BEGIN";
export const FETCH_APLAN_SUCCESS = "FETCH_APLAN_SUCCESS";
export const FETCH_APLAN_FAILURE = "FETCH_APLAN_FAILURE";

//Reset Password

export const RESET_PASS_BEGIN = "RESET_PASS_BEGIN";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";
export const RESET_PASS_FAILURE = "RESET_PASS_FAILURE";

//Reports

export const FETCH_REPORTS_BEGIN = "FETCH_REPORTS_BEGIN";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";

export const FETCH_CHART_REPORTS_BEGIN = "FETCH_CHART_REPORTS_BEGIN";
export const FETCH_CHART_REPORTS_SUCCESS = "FETCH_CHART_REPORTS_SUCCESS";
export const FETCH_CHART_REPORTS_FAILURE = "FETCH_CHART_REPORTS_FAILURE";

// Subject

export const FETCH_SUBJECT_BEGIN = "FETCH_SUBJECT_BEGIN";
export const FETCH_SUBJECT_SUCCESS = "FETCH_SUBJECT_SUCCESS";
export const FETCH_SUBJECT_FAILURE = "FETCH_SUBJECT_FAILURE";

//broadcast

// Subject

export const FETCH_BROADCAST_BEGIN = "FETCH_BROADCAST_BEGIN";
export const FETCH_BROADCAST_SUCCESS = "FETCH_BROADCAST_SUCCESS";
export const FETCH_BROADCAST_FAILURE = "FETCH_BROADCAST_FAILURE";

export const FETCH_BROADCASTP_BEGIN = "FETCH_BROADCASTP_BEGIN";
export const FETCH_BROADCASTP_SUCCESS = "FETCH_BROADCASTP_SUCCESS";
export const FETCH_BROADCASTP_FAILURE = "FETCH_BROADCASTP_FAILURE";

export const UPDATE_MYACCOUNT_BEGIN = "UPDATE_MYACCOUNT_BEGIN";
export const UPDATE_MYACCOUNT_SUCCESS = "UPDATE_MYACCOUNT_SUCCESS";
export const UPDATE_MYACCOUNT_FAILURE = "UPDATE_MYACCOUNT_FAILURE";

export const SET_ASDEFULAT_BEGIN = "SET_ASDEFULAT_BEGIN";
export const SET_ASDEFULAT_SUCCESS = "SET_ASDEFULAT_SUCCESS";
export const SET_ASDEFULAT_FAILURE = "SET_ASDEFULAT_FAILURE";

export const SHUFFLE_BEGIN = "SHUFFLE_BEGIN";
export const SHUFFLE_SUCCESS = "SHUFFLE_SUCCESS";
export const SHUFFLE_FAILURE = "SHUFFLE_FAILURE";

export const SET_ENGAGEMENT_BEGIN = "SET_ENGAGEMENT_BEGIN";
export const SET_ENGAGEMENT_SUCCESS = "SET_ENGAGEMENT_SUCCESS";
export const SET_ENGAGEMENT_FAILURE = "SET_ENGAGEMENT_FAILURE";

export const CHECK_INVITECODE_BEGIN = "CHECK_INVITECODE_BEGIN";
export const CHECK_INVITECODE_SUCCESS = "CHECK_INVITECODE_SUCCESS";
export const CHECK_INVITECODE_FAILURE = "CHECK_INVITECODE_FAILURE";
