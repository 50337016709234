import {
  BASE_URL_NODE,
  USER_REG_BEGIN,
  USER_REG_SUCCESS,
  USER_REG_FAILURE,
  SET_CURRENT_USER,
  LOGIN,
  LOGIN_SUBMIT,
  LOGOUT,
  LOGIN_FAIL,
  CHANGEPASS_BEGIN,
  CHANGEPASS_SUCCESS,
  CHANGEPASS_FAILURE,
  FORGOT_PASS_BEGIN,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_FAILURE,
  BASE_URL_NODE_LIVE,
  USER_REGRESET_STATE,
  USER_REGRESETC_STATE,
  USER_REGCOM_BEGIN,
  USER_REGCOM_SUCCESS,
  USER_REGCOM_FAILURE,
  RESET_PASS_BEGIN,
  RESET_PASS_SUCCESS,
  RESET_PASS_FAILURE,
  LOADLOGIN,
  UPDATE_MYACCOUNT_BEGIN,
  UPDATE_MYACCOUNT_SUCCESS,
  UPDATE_MYACCOUNT_FAILURE,
  SET_ASDEFULAT_BEGIN,
  SET_ASDEFULAT_SUCCESS,
  SET_ASDEFULAT_FAILURE,


} from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import {setCookie} from '../utils/cookie';
import jwtDecode from "jwt-decode";
import {SOME_THING_WENT_WRONG,PASSWORD_UPDATED_SUCCESS,EMAIL_VERIFIED} from "../utils/message";
import {PAYMENT_SUCCESS} from "../utils/tooltip";



import { setAlert } from "./alert";

const apiUrl = BASE_URL_NODE;
const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require('qs');


export const regBegin = () => ({
  type: USER_REG_BEGIN,
});

export const regSuccess = (reg) => {
  return {
    type: USER_REG_SUCCESS,
    payload: { reg },
  };
};

export const regFailure = (error) => ({
  type: USER_REG_FAILURE,
  payload: { error },
});



export const userReg = (reg) => {
  return (dispatch) => {
    dispatch(regBegin());
    return axios
      .post(`${apiUrlLive}register.php`,qs.stringify(reg))
      .then((response) => {
        dispatch(regSuccess(response.data));
        dispatch(setAlert(response.data.message, response.data.type, "registerdiv"));
        dispatch(regResetCom());
      })
      .catch((error) => {
        dispatch(regFailure(error));
      });
  };
};



export const regBeginCom = () => ({
  type: USER_REGCOM_BEGIN,
});

export const regSuccessCom = (reg) => {
  return {
    type: USER_REGCOM_SUCCESS,
    payload: { reg },
  };
};

export const regFailureCom = (error) => ({
  type: USER_REGCOM_FAILURE,
  payload: { error },
});



export const regResetCom = () => ({
  type: USER_REGRESET_STATE,
});
export const regResetCCom = () => ({
  type: USER_REGRESETC_STATE,
});

export const userRegComplete = (user) => {
  return (dispatch) => {
    dispatch(regBeginCom());
    return axios
      .post(`${apiUrlLive}registercomplete.php`,  qs.stringify(user))
      .then((response) => {
        dispatch(regSuccessCom(response.data));
        dispatch(setAlert(response.data.message, response.data.type, "logindiv"));
        dispatch(regResetCCom());
      })
      .catch((error) => {
        dispatch(regFailureCom(error));
        dispatch(
          setAlert(
           SOME_THING_WENT_WRONG,
            "danger",
            "logindiv"
          )
        );
      });
  };
};

export function setCurrentUser(data) {
  return {
    type: SET_CURRENT_USER,
    payload: data.data,
  };
}


export const userLogin = (userdata) => {
  return (dispatch) => {
    dispatch(loginSubmit());
    
    return axios
      .post(`${apiUrlLive}userlogin.php`, qs.stringify(userdata))
      .then((response) => {
        if (response.data.type==="error") {
          dispatch({ type: LOGIN_FAIL });
          dispatch(setAlert(response.data.message, "error", "logindiv"));
        } if (response.data.type==="success"){
          localStorage.setItem("token", response.data.token);
          if (localStorage.token) {
            setAuthToken(localStorage.token);
          }

          dispatch(loginSuccess(response.data));
          dispatch(setCurrentUser(jwtDecode(localStorage.token)));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN,
    payload: data,
  };
};

function loginSubmit() {
  return {
    type: LOGIN_SUBMIT,
  };
}

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  setCookie('uuemail',""); 
  setCookie('uuname',""); 
  setAuthToken(localStorage.token);
  localStorage.removeItem('userType');
 
   window.location.href = "/";
  
};

export const changePassBegin = () => ({
  type: CHANGEPASS_BEGIN,
});

export const changePassSuccess = (updatepass) => {
  return {
    type: CHANGEPASS_SUCCESS,
    payload: { updatepass },
  };
};

export const changePassFailure = (error) => ({
  type: CHANGEPASS_FAILURE,
  payload: { error },
});

export const updatePass = (updatepass, history) => {
  return (dispatch) => {
    dispatch(changePassBegin());
    return axios
      .post(apiUrl + "updatepass", updatepass)

      .then((response) => {
        dispatch(changePassSuccess(response.data));
        dispatch(
          setAlert(response.data.message, response.data.type, "changepwd")
        );
        if (
          response.data.message === PASSWORD_UPDATED_SUCCESS
        ) {
          setTimeout(() => {
            dispatch({ type: LOGOUT });
          }, 2000);
        }
      })
      .catch((error) => dispatch(changePassFailure(error)));
    
  };
};

export const forPassBegin = () => ({
  type: FORGOT_PASS_BEGIN,
});

export const forPassSuccess = (forgotpass) => {
  return {
    type: FORGOT_PASS_SUCCESS,
    payload: { forgotpass },
  };
};

export const forPassFailure = (error) => ({
  type: FORGOT_PASS_FAILURE,
  payload: { error },
});

export const forgotpass = (forgotpass) => {


  return (dispatch) => {
    dispatch(forPassBegin());
    return axios
      .post(apiUrlLive + "forgot_password.php", qs.stringify({email_id:forgotpass.email,host:forgotpass.host}) )
      .then((response) => {
        dispatch(forPassSuccess(response.data));
        dispatch(
          setAlert(response.data.message, response.data.type, "forgotpass")
        );
      })
      .catch((error) => {
        dispatch(forPassFailure(error));
        dispatch(
          setAlert(
            SOME_THING_WENT_WRONG,
            "error",
            "forgotpass"
          )
        );
      });
  };
};



export const resetPassBegin = () => ({
  type: RESET_PASS_BEGIN,
});

export const resetPassSuccess = (reset) => {
  return {
    type:  RESET_PASS_SUCCESS,
    payload: { forgotpass },
  };
};

export const resetPassFailure = (error) => ({
  type: RESET_PASS_FAILURE,
  payload: { error },
});

export const resetpass = (reset) => {
  return (dispatch) => {
    dispatch(resetPassBegin());
    return axios
      .post(apiUrlLive + "password_reset.php", qs.stringify(reset))

      .then((response) => {
        dispatch(resetPassSuccess(response.data));
       if (response.data.type==="error") {
          
        }

         dispatch(setAlert(response.data.message, response.data.type, "resetpass"));

      })
      .catch((error) => {
        dispatch(resetPassFailure(error));
        dispatch(
          setAlert(
            SOME_THING_WENT_WRONG,
            "error",
            "forgotpass"
          )
        );
      });
  };
};



export const loadlogin = (jwtdata) => {

return (dispatch) => {
  dispatch({ type: LOADLOGIN });

if(jwtdata && jwtdata.id!==undefined && jwtdata.id==='/login/1')
{
  window.history.pushState(null, '', "/login");
  dispatch(setAlert(EMAIL_VERIFIED, "success", "logindiv"));
}
  };
};




export const updateproBegin = () => ({
  type: UPDATE_MYACCOUNT_BEGIN,
});

export const updateproSuccess = (updateprofile) => {
  return {
    type: UPDATE_MYACCOUNT_SUCCESS,
    payload: updateprofile ,
  };
};

export const updateproFailure = (error) => ({
  type: UPDATE_MYACCOUNT_FAILURE,
  payload: { error },
});



export const updateprofile = (updateprofile) => {
  delete axios.defaults.headers.common['x-auth-token'];

  return (dispatch) => {
    dispatch(updateproBegin());
    return axios
      .post(`${apiUrlLive}update_user.php`,qs.stringify(updateprofile))
      .then((response) => {

localStorage.setItem("token", response.data.token);
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
dispatch(setCurrentUser(jwtDecode(response.data.token)));
dispatch(updateproSuccess(updateprofile));
dispatch(setAlert(response.data.message, response.data.type, "udpatediv"));
       

      })
      .catch((error) => {
        dispatch(updateproFailure(error));
        //dispatch(setAlert(error, 'danger','registerdiv'));
      });
  };
};



export const updatedefaultBegin = () => ({
  type: SET_ASDEFULAT_BEGIN,
});

export const updatedefaultSuccess = (updatedefualt) => {
  return {
    type: SET_ASDEFULAT_SUCCESS,
    payload: updateprofile ,
  };
};

export const updatedefaultFailure = (error) => ({
  type: SET_ASDEFULAT_FAILURE,
  payload: { error },
});



export const updatedefualt = (updatedefualt) => {
 delete axios.defaults.headers.common['x-auth-token'];
  return (dispatch) => {
    dispatch(updatedefaultBegin());
    return axios
      .post(`${apiUrlLive}default_sender_profileid.php`,qs.stringify(updatedefualt))
      .then((response) => {

localStorage.setItem("token", response.data.jwt);
if (localStorage.token) {
  setAuthToken(localStorage.token);
}


dispatch(setCurrentUser(jwtDecode(response.data.jwt)));
dispatch(updatedefaultSuccess(updatedefualt));
       

      })
      .catch((error) => {
        dispatch(updatedefaultFailure(error));
        //dispatch(setAlert(error, 'danger','registerdiv'));
      });
  };
};


export const paymentsuccess = (updateplan) => {

  
  delete axios.defaults.headers.common['x-auth-token'];
  return (dispatch) => {
  
      if(updateplan.id
==='1'){
      
      dispatch(updateproBegin());
      
      return axios
        .post(`${apiUrlLive}update_plan.php`,qs.stringify(updateplan))
        .then((response) => {
  
  localStorage.setItem("token", response.data.token);
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  
  dispatch(setAlert(PAYMENT_SUCCESS, 'success', "myaccount"));
  dispatch(setCurrentUser(jwtDecode(response.data.token)));
  dispatch(updateproSuccess(updateprofile));
  })
        .catch((error) => {
          dispatch(updateproFailure(error));
          //dispatch(setAlert(error, 'danger','registerdiv'));
        });
  
      }
        
    };
  
  
  }