import {
  FETCH_BROADCASTP_BEGIN,
  FETCH_BROADCASTP_SUCCESS,
  FETCH_BROADCASTP_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
};

export default function mybroadcastpReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BROADCASTP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_BROADCASTP_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.broadcast,
      };

    case FETCH_BROADCASTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
