import {
  FETCH_BROADCASTP_BEGIN,
  FETCH_BROADCASTP_SUCCESS,
  FETCH_BROADCASTP_FAILURE,
  BASE_URL_NODE_LIVE,
} from "./types";
import axios from "axios";
import axiosRetry from "axios-retry";
axiosRetry(axios, { retries: 4 });

const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const fetchBroadBegin = () => ({
  type: FETCH_BROADCASTP_BEGIN,
});

export const fetchBroadSuccess = (broadcast) => {
  return {
    type: FETCH_BROADCASTP_SUCCESS,
    payload: { broadcast },
  };
};

export const fetchBroadFailure = (error) => ({
  type: FETCH_BROADCASTP_FAILURE,
  payload: { error },
});

export const fetchBroadcast = (jwt) => {
  var urlparams = jwt.sender_profile_id
    ? "&sender_profile_id=" + jwt.sender_profile_id
    : "";
  var urlparams1 = jwt.tracking ? "&tracking=" + jwt.tracking : "";
  var urlparams2 = jwt.email_type ? "&email_type=" + jwt.email_type : "";
  var querystringvar = "?jwt=";
  return (dispatch) => {
    dispatch(fetchBroadBegin());
    return axios
      .get(
        `${apiUrlLive}broadcastdate_report.php${querystringvar}${jwt.jwt}${urlparams}${urlparams1}${urlparams2}`
      )
      .then((response) => {
        dispatch(fetchBroadSuccess(response.data));
      })
      .catch((error) => dispatch(fetchBroadFailure(error)));
  };
};

export const fetchFilterBroadcast = (keyword) => {
  return (dispatch) => {
    dispatch(fetchBroadBegin());
    return axios
      .post(apiUrlLive + "broadcastdate_report.php", qs.stringify(keyword))
      .then((response) => {
        dispatch(fetchBroadSuccess(response.data));
      })
      .catch((error) => dispatch(fetchBroadFailure(error)));
  };
};
