import {
  FETCH_SENDERP_BEGIN,
  FETCH_SENDERP_SUCCESS,
  FETCH_SENDERP_FAILURE,
  FETCH_SENDERPRE_BEGIN,
  FETCH_SENDERPRE_SUCCESS,
  FETCH_SENDERPRE_FAILURE,
  BASE_URL_NODE_LIVE,
  CREATE_SENDERPR_BEGIN,
  CREATE_SENDERPR_SUCCESS,
  CREATE_SENDERPR_FAILURE,
  FETCH_SENDEREACT_BEGIN,
  FETCH_SENDEREACT_SUCCESS,
  FETCH_SENDEREACT_FAILURE,
  CREATE_SENDERPRV_BEGIN,
  CREATE_SENDERPRV_SUCCESS,
  CREATE_SENDERPRV_FAILURE,
  DEACTIVE_PRO_BEGIN,
  DEACTIVE_PRO_SUCCESS,
  DEACTIVE_PRO_FAILURE,
  FETCH_USENDERPRE_BEGIN,
  FETCH_USENDERPRE_SUCCESS,
  FETCH_USENDERPRE_FAILURE,
  SET_ENGAGEMENT_BEGIN,
  SET_ENGAGEMENT_SUCCESS,
  SET_ENGAGEMENT_FAILURE,
} from "./types";
import axios from "axios";
import { setAlert } from "./alert";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 4 });

//const apiUrl = BASE_URL_NODE;
const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const fetchSPBegin = () => ({
  type: FETCH_SENDERP_BEGIN,
});

export const fetchSPSuccess = (senderprofiles) => {
  return {
    type: FETCH_SENDERP_SUCCESS,
    payload: { senderprofiles },
  };
};

export const fetchSPFailure = (error) => ({
  type: FETCH_SENDERP_FAILURE,
  payload: { error },
});

export const fetchSenderprofiles = (jwtdata) => {
  var querystringvar = "?jwt=";

  return (dispatch) => {
    dispatch(fetchSPBegin());
    return axios
      .get(`${apiUrlLive}senderprofiles.php${querystringvar}${jwtdata.jwt}`)
      .then((response) => {
        dispatch(fetchSPSuccess(response.data));
      })
      .catch((error) => dispatch(fetchSPFailure(error)));
  };
};

export const fetchSPREBegin = () => ({
  type: FETCH_SENDERPRE_BEGIN,
});

export const fetchSPRESuccess = (senderpre) => {
  return {
    type: FETCH_SENDERPRE_SUCCESS,
    payload: { senderpre },
  };
};

export const fetchSPREFailure = (error) => ({
  type: FETCH_SENDERPRE_FAILURE,
  payload: { error },
});

export const fetchSenderpre = (jwtdata) => {
  return (dispatch) => {
    dispatch(fetchSPREBegin());
    return axios
      .post(apiUrlLive + "profilepreset.php", qs.stringify(jwtdata))
      .then((response) => {
        if (jwtdata && jwtdata.id !== "" && jwtdata.id !== undefined) {
          window.history.pushState(
            null,
            "",
            "/createsenderprofile/" + jwtdata.senderemail
          );
          dispatch(
            setAlert(
              "You have successfully verified your email.",
              "success",
              "createsenderp"
            )
          );
        }

        dispatch(fetchSPRESuccess(response.data));
      })
      .catch((error) => dispatch(fetchSPREFailure(error)));
  };
};

export const createSPBegin = () => ({
  type: CREATE_SENDERPR_BEGIN,
});

export const createSPSuccess = (createpro) => {
  return {
    type: CREATE_SENDERPR_SUCCESS,
    payload: { createpro },
  };
};

export const createSPFailure = (error) => ({
  type: CREATE_SENDERPR_FAILURE,
  payload: { error },
});

export const crreatesenderp = (createpro) => {
  return (dispatch) => {
    dispatch(createSPBegin());
    return axios
      .post(`${apiUrlLive}createsenderprofile.php`, qs.stringify(createpro))
      .then((response) => {
        dispatch(createSPSuccess(createpro));
        dispatch(fetchSenderprofiles({ jwt: localStorage.token }));

        if (response.data.statuscode === "0") {
          dispatch(
            setAlert(
              "Something went wrong.Please try again.",
              "error",
              "createsenderp"
            )
          );
          dispatch(createSPVFailure(""));
        }

        if (response.data.statuscode === "1") {
          window.location.href = "/senderprofiles";
        }
      })
      .catch((error) => dispatch(createSPFailure(error)));
  };
};

export const createSPVBegin = () => ({
  type: CREATE_SENDERPRV_BEGIN,
});

export const createSPVSuccess = (createpro) => {
  return {
    type: CREATE_SENDERPRV_SUCCESS,
    payload: { createpro },
  };
};

export const createSPVFailure = (error) => ({
  type: CREATE_SENDERPRV_FAILURE,
  payload: { error },
});

export const crreatesenderpview = (createpro) => {
  return (dispatch) => {
    dispatch(createSPVBegin());
    return axios
      .post(`${apiUrlLive}createsenderprofile.php`, qs.stringify(createpro))
      .then((response) => {
        if (response.data.statuscode === "0") {
          dispatch(
            setAlert(
              "Something went wrong.Please try again.",
              "error",
              "createsenderp"
            )
          );
          dispatch(createSPVFailure(""));
        }

        if (response.data.statuscode === "1") {
          dispatch(createSPVSuccess(response.data));
        }
      })
      .catch((error) => dispatch(createSPVFailure(error)));
  };
};

export const fetchSPEABegin = () => ({
  type: FETCH_SENDEREACT_BEGIN,
});

export const fetchSPEASuccess = (senderemailsa) => {
  return {
    type: FETCH_SENDEREACT_SUCCESS,
    payload: { senderemailsa },
  };
};

export const fetchSPEAFailure = (error) => ({
  type: FETCH_SENDEREACT_FAILURE,
  payload: { error },
});

export const fetchSenderprofilesea = (senderemailsa) => {
  return (dispatch) => {
    dispatch(fetchSPEABegin());
    return axios
      .post(
        apiUrlLive + "senderprofilemailactions.php",
        qs.stringify(senderemailsa)
      )
      .then((response) => {
        dispatch(fetchSPEASuccess(response.data));
      })
      .catch((error) => dispatch(fetchSPEAFailure(error)));
  };
};

// Deactive Profile

export const deactiveSPBegin = () => ({
  type: DEACTIVE_PRO_BEGIN,
});

export const deactiveSPSuccess = (deprofiles) => {
  return {
    type: DEACTIVE_PRO_SUCCESS,
    payload: { deprofiles },
  };
};

export const deactiveSPFailure = (error) => ({
  type: DEACTIVE_PRO_FAILURE,
  payload: { error },
});

export const deactiveprofile = (jwtdata) => {
  return (dispatch) => {
    dispatch(deactiveSPBegin());
    return axios
      .post(apiUrlLive + "change_status.php", qs.stringify(jwtdata))
      .then((response) => {
        dispatch(deactiveSPSuccess(response.data));
        dispatch(
          setAlert(response.data.message, response.data.type, "updatesenderp")
        );
        if (jwtdata.page === "senderprofile") {
          dispatch(fetchSenderprofiles(jwtdata));
        } else {
          window.location.href = "/senderprofiles";
        }
      })
      .catch((error) => dispatch(deactiveSPFailure(error)));
  };
};

// Update Preset

export const fetchUSPREBegin = () => ({
  type: FETCH_USENDERPRE_BEGIN,
});

export const fetchUSPRESuccess = (senderpre) => {
  return {
    type: FETCH_USENDERPRE_SUCCESS,
    payload: { senderpre },
  };
};

export const fetchUSPREFailure = (error) => ({
  type: FETCH_USENDERPRE_FAILURE,
  payload: { error },
});

export const fetchUSenderpre = (jwtdata) => {
  return (dispatch) => {
    dispatch(fetchUSPREBegin());
    return axios
      .post(apiUrlLive + "profilepreset1.php", qs.stringify(jwtdata))
      .then((response) => {
        dispatch(fetchUSPRESuccess(response.data));
      })
      .catch((error) => dispatch(fetchUSPREFailure(error)));
  };
};
// Update Sender Profile

export const updatesp = (updatesp) => {
  return (dispatch) => {
    dispatch(createSPBegin());
    return axios
      .post(`${apiUrlLive}senderprofileupdate.php`, qs.stringify(updatesp))
      .then((response) => {
        if (response.data.statuscode === "1") {
          dispatch(createSPSuccess(updatesp));
          dispatch(
            fetchUSenderpre({ jwt: localStorage.token, id: updatesp.id })
          );
          dispatch(setAlert(response.data.message, "success", "updatesenderp"));
        }
        if (response.data.statuscode === "0") {
          dispatch(createSPFailure(""));
          dispatch(
            setAlert(
              "Something went wrong.Please try again.",
              "error",
              "updatesenderp"
            )
          );
        }
      })
      .catch((error) => dispatch(createSPFailure(error)));
  };
};

export const updatesenderpview = (createpro) => {
  return (dispatch) => {
    dispatch(createSPVBegin());
    return axios
      .post(`${apiUrlLive}senderprofileupdate.php`, qs.stringify(createpro))
      .then((response) => {
        dispatch(createSPVSuccess(response.data));
      })
      .catch((error) => dispatch(createSPVFailure(error)));
  };
};

// Set engagement

export const setengagementBegin = () => ({
  type: SET_ENGAGEMENT_BEGIN,
});

export const setengagementSuccess = (setengagement) => {
  return {
    type: SET_ENGAGEMENT_SUCCESS,
    payload: { setengagement },
  };
};

export const setengagementFailure = (error) => ({
  type: SET_ENGAGEMENT_FAILURE,
  payload: { error },
});

export const setengagement = (jwtdata) => {
  return (dispatch) => {
    dispatch(setengagementBegin());
    return axios
      .post(apiUrlLive + "setengagement.php", qs.stringify(jwtdata))
      .then((response) => {
        dispatch(setengagementSuccess(response.data));
      })
      .catch((error) => dispatch(setengagementFailure(error)));
  };
};
