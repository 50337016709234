import React, { Component } from "react";
import MyEmail from "./MyEmail";
import { fetchDummyData } from "../actions/dashboard";
import LoaderUi from "../shared/Loader";
import { connect } from "react-redux";

class MyEmailContainter extends Component {
  state = {
    jwt: localStorage.token,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchDummyData(this.state));
  }

  render() {
    var { loading8, dashdummy } = this.props;

    if (loading8) {
      return <LoaderUi />;
    }

    var default_gmail_emails = dashdummy.default_gmail_emails;
    var number_of_emails = dashdummy.number_of_emails;

    var defaultyahoo = number_of_emails - default_gmail_emails;

    return <MyEmail dashdummy={dashdummy} defaultyahoo={defaultyahoo} />;
  }
}

const mapStateToProps = (state) => ({
  dashdummy: state.dash.items8,
  loading8: state.dash.loading8,
  error8: state.dash.error8,
});

export default connect(mapStateToProps, null)(MyEmailContainter);
