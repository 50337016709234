import {
  FETCH_REPORTS_BEGIN,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  FETCH_CHART_REPORTS_BEGIN,
  FETCH_CHART_REPORTS_SUCCESS,
  FETCH_CHART_REPORTS_FAILURE,
  BASE_URL_NODE_LIVE,
} from "./types";
import axios from "axios";

const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const fetchReportsBegin = () => ({
  type: FETCH_REPORTS_BEGIN,
});

export const fetchReportsSuccess = (reports) => {
  return {
    type: FETCH_REPORTS_SUCCESS,
    payload: { reports },
  };
};

export const fetchReportsFailure = (error) => ({
  type: FETCH_REPORTS_FAILURE,
  payload: { error },
});

export const fetchReports = (keyword) => {
  return (dispatch) => {
    dispatch(fetchReportsBegin());
    return axios
      .post(apiUrlLive + "broadcast_report.php", qs.stringify(keyword))
      .then((response) => {
        dispatch(fetchReportsSuccess(response.data));
      })
      .catch((error) => dispatch(fetchReportsFailure(error)));
  };
};

export const filterReports = (keyword) => {
  return (dispatch) => {
    dispatch(fetchReportsBegin());
    return axios
      .post(apiUrlLive + "broadcast_report.php", qs.stringify(keyword))
      .then((response) => {
        dispatch(fetchReportsSuccess(response.data));
      })
      .catch((error) => dispatch(fetchReportsFailure(error)));
  };
};

export const fetchChartReportsBegin = () => ({
  type: FETCH_CHART_REPORTS_BEGIN,
});

export const fetchChartReportsSuccess = (reportschart) => {
  return {
    type: FETCH_CHART_REPORTS_SUCCESS,
    payload: { reportschart },
  };
};

export const fetchChartReportsFailure = (error) => ({
  type: FETCH_CHART_REPORTS_FAILURE,
  payload: { error },
});

export const fetchChartReports = (keyword) => {
  return (dispatch) => {
    dispatch(fetchChartReportsBegin());
    return axios
      .post(apiUrlLive + "fetchdashreport.php", qs.stringify(keyword))
      .then((response) => {
        dispatch(fetchChartReportsSuccess(response.data));
      })
      .catch((error) => dispatch(fetchChartReportsFailure(error)));
  };
};

export const filterChartReports = (keyword) => {
  return (dispatch) => {
    dispatch(fetchChartReportsBegin());
    return axios
      .post(apiUrlLive + "fetchdashreport.php", qs.stringify(keyword))
      .then((response) => {
        dispatch(fetchChartReportsSuccess(response.data));
      })
      .catch((error) => dispatch(fetchChartReportsFailure(error)));
  };
};
