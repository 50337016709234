import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "../app/shared/Spinner";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./dashboard/DashboardContainter";
import Billing from "./billing/Billing";
import MyAccount from "./myaccount/MyAccount";
import MyEmail from "./myemail/MyEmailContainter";
import Engagementinstruction from "./senderprofile/Engagementinstruction";
import Senderemail from "./senderemail/SenderEmailContainter";
import Senderprofile from "./senderprofile/SenderprofileContainter";
import Plan from "./plans/Plan";
import Payment from "./plans/Payment";
import CreateSenderprofile from "./senderprofile/CreateSenderprofileContainter";
import UpdateSenderprofile from "./senderprofile/UpdateSenderprofileContainter";
import ViewSenderprofile from "./senderprofile/ViewSenderprofileContainter";
import AutomateSenderprofile from "./senderprofile/AutomateSenderprofileContainter";
import InviteContainter from "./user-pages/InviteContainter";
import Report from "./report/ReportsContainer";
import Broadcast from "./broadcast/Broadcast";
import Privacy from "../app/shared/Privacy";
import Term from "../app/shared/TermandCondition";
import DeliverabilityTester from "./deliverabilitytester/DeliverabilityTester";

const Forgotpwd = lazy(() => import("./user-pages/ForgotPwd"));
const ResetPwd = lazy(() => import("./user-pages/ResetPwd"));

const Changebilling = lazy(() => import("./billingmethod/Billingmethod"));

const Desktopmm = lazy(() => import("./desktopmm/Desktopmm"));

const Login = lazy(() => import("./user-pages/Login"));
const ClearUser = lazy(() => import("./user-pages/ClearUser"));

const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));
const MainForm = lazy(() => import("./user-pages/MainForm"));

const Emailinboxpm = lazy(() => import("./emailinboxpm/Emailinboxpm"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute
            exact
            path="/dashboard/:id/:name"
            component={Dashboard}
          />
          <PrivateRoute exact path="/myaccount" component={MyAccount} />
          <PrivateRoute exact path="/myaccount/:id" component={MyAccount} />
          <PrivateRoute exact path="/seedemails" component={MyEmail} />
          <PrivateRoute exact path="/broadcasts" component={Broadcast} />
          <PrivateRoute
            exact
            path="/deliverabilitytester"
            component={DeliverabilityTester}
          />
          <PrivateRoute
            exact
            path="/engagementinstruction/:id"
            component={Engagementinstruction}
          />
          <PrivateRoute
            exact
            path="/engagementinstruction/:id/:id2"
            component={Engagementinstruction}
          />

          <PrivateRoute exact path="/desktopmm" component={Desktopmm} />
          <PrivateRoute exact path="/emailinboxpm" component={Emailinboxpm} />
          <PrivateRoute exact path="/reports" component={Report} />
          <PrivateRoute exact path="/reports/:id" component={Report} />

          <Route exact path="/forgotpassword" component={Forgotpwd} />
          <Route exact path="/resetpwd/:q1" component={ResetPwd} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/termsandconditions" component={Term} />
          <Route exact path="/invite/:id" component={InviteContainter} />
          <PrivateRoute exact path="/senderemails" component={Senderemail} />
          <PrivateRoute exact path="/senderemails/:q" component={Senderemail} />
          <PrivateRoute
            exact
            path="/senderprofiles"
            component={Senderprofile}
          />
          <PrivateRoute
            exact
            path="/createsenderprofile"
            component={CreateSenderprofile}
          />
          <PrivateRoute
            exact
            path="/createsenderprofile/:id"
            component={CreateSenderprofile}
          />

          <PrivateRoute
            exact
            path="/createsenderprofile/:id/:q"
            component={CreateSenderprofile}
          />

          <PrivateRoute
            exact
            path="/updatesenderprofile/:id"
            component={UpdateSenderprofile}
          />
          <Route
            exact
            path="/viewsenderprofile/:id"
            component={ViewSenderprofile}
          />

          <Route
            exact
            path="/automatesenderprofile/:id"
            component={AutomateSenderprofile}
          />

          <Route exact path="/billing" component={Billing} />
          <Route exact path="/change-billing" component={Changebilling} />
          <PrivateRoute exact path="/pricing" component={Plan} />
          <PrivateRoute exact path="/pricing/:id" component={Plan} />
          <Route exact path="/payment/:id" component={Payment} />
          <PrivateRoute
            exact
            path="/changepassword"
            component={ChangePassword}
          />

          <Route path="/clearuser" component={ClearUser} />
          <Route exact path="/login/:id/:id2" component={Login} />
          <Route exact path="/login/:id" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/register/:id/:id2/:id3" component={MainForm} />
          <Route path="/register/:id/:id2" component={MainForm} />
          <Route path="/register/:id" component={MainForm} />
          <Route path="/register" component={MainForm} />

          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
