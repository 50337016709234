import React, { Component } from "react";
import { connect } from "react-redux";

class Payment extends Component {
  state = {
    jwt: localStorage.token,
  };

  componentDidMount() {
    if (this.state.jwt && this.state.jwt !== undefined) {
      window.location =
        "https://cas.inboxally.com/API/stripe_payment.php?plan_id=" +
        this.props.match.params.id +
        "&jwt=" +
        this.state.jwt;
    } else {
      window.location =
        "https://cas.inboxally.com/API/stripe_payment.php?plan_id=" +
        this.props.match.params.id;
    }
  }

  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0"></div>
      </div>
    );
  }
}

export default connect(null, null)(Payment);
