import React, { Component } from "react";
import { Link } from "react-router-dom";
import GaugeChart from "react-gauge-chart";
import {
  fetchDG,
  fetchLER,
  fetchDN,
  fetchAvInbox,
  fetchDelImp,
} from "../actions/dashboard";
import { connect } from "react-redux";
import Multilinechart from "../charts/MultilinechartContainter";
import Donut from "./Donut";
import LoaderUi from "../shared/Loader";
import { fetchSenderprofiles } from "../actions/senderprofiles";
import { fetchBroadcast } from "../actions/broadcast";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Latestbroadcast from "./Latestbroadcast";
import Latestactions from "./Latestactions";
import { updatedefualt } from "../actions/auth";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { SENDER_PROFILEDASHBOARD } from "../utils/tooltip";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
    maxWidth: 360,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export class Dashboard extends Component {
  state = {
    jwt: localStorage.token,
    sender_profile_id: this.props.user.default_dashboard
      ? this.props.user.default_dashboard === "0"
        ? ""
        : this.props.user.default_dashboard
      : "",
    sender_profile_name: "",
    csender_profile_id: this.props.user.default_dashboard
      ? this.props.user.default_dashboard === "0"
        ? ""
        : this.props.user.default_dashboard
      : "",
    csender_profile_name: "",
    email_type: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      sender_profile_id: this.props.myuser.default_dashboard
        ? this.props.myuser.default_dashboard === "0"
          ? ""
          : this.props.myuser.default_dashboard
        : "",
    });
    this.props.dispatch(fetchBroadcast(this.state));
    this.props.dispatch(fetchDG(this.state));

    this.props.dispatch(fetchDN(this.state));
    this.props.dispatch(fetchAvInbox(this.state));
    this.props.dispatch(fetchDelImp(this.state));
    this.props.dispatch(fetchSenderprofiles(this.state));
    this.getData();
    this.getActionData();
    this.intervalID = setInterval(this.getData, 30000);
    this.intervalActionID = setInterval(this.getActionData, 30000);
  }

  componentDidUpdate(prevProps, prevState) {
    //var newstate={jwt: localStorage.token,sender_profile_id:prevState.sender_profile_id,email_type:prevState.email_type};

    if (this.state.sender_profile_id !== prevState.sender_profile_id) {
      this.props.dispatch(fetchBroadcast(this.state));
      this.props.dispatch(fetchDG(this.state));
      this.props.dispatch(fetchDN(this.state));
      this.props.dispatch(fetchAvInbox(this.state));
      this.props.dispatch(fetchDelImp(this.state));
      this.props.dispatch(fetchSenderprofiles(this.state));
      this.getData();
      this.getActionData();
      this.intervalID = setInterval(this.getData, 30000);
      this.intervalActionID = setInterval(this.getActionData, 30000);
    }

    if (this.state.email_type !== prevState.email_type) {
      this.props.dispatch(fetchBroadcast(this.state));
      this.props.dispatch(fetchDG(this.state));
      this.props.dispatch(fetchLER(this.state));
      this.props.dispatch(fetchDN(this.state));
      this.props.dispatch(fetchAvInbox(this.state));
      this.props.dispatch(fetchDelImp(this.state));
    }
  }

  getData = () => {
    this.props.dispatch(fetchBroadcast(this.state));
  };

  getActionData = () => {
    this.props.dispatch(fetchLER(this.state));
  };

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearInterval(this.intervalActionID);
  }

  buildOptions = (senderprofile) => {
    var arr = [];

    if (senderprofile && senderprofile.length > 0) {
      for (let i = 0; i < senderprofile.length; i++) {
        if (senderprofile[i][6] === "1") {
          arr.push(
            <option
              value={senderprofile[i][4] + "/" + senderprofile[i][0]}
              key={i - 1}
            >
              {senderprofile[i][0]}
            </option>
          );
        }
      }
    }
    return arr;
  };

  setAsDefualt = (e) => {
    e.preventDefault();
    this.props.onUpdateDefualt({
      jwt: localStorage.token,
      id: this.state.sender_profile_id,
    });
  };

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value });
  };

  changeUrl = (e) => {
    var selectedid = e.target.value.split("/");

    if (e.target.value !== "") {
      this.setState({
        sender_profile_id: selectedid[0],
        sender_profile_name: selectedid[1],
      });
      this.setState({
        csender_profile_id: selectedid[0],
        csender_profile_name: selectedid[1],
      });
    } else {
      this.setState({
        sender_profile_id: "0",
        sender_profile_name: "",
        csender_profile_id: "0",
        csender_profile_name: "",
      });
    }
  };

  render() {
    var senderprofilesname = "";

    var dashdummy = this.props.dashdummy;

    var emailtype = this.state.email_type;

    var {
      broadcast,
      loading2,
      dashdg,
      dashler,
      dashddn,
      loading6,
      dashdav,
      dashddelim,
      loading7,
      senderloading,
      senderprofiles,
      userloading,
    } = this.props;

    if (senderprofiles.data) {
      var senderprofilesdata = senderprofiles.data.filter(
        (senderprofile) => senderprofile[3] !== "NA"
      );
      if (this.state.sender_profile_id !== "0") {
        senderprofilesname = senderprofiles.data.filter(
          (senderprofile) => senderprofile[4] === this.state.sender_profile_id
        );
      }
    }
    if (senderprofilesname[0]) {
      senderprofilesname = senderprofilesname[0][0];
    }

    if (senderloading || userloading || loading2 || loading6 || loading7) {
      return <LoaderUi />;
    }

    var defualtselect = this.state.sender_profile_id
      ? ((this.state.sender_profile_id === "0"
          ? ""
          : this.state.sender_profile_id +
            "/" +
            (this.state.sender_profile_name
              ? this.state.sender_profile_name
              : senderprofilesname)): "")
      : "";

    var emailsremaining =
      parseInt(dashdg.plan_emails) - parseInt(dashdg.emails_today);

    return (
      <div>
        <div className="page-header">
          <div className="col-sm-6 col-md-8">
            <div className="row ">
              <div className="col-sm-4 zeromarginpadding">
                <h1 className="page-title h1lineheight">
                  Select sender profile
                </h1>
              </div>

              <div className="col-sm-4 zeromarginpadding">
                <select
                  className="form-control"
                  defaultValue={defualtselect}
                  onChange={this.changeUrl}
                  style={{ fontSize: "16px" }}
                >
                  <option value="">All sender profiles</option>
                  {this.buildOptions(senderprofilesdata)}
                </select>
              </div>

              <div className="col-sm-1 zeromarginpadding text-center">
                <HtmlTooltip
                  title={SENDER_PROFILEDASHBOARD}
                  placement="top-start"
                >
                  <span className="h1lineheight">
                    <InfoOutlinedIcon className="toottipcls" />
                  </span>
                </HtmlTooltip>
              </div>

              {(this.state.sender_profile_id
                ? this.state.sender_profile_id
                : "0") !== this.props.user.default_dashboard && (
                <div className="col-sm-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mr-1 mt-1"
                    onClick={this.setAsDefualt}
                  >
                    Set as default
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="col-sm-6 col-md-4 zeromarginpadding">
            {/* <h1 className="page-title"> {this.state.sender_profile_name?this.state.sender_profile_name:senderprofilesname} Dashboard   </h1> */}

            {dashdummy.multiple_email_type && (
              <div className="float-right mb-2 ">
                <div className="form-inline">
                  <div className="form-group">
                    <h1
                      className="page-title h1lineheight"
                      style={{ marginRight: "26px" }}
                    >
                      Email provider
                    </h1>
                    <select
                      className="form-control mt-1"
                      style={{ fontSize: "16px" }}
                      name="email_type"
                      onChange={this.handleInputChange}
                      value={this.state.email_type}
                    >
                      <option value="">All</option>
                      <option value="Gmail">Gmail</option>
                      <option value="Yahoo">Yahoo</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-sm-6 grid-margin stretch-card">
            <div className="card ">
              <div className="card-body margin4padding">
                <h4 className="mt-5 ml-3">Emails remaining today</h4>
                <p style={{ fontSize: "12px" }} className="ml-3 mt-2">
                  Note: These figures may be delayed as long as 4 hours{" "}
                </p>
                <div className="mt-2 text-center">
                  <GaugeChart
                    id="gauge-chart2dash"
                    colors={["#FF5F6D", "#00D0BD"]}
                    textColor={"#000"}
                    startColor="Red"
                    nrOfLevels={20}
                    percent={
                      (((dashdg.plan_emails - dashdg.emails_today) /
                        dashdg.plan_emails) *
                        100) /
                      100
                    }
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="text-center" style={{ marginTop: "-15px" }}>
                  <div className="row">
                    <div className="col-md-4 ppercent-text">
                      &nbsp;&nbsp;&nbsp;0
                    </div>
                    <div className="col-md-4 ppercent-text">
                      {emailsremaining}
                    </div>
                    <div className="col-md-4 ppercent-text">
                      {dashdg.plan_emails} &nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
                <p className="text-center">
                  {process.env.REACT_APP_NAME_PRICING_BUTTON_ENABLE === "1" && (
                    <Link to="/pricing">
                      <button type="button" className="btn btn-outline-primary">
                        Upgrade for more emails
                      </button>
                    </Link>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-sm-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <Latestbroadcast broadcast={broadcast} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-12 col-sm-12 grid-margin stretch-card">
            <div className="row flex-grow">
              <div className="col-xl-12 col-lg-6 col-sm-6">
                <div className="row">
                  <div className="col-md-6  stretch-card">
                    <div className="card deliverybox">
                      <div className="card-body">
                        <BootstrapTooltip
                          title="% change in inboxing from 7 days ago till today"
                          placement="top-start"
                        >
                          <div>
                            <b>
                              Deliverability improvement over the last 7 days
                            </b>
                            <h1 className="text-center white">
                              {dashddelim.improvement}{" "}
                            </h1>
                          </div>
                        </BootstrapTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 stretch-card">
                    <div className="card  averageinbox">
                      <div className="card-body">
                        <BootstrapTooltip
                          title="Mean of inbox % over last 7 days"
                          placement="top-start"
                        >
                          <div>
                            <b>
                              Average inboxing % over the last 7 days
                              &nbsp;&nbsp;&nbsp;
                            </b>
                            <h1 className="text-center white">
                              {dashdav.inboxed}
                            </h1>
                          </div>
                        </BootstrapTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12 col-lg-6 col-sm-6 grid-margin-0  grid-margin-0 grid-margin-xl stretch-card">
                <div className="card ">
                  <div className="card-body ">
                    <Donut data={dashddn.data} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <Latestactions dashler={dashler} />
              </div>
            </div>
          </div>
        </div>

        <Multilinechart
          email_type={emailtype}
          user={this.props.user}
          sender_profile_id={this.state.sender_profile_id}
          csender_profile_id={this.state.csender_profile_id}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  broadcast: state.broadcast.items,
  loading: state.broadcast.loading,
  dashdg: state.dash.items2,
  loading2: state.dash.loading2,
  error2: state.dash.error2,
  dashler: state.dash.items3[0],
  loading3: state.dash.loading3,
  error3: state.dash.error3,
  dashddn: state.dash.items5,
  loading5: state.dash.loading5,
  error5: state.dash.error5,
  dashdav: state.dash.items6,
  loading6: state.dash.loading6,
  error6: state.dash.error6,
  dashddelim: state.dash.items7,
  loading7: state.dash.loading7,
  error7: state.dash.error7,
  senderprofiles: state.senderprofiles.items,
  senderloading: state.senderprofiles.loading,
  user: state.auth.user,
  userloading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateDefualt: (distdata) => {
      dispatch(updatedefualt(distdata));
    },
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
