import React, { Component } from "react";
import Multilinechart from "./Multilinechart";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import {fetchDS30} from "../actions/dashboard";
import {fetchDummyData} from "../actions/dashboard";

class MultilinechartContainter extends Component {

state = {
jwt:localStorage.token,
sender_profile_id:this.props.sender_profile_id,
sender_profile_name:'',
csender_profile_id:this.props.csender_profile_id,
csender_profile_name:'',
email_type:this.props.email_type?this.props.email_type:'',

 };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchDummyData(this.state));
    this.props.dispatch(fetchDS30(this.state));

    
  }

handleInputChange = (e) => {
    this.setState({ email_type: e.currentTarget.value });

    console.log(this.state.email_type);

  };
  
componentDidUpdate(prevProps,prevState) {

console.log(prevProps);
console.log(prevState);


  if (this.state.sender_profile_id!== prevState.sender_profile_id || (this.state.email_type !== prevState.email_type) ) {
  


   this.props.dispatch(fetchDS30(this.state));
 
}

}

static getDerivedStateFromProps(nextProps, prevState) {
  
 if (nextProps.email_type !== prevState.email_type) { 

     return {
        email_type: nextProps.email_type,
       
      };

     } 

return null;
  
  }





  buildOptions = (senderprofile) => {
    var arr = [];
    
    if (senderprofile && senderprofile.length > 0) {
    for (let i = 0; i <senderprofile.length; i++) {
    if(senderprofile[i][6]==='1')
    {
     
    arr.push(<option value={senderprofile[i][4]+'/'+senderprofile[i][0]} key={i-1}>{senderprofile[i][0]}</option>);
    }
    }
    }
    return arr;
    };

    changeUrlchart = (e) => {
      var selectedid=e.target.value.split('/');
       
      if(e.target.value!=='')
      {
      
     this.setState({ csender_profile_id: selectedid[0],csender_profile_name:selectedid[1]});
      
    }
      else{
      this.setState({ csender_profile_id:'0',csender_profile_name:''});
        
      }
    
    }

render() {
 

  

  var senderprofilesname='';
  var csenderprofilename;


  var {loading4,dashds30,dashdummy,senderloading,senderprofiles} = this.props;
  
  
  
  
  if(senderprofiles.data)
  {
  var senderprofilesdata = senderprofiles.data.filter(senderprofile => senderprofile[3]!=='NA'); 
  if(this.state.sender_profile_id!=='0')
  {
  
    senderprofilesname = senderprofiles.data.filter(senderprofile => senderprofile[4]===this.state.sender_profile_id); 
  
  }
  
  }
  if(senderprofilesname[0])
  {
  senderprofilesname=  senderprofilesname[0][0];
  }
  
  if(this.state.csender_profile_name)
  {
  
  csenderprofilename=this.state.csender_profile_name;
  
  }
  else{
  

if((this.props.user.default_dashboard==='0') && this.state.csender_profile_id==='')
{
csenderprofilename='All';
}
  else if((this.props.user.default_dashboard!=='') && this.state.csender_profile_id!=='0')
   {


    csenderprofilename=senderprofilesname;
   
  }
   else
   {


   csenderprofilename='All';
   }
  
    

  }
  
  
  
  
  if(senderloading) {
  return (<LoaderUi/>) 
  }
  
  if(loading4)
  {
  dashds30='';
  }
  
 var defualtselect=this.state.sender_profile_id?((this.state.sender_profile_id
==='0')?'':this.state.sender_profile_id+'/'+(this.state.sender_profile_name?this.state.sender_profile_name:senderprofilesname):''):'';
  
  
  
  
 





  return (

  
    <React.Fragment>

    
<div className="page-header">
<div className="col-sm-12 col-md-4">
<div className="row " style={{'float':'left'}}>
<div className="col-sm-12   zeromarginpadding">
<select className="form-control" defaultValue={defualtselect}  onChange={this.changeUrlchart} style={{'fontSize':'16px'}}>
<option value="">All sender profiles</option>
{this.buildOptions(senderprofilesdata)}
</select>

</div></div>   


</div>

<div className="col-sm-12 col-md-8">
{dashdummy.multiple_email_type && <div className="row " style={{'float':'right'}}>
<div className="col-sm-12   zeromarginpadding">
{/* <select 
className="form-control" defaultValue={this.state.email_type} name="email_type" onChange={this.handleInputChange}  style={{'fontSize':'16px'}} >
<option value="">All Email Providers</option>
<option value="Gmail">Gmail</option>
<option value="Yahoo">Yahoo</option>
</select> */}

</div>

</div>}
</div>

</div>




<div className="row">
<div className="col-md-12 grid-margin stretch-card">
<div className="card">
<div className="card-body">

<Multilinechart key={0}  data={dashds30.data?dashds30.data.[this.state.csender_profile_id?this.state.csender_profile_id:0]:''}  sender_profile_name={csenderprofilename}   />  
</div>
</div>
</div>
</div>



{(this.state.csender_profile_id==='') && senderprofilesdata && senderprofilesdata.map((value, index) => {


if(value[6]==='1')
{
  return (<div className="row">
  <div className="col-md-12 grid-margin stretch-card">
  <div className="card">
  <div className="card-body">
  <Multilinechart key={Math.random()}  data={dashds30?dashds30.data.[value[4]]:''} sender_profile_name={value[0]}  />
  </div>
  </div>
  </div>
  </div>)
}
else
{
  return null;
}
 


}

)}


</React.Fragment>


        );


  }
}



const mapStateToProps = (state) => ({
  
  dashds30: state.dash.items4,
  loading4: state.dash.loading4,
  error4: state.dash.error4,
  dashdummy: state.dash.items8,
  senderprofiles: state.senderprofiles.items,
  senderloading: state.senderprofiles.loading,
 
  });



export default connect(mapStateToProps, null)(MultilinechartContainter);





