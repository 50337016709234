import {
  FETCH_SUBJECT_BEGIN,
  FETCH_SUBJECT_SUCCESS,
  FETCH_SUBJECT_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
};

export default function mySubjectReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.sub,
      };

    case FETCH_SUBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
