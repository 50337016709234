import {
  CHECK_INVITECODE_BEGIN,
  CHECK_INVITECODE_SUCCESS,
  CHECK_INVITECODE_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
};

export default function invitecodeReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_INVITECODE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CHECK_INVITECODE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.invitecode,
      };

    case CHECK_INVITECODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
