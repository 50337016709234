import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchMyemails, shuffleseedemails } from "../actions/myemails";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "../utils/theme";
import { fetchMyplandetail } from "../actions/plan";
import LoaderUi from "../shared/Loader";
import { CSVLink } from "react-csv";
import initHelpHero from "helphero";
import { SEEDEMAIL } from "../utils/message";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Chip from "@material-ui/core/Chip";

export class MyEmail extends Component {
  state = {
    dwdno: 0,
    todwdno: 999,
    jwt: localStorage.token,
    learnmore: true,
    hide: false,
    learnmore2: true,
    hide2: false,
    learnmore3: true,
    hide3: false,
    type: "All",
    validationErrors: {},
    shuffle_gmail: this.props.dashdummy.default_gmail_emails,
    shuffle_yahoo: this.props.defaultyahoo,
    totalemails: this.props.dashdummy.number_of_emails,
  };

  validators = {
    dwdno: (str) => this.fromvadition(str),
  };

  fromvadition = (str) => {
    if (str !== "" && str > this.state.todwdno) {
      return "From should be greater than To No.";
    }
  };

  validate = (name) => {
    const value = this.state[name];
    let error = this.validators.hasOwnProperty(name)
      ? this.validators[name](value)
      : "";
    this.setState(({ validationErrors }) => ({
      validationErrors: { ...validationErrors, [name]: error },
    }));
    return error;
  };

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value }, () =>
      this.validate(fieldName)
    );
  };

  handleInputChangeV = (e, limittotal, yahoomax) => {
    var yahoosuffle = limittotal - e.currentTarget.value;

    yahoosuffle = yahoosuffle < yahoomax ? yahoosuffle : yahoomax;

    this.setState({
      shuffle_gmail: e.currentTarget.value,
      shuffle_yahoo: yahoosuffle,
    });
  };

  onLeanrMore = (e) => {
    this.setState({ learnmore: !this.state.learnmore, hide: !this.state.hide });
  };
  onHide = (e) => {
    this.setState({ learnmore: !this.state.learnmore, hide: !this.state.hide });
  };
  onLeanrMore2 = (e) => {
    this.setState({
      learnmore2: !this.state.learnmore2,
      hide2: !this.state.hide2,
    });
  };
  onHide2 = (e) => {
    this.setState({
      learnmore2: !this.state.learnmore2,
      hide2: !this.state.hide2,
    });
  };

  onLeanrMore3 = (e) => {
    this.setState({
      learnmore3: !this.state.learnmore3,
      hide3: !this.state.hide3,
    });
  };
  onHide3 = (e) => {
    this.setState({
      learnmore3: !this.state.learnmore3,
      hide3: !this.state.hide3,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchMyemails(this.state));
    this.props.dispatch(fetchMyplandetail(this.state));
  }

  buildOptions = (myemail) => {
    var arr = [];

    if (myemail && myemail.length > 0) {
      for (let i = 1; i <= myemail.length; i++) {
        arr.push(
          <option value={i - 1} key={i - 1}>
            {i}
          </option>
        );
      }
    }
    return arr;
  };

  buildOptions1 = (myemail, yahoo, total) => {
    var i = total - yahoo;
    if (i < 0) {
      i = 0;
    }

    var arr = [];

    if (myemail && myemail > 0) {
      for (i; i <= myemail; ) {
        arr.push(
          <option value={i} key={i}>
            {i}
          </option>
        );
        if (myemail < 100) {
          i += 25;
        } else if (myemail < 1000) {
          i += 50;
        } else {
          i += 100;
        }
      }
    }

    return arr;
  };

  onDownloadEvent = () => {
    var hlp = initHelpHero("artQ7EmmPkC");
    hlp.update({ hasDownloadedSeedEmails: true });
  };

  onshuffleyes = (e) => {
    this.props.onshuffleyessub(this.state);
  };

  onshuffle = (e) => {
    confirmAlert({
      message: `Are you sure you want to proceed?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.onshuffleyes(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  render() {
    var {
      loading,
      myemails,
      loading2,
      loading3,
      user,
      loading8,
      dashdummy,
    } = this.props;

    const { learnmore, hide, learnmore3, hide3 } = this.state;
    var userType = localStorage.getItem("userType");

    var sufffle;

    if (userType === "Admin") {
      sufffle = dashdummy.shuffle_seed_email;
    } else {
      sufffle = dashdummy.shuffle_seed_email;
    }

    if (user && user.status === "0") {
      return (
        <div>
          <div className="page-header">
            <div className="col-md-3 text-left">
              <h3 className="page-title"> Seed Emails </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  {learnmore && <p>{SEEDEMAIL} </p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const headers = [
      { label: "Email", key: "email" },
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Status", key: "status" },
    ];

    if (loading || loading2 || loading3 || loading8) {
      return <LoaderUi />;
    }

    //var plandata = plan ? plan[0] : "";

    const options = {
      filterType: "checkbox",
      filter: false,
      viewColumns: false,
      elevation: 0,
      selectableRows: "none",
      download: false,
      pagination: true,
      print: false,
    };

    const columns = ["Email", "First Name", "Last Name"];

    if (myemails.data) {
      var farray = myemails.data.map((obj) => Object.values(obj));
      var exportdata = myemails.data.slice(
        this.state.dwdno,
        parseInt(this.state.todwdno) + 1
      );

      var uniquearrayresult = new Set();
      var key = "type";
      myemails.data.forEach(function (item) {
        if (item.hasOwnProperty(key)) {
          uniquearrayresult.add(item[key]);
        }
      });

      var uniqueservice_pro = [...uniquearrayresult];
      var countservice_pro = uniqueservice_pro.length;
    }

    var totalemail = myemails.data ? myemails.data.length : "";

    if (this.state.type !== "All") {
      var filterdata = [];
      for (let i = 0; i < myemails.data.length; i++) {
        if (myemails.data[i].type === this.state.type) {
          filterdata.push(myemails.data[i]);
        }
      }
    }

    return (
      <div>
        {!dashdummy.shuffling && (
          <div>
            <div className="page-header">
              <div className="col-md-3 text-left">
                <h3 className="page-title"> Seed Emails </h3>
              </div>

              {process.env.REACT_APP_NAME_PRICING_BUTTON_ENABLE === "1" && (
                <div className="col-md-3 text-center">
                  <span className="d-flex justify-content-center mr-6">
                    &nbsp;&nbsp;
                    <Link to="/pricing">
                      <button type="button" className="btn btn-primary">
                        Upgrade Now
                      </button>
                    </Link>
                  </span>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    {learnmore && (
                      <p>
                        These are all of your seed emails for your plan. You
                        will need to download them to use our service. Note, it
                        is best to send emails in their current order.{" "}
                        <Link
                          to="#"
                          onClick={this.onLeanrMore}
                          style={{
                            color: "#000",
                            "text-decoration": "underline",
                          }}
                        >
                          Learn More
                        </Link>
                      </p>
                    )}
                    {hide && (
                      <p>
                        These are all of your seed emails for your plan. You
                        will need to download them to use our service. Note, it
                        is best to send emails in their current order. If you
                        are not sending all at once or sending very slowly,
                        please send to emails using their order in the download
                        as they have been specifically ordered for best results
                        when sent over an extended time period.{" "}
                        <Link
                          to="#"
                          onClick={this.onHide}
                          style={{
                            color: "#000",
                            "text-decoration": "underline",
                          }}
                        >
                          Read Less
                        </Link>
                      </p>
                    )}

                    <div className="row grid-margin">
                      <div className="col-md-4">
                        No of emails : {totalemail}
                      </div>
                      <div className="col-md-12 mt-3">
                        <b>Note :</b> To keep seed emails effective we might
                        replace some percentage of seed emails therefore
                      </div>
                      <div className="col-md-12 ml-5 mt-1">
                        we recommend you to re download seed emails monthly.
                      </div>
                      {/* <div className="col-md-4 text-right txtrgt">
No of emails per day : {plandata.number_of_emails_per_day}
</div> */}
                    </div>

                    <form className="form-sample">
                      <div className="row">
                        <div className="col-md-8">
                          <MuiThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                              title={""}
                              data={farray}
                              columns={columns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </div>

                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-12 grid-margin ">
                              <h4 className="text-center">Download as CSV</h4>
                            </div>

                            {countservice_pro > 1 && (
                              <React.Fragment>
                                <div className="col-md-6">
                                  <span className="float-left mt-2">
                                    Email Provider
                                  </span>
                                </div>

                                <div className="col-md-6">
                                  <select
                                    name="type"
                                    className="form-control"
                                    onChange={this.handleInputChange}
                                    defaultValue={this.state.type}
                                  >
                                    <option value="All">All</option>
                                    <option value="Gmail">Gmail</option>
                                    <option value="Yahoo">Yahoo</option>
                                  </select>
                                </div>
                              </React.Fragment>
                            )}

                            <div className="col-md-12 grid-margin ">
                              <div className="col text-center mt-3">
                                {this.state.type === "All" && (
                                  <CSVLink
                                    data={myemails.data ? myemails.data : ""}
                                    headers={headers}
                                    filename={"allseedemails.csv"}
                                    className="btn btn-primary"
                                    onClick={this.onDownloadEvent}
                                  >
                                    Download {this.state.type} Seed Emails
                                  </CSVLink>
                                )}
                                {this.state.type !== "All" && (
                                  <CSVLink
                                    data={filterdata ? filterdata : ""}
                                    headers={headers}
                                    filename={
                                      this.state.type.toLowerCase() +
                                      "seedemails.csv"
                                    }
                                    className="btn btn-primary"
                                    onClick={this.onDownloadEvent}
                                  >
                                    Download {this.state.type} Seed Emails
                                  </CSVLink>
                                )}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <p></p>

                              <div className="col text-center ">
                                <p className="lead">OR</p>
                              </div>

                              <p>
                                Choose a specific number to download. This is
                                useful if you will be ramping up by day as this
                                will let you download specific numbers of
                                emails, each in an appropriately labeled file.
                              </p>
                            </div>

                            <div className="col-md-6">
                              <span className="float-left">From</span>
                              <select
                                name="dwdno"
                                className="form-control"
                                onChange={this.handleInputChange}
                              >
                                {this.buildOptions(myemails.data)}
                              </select>
                            </div>

                            <div className="col-md-6">
                              <span className="float-left">To</span>
                              <select
                                name="todwdno"
                                className="form-control"
                                onChange={this.handleInputChange}
                                defaultValue={this.state.todwdno}
                              >
                                {this.buildOptions(myemails.data)}
                              </select>
                            </div>

                            <div className="col-md-12 btnpd mt-2 text-center">
                              <div style={{ fontSize: 14, color: "red" }}>
                                {this.state.validationErrors.dwdno}
                              </div>
                              {!this.state.validationErrors.dwdno && (
                                <CSVLink
                                  data={exportdata ? exportdata : ""}
                                  headers={headers}
                                  filename={
                                    "my_" +
                                    (parseInt(this.state.dwdno) + 1) +
                                    "_to_" +
                                    (parseInt(this.state.todwdno) + 1) +
                                    "_emails.csv"
                                  }
                                  className="btn btn-primary"
                                  onClick={this.onDownloadEvent}
                                >
                                  Download selected amount
                                </CSVLink>
                              )}
                            </div>

                            {sufffle && totalemail > 49 && (
                              <div className="col-md-12 btnpd mt-5">
                                <h4 className="text-center">
                                  Shuffle Seed Emails{" "}
                                  <Chip
                                    label="Beta"
                                    color="secondary"
                                    style={{ backgroundColor: "red" }}
                                    size="small"
                                  />
                                </h4>
                                {learnmore3 && (
                                  <p>
                                    You can now request to shuffle your seed
                                    emails once a month and we will reassign you
                                    seed emails from our pool of 5000 seed
                                    emails although some of your old seed emails
                                    might also get reassigned to you.{" "}
                                    <Link
                                      to="#/"
                                      onClick={this.onLeanrMore3}
                                      style={{
                                        color: "#000",
                                        "text-decoration": "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Learn More
                                    </Link>
                                  </p>
                                )}
                                {hide3 && (
                                  <p>
                                    You can now request to shuffle your seed
                                    emails once a month and we will reassign you
                                    seed emails from our pool of 5000 seed
                                    emails although some of your old seed emails
                                    might also get reassigned to you. Please
                                    note, you should not request to shuffle seed
                                    emails if you have already sent a campaign
                                    on your existing seed email list in the past
                                    24 hours as that might lead to lower
                                    engagement in your existing campaign.{" "}
                                    <Link
                                      to="#/"
                                      onClick={this.onHide3}
                                      style={{
                                        color: "#000",
                                        "text-decoration": "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Read Less
                                    </Link>
                                  </p>
                                )}

                                <div className="col-md-5 float-left  mb-2">
                                  <span className="float-left">Gmail</span>
                                  <select
                                    name="shuffle_gmail"
                                    className="form-control"
                                    onChange={(e) =>
                                      this.handleInputChangeV(
                                        e,
                                        dashdummy.number_of_emails,
                                        dashdummy.number_of_yahoo_emails
                                      )
                                    }
                                    defaultValue={this.state.shuffle_gmail}
                                  >
                                    {this.buildOptions1(
                                      dashdummy.default_gmail_emails,
                                      dashdummy.number_of_yahoo_emails,
                                      dashdummy.number_of_emails
                                    )}
                                  </select>
                                </div>

                                <div className="col-md-5 float-left mb-2">
                                  <span className="float-left">Yahoo</span>
                                  <input
                                    type="text"
                                    name="shuffle_yahoo"
                                    className="form-control"
                                    value={this.state.shuffle_yahoo}
                                  />
                                </div>

                                <div className="text-center mt-5">
                                  <button
                                    className="btn btn-primary text-center"
                                    onClick={this.onshuffle}
                                    type="button"
                                  >
                                    Shuffle Seed Emails
                                  </button>
                                </div>
                              </div>
                            )}

                            {!sufffle &&
                              totalemail > 100 &&
                              totalemail < 3001 &&
                              countservice_pro === "1" && (
                                <div className="col-md-12 btnpd mt-5">
                                  <h4 className="text-center">
                                    Shuffle Seed Emails{" "}
                                    <Chip
                                      label="Beta"
                                      color="secondary"
                                      style={{ backgroundColor: "red" }}
                                      size="small"
                                    />
                                  </h4>
                                  <p>
                                    Your seed emails were last updated on{" "}
                                    {dashdummy.last_updated}, you can request to
                                    shuffle your seed emails after{" "}
                                    {dashdummy.next_update_after} .{" "}
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {dashdummy.shuffling && (
          <div>
            <div className="page-header">
              <div className="col-md-3 text-left">
                <h3 className="page-title"> Seed Emails </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    Your seed emails are getting shuffled, please check and
                    download your seed emails after 30 minutes.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onshuffleyessub: (distdata) => {
      dispatch(shuffleseedemails(distdata));
    },
    dispatch,
  };
};

const mapStateToProps = (state) => ({
  myemails: state.myemails.items,
  loading: state.myemails.loading,
  error: state.myemails.error,
  plan: state.plan.items.data,
  loading2: state.plan.loading2,
  error2: state.plan.error2,
  isAuthenticated: state.auth.isAuthenticated,
  loading3: state.auth.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(MyEmail);
