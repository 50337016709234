import {
  CHECK_INVITECODE_BEGIN,
  CHECK_INVITECODE_SUCCESS,
  CHECK_INVITECODE_FAILURE,
  BASE_URL_NODE_LIVE,
} from "./types";
import axios from "axios";
import { setAlert } from "./alert";

const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const inviteCodeCheckBegin = () => ({
  type: CHECK_INVITECODE_BEGIN,
});

export const inviteCodeCheckSuccess = (invitecode) => {
  return {
    type: CHECK_INVITECODE_SUCCESS,
    payload: { invitecode },
  };
};

export const inviteCodeCheckFailure = (error) => ({
  type: CHECK_INVITECODE_FAILURE,

  payload: { error },
});

export const inviteCodeCheck = (jwt) => {
  var invitecode = jwt.invitecode;
  var plan_id = jwt.plan_id;
  var email = jwt.email;

  return (dispatch) => {
    dispatch(inviteCodeCheckBegin());
    return axios
      .post(`${apiUrlLive}check_invite_code.php`, qs.stringify(jwt))
      .then((response) => {
        dispatch(inviteCodeCheckSuccess(response.data));

        if (response.data.statuscode === "0") {
          dispatch(
            setAlert(
              "The invite code you have entered is either invalid or has already been used. Contact us for further assistance.",
              "error",
              "invitediv"
            )
          );
        } else {
          window.location.href =
            "/register/" + plan_id + "/" + email + "/" + invitecode;
        }
      })
      .catch((error) => dispatch(inviteCodeCheckFailure(error)));
  };
};
