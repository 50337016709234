import React, { Component } from "react";
import Invite from "./Invite";
import { fetchBD } from "../actions/blockeddomain";
import { connect } from "react-redux";

class InviteContainter extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchBD());
  }

  render() {
    var { blockeddomain } = this.props;

    return (
      <Invite
        blockeddomain={blockeddomain}
        plan_id={this.props.match.params.id}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  blockeddomain: state.blockeddomain.items,
  loading: state.blockeddomain.loading,
  error: state.blockeddomain.error,
});

export default connect(mapStateToProps, null)(InviteContainter);
