import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { logout } from "../actions/auth";
import { connect } from "react-redux";
import { fetchDummyData } from "../actions/dashboard";
import TransitionAlerts from "./TransitionAlerts";
import { setCookie } from "../utils/cookie";
import initHelpHero from "helphero";
import {
  USERSTATUSZERO,
  USERSTATUSMINUSONE,
  USERSTATUSZERODUMMYFALSE,
} from "../utils/message";

class Navbar extends Component {
  state = { jwt: localStorage.token };

  componentDidMount() {
    this.props.dispatch(fetchDummyData(this.state));
  }

  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  onLogout = (e) => {
    this.props.onLogout();
  };

  setZoho = () => {
    if (document.readyState !== "loading") {
      window.$zoho.salesiq.visitor.name(this.props.user.first_name);
      window.$zoho.salesiq.visitor.email(this.props.user.email);
    }
  };

  removechat = () => {
    if (document.readyState !== "loading") {
      window.$zoho.salesiq.floatbutton.visible("hide");
    }
  };

  render() {
    const { user, dashdummy, location } = this.props;
    var usermsg = "";

    const showtooltip = dashdummy.length === 0 ? false : true;
    var hlp = initHelpHero("artQ7EmmPkC");
    if (dashdummy.message && dashdummy) {
      hlp.update({ hasSentBroadcast: true });
    } else {
      hlp.update({ hasSentBroadcast: false });
    }

    const usertooltip = this.props.user.status <= "0" ? true : false;
    const showtooltipmsg =
      "Please note: Your dashboard is currently populated with sample data. This data will be replaced with real data as you start sending emails.";
    if (this.props.user.status === 0 && dashdummy.message) {
      usermsg = USERSTATUSZERO;
    } else if (this.props.user.status === 0 && !dashdummy.message) {
      usermsg = USERSTATUSZERODUMMYFALSE;
    } else {
      usermsg = USERSTATUSMINUSONE;
    }

    if (this.props.user.first_name !== undefined) {
      setCookie(
        "uuname",
        this.props.user.first_name + " " + this.props.user.last_name
      );
    } else {
      setCookie("uuname", "");
    }
    if (this.props.user.email !== undefined) {
      setCookie("uuemail", this.props.user.email);
    } else {
      setCookie("uuemail", "");
    }

    var userType = localStorage.getItem("userType");

    if (this.props.user.first_name !== undefined) {
      if (
        userType !== "Admin" &&
        process.env.REACT_APP_NAME_IS_CHAT_ENABLE !== 0
      ) {
        this.setZoho();
      } else {
        this.removechat();
      }

      //   window.Beacon("logout");

      hlp.identify(this.props.user.email);
      hlp.update();

      //   if(this.props.user.plan==3 ||  this.props.user.plan==4)
      //   {
      //       //alert("1");
      //    // window.Beacon('init', '93b7a01e-add2-42a7-99dc-fb55c477e0a9');
      //   }
      //   else
      //   {
      //    // alert("3");
      //    window.Beacon('init', '5f5efdeb-6fbd-4851-8a77-4121a686145c');
      //   }

      //   window.Beacon('identify',{name:this.props.user.first_name+' '+this.props.user.last_name,email:this.props.user.email});
    } else {
      //alert("2");
      // window.Beacon("logout");
      // window.Beacon('init', '5f5efdeb-6fbd-4851-8a77-4121a686145c');

      if (
        userType !== "Admin" &&
        process.env.REACT_APP_NAME_IS_CHAT_ENABLE !== 0
      ) {
        // window.civchat = {
        //   apiKey: "E4F2ZD",
        //  };
        // window.$zoho.salesiq.afterReady = function () {
        //   window.$zoho.salesiq.field.clear(["name", "email"]);
        //   window.$zoho.salesiq.reset();
        // };
      }
    }

    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <a
            className="navbar-brand brand-logo-mini align-self-center d-lg-none"
            href="!#"
            onClick={(evt) => evt.preventDefault()}
          >
            <img
              src={require("../../assets/images/" +
                process.env.REACT_APP_NAME_MINI_LOGO)}
              alt="logsso"
              className="brand-logo-mini"
              style={{ width: "20%" }}
            />
          </a>
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <i className="mdi mdi-menu"></i>
          </button>

          {this.props.user &&
            !usertooltip &&
            showtooltip &&
            !dashdummy.message &&
            usermsg &&
            location.pathname === "/dashboard" && (
              <div className="card-body text-xs-right">
                <div className="text-xs-right text-sm-left">
                  <span className="pull-center">
                    <TransitionAlerts msg={showtooltipmsg} />
                  </span>
                </div>
              </div>
            )}
          {this.props.user && usertooltip && (
            <div className="card-body text-xs-right">
              <div className="text-xs-right text-sm-left">
                <span className="pull-center">
                  <TransitionAlerts msg={usermsg} />
                </span>
              </div>
            </div>
          )}

          <ul className="navbar-nav navbar-nav-right ml-lg-auto">
            <li className="nav-item  nav-profile border-0">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span className="profile-text">
                    {user.first_name} {user.last_name} &nbsp;
                    <i className="mdi mdi-arrow-down-drop-circle  menu-icon "></i>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <Link
                      to="/myaccount"
                      onClick={() =>
                        window.userengage("event.Viewed_my_account")
                      }
                    >
                      My Account
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <Link to="/#" onClick={this.onLogout}>
                      Sign Out
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user,
    dashdummy: state.dash.items8,
    loading8: state.dash.loading8,
    error8: state.dash.error8,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => {
      window.userengage("event.Loggedout");
      dispatch(logout());
    },
    dispatch,
  };
};

const NavWithRouter = withRouter(Navbar);

export default connect(mapStateToProps, mapDispatchToProps)(NavWithRouter);
