export const PROFILE_NAME_TOOLTIP = "Enter a name to identify this profile that only you will see eg. Hotel clients, Healthcare newsletters, etc.";
export const FROM_EMAIL_TOOLTIP = "Select a sender email to use for your 'from email' . By default only your signup email is available. To add more sender emails, See 'My Emails' tab on the left nav bar.";
export const FOR_DEFAULT_SETTINGS = "For default settings.";
export const TRADITIONAL_WARMUP="When you have a cold domain or IP. Also be sure to ramp up slowly and spread out your sending throughout the day if possible.";
export const DOMAIN_IP_REPAIR="When you are having trouble getting out of the spamfolder. Also it's best to stop sending to your own list and just to ours for a week or two. You should first check and get your domain/IP delisted from any blacklist.";
export const REPITATION_BOOST="When you are sending regularly and feel you should be getting more opens. Send to our (highly engaged) emails before sending to the rest of your list."; 
export const REPITATION_PROTECT="When things are going well but you want to make sure you keep up your good reputation, especially if there is something special coming up such as holiday sale, new product launch, or other important announcement. Send to our (highly engaged) emails before sending to the rest of your list."
export const FOR_CUSTOM_SETTINGS="For customized settings.";
export const OPEN_TOOLTIP="The most basic way to show interest. As this happens before the content is seen, this interest is based solely on some mix of subject, from name and email.";
export const SCROLL_DOWN_TOOLTIP="Highly engaged recipients not only open emails, but scroll down as they read. So long as the content is longer than the page, this should be done for nearly all opened emails.";
export const REMOVE_FROMSPAM_TOOLTIP="When a recipient goes out of their way to take an email out of spam, they are telling the inbox provider, hey, you made a mistake.  Next time, please don't do this again.";
export const REMOVE_FROMPROMOTIONS_TOOLTIP="Moving an email from the promotions folder to the primary inbox tells inbox providers that you disagree with their sorting criteria and it and similar emails should go to the primary inbox.";
export const REPLY_TOOLTIP="Demonstrates that recipient is interested in the content of your email.  We have a set of random generic replies we use for this.";
export const MARKIMP_TOOLTIP="Marking an email as important can essentially be interpreted by the inbox provider as 'This email is extremely valuable, never send it to spam.";
export const CLICK_LINK_TOOLTIP="Demonstrates not only interest in content, but trust, as many recipients won't click on links from sources they deem untrustworthy.";
export const EXCEPT_UNSUBCRIBE = "Except Unsubscribe";
export const SENDER_PROFILE_TOOLTIP = "Profile";
export const EDIT_BUSINESS_EMAIL = "To edit Business email, contact support.";
export const EDIT_BUSINESS_NAME = "To edit Business name, contact support.";
export const MOVE_FROM_INBOX_ASSAFE = "Move from spam to inbox and mark as safe";
export const MOVE_FROM_PROMO = "Move from Promo to Inbox";
export const SENDER_PRIMARYINBOXED = "This includes Primary Inbox & Promotions, for a breakup please refer to the broadcasts page.";
export const SENDER_PRIMARYINBOXEDBROD= "This includes Primary Inbox & Promotions.";
export const SENDER_PROFILEDASHBOARD= "Select all sender profiles to view sum of activity on dashboard across all sender profiles or select individual sender profile to view activity on dashboard of particular sender profile.";
export const PAYMENT_SUCCESS= "Payment Successful.";
export const PAYMENT_FAILED= "Payment Failed.";
