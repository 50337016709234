import {
  FETCH_SENDERE_BEGIN,
  FETCH_SENDERE_SUCCESS,
  FETCH_SENDERE_FAILURE,
  SAVE_SENDERE_BEGIN,
  SAVE_SENDERE_SUCCESS,
  SAVE_SENDERE_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],

  error: null,
};

export default function senderEmailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SENDERE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SENDERE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.senderemails,
      };

    case FETCH_SENDERE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case SAVE_SENDERE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SAVE_SENDERE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SAVE_SENDERE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
