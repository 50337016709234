import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "../utils/theme";
import { fetchBroadcast, fetchFilterBroadcast } from "../actions/broadcastp";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { fetchDummyData } from "../actions/dashboard";
import {
  SENDER_PROFILE_TOOLTIP,
  SENDER_PRIMARYINBOXEDBROD,
} from "../utils/tooltip";
import initHelpHero from "helphero";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export class Broadcast extends Component {
  constructor() {
    super();
    this.state = {
      showHide: false,
      jwt: localStorage.token,
      email_type: "",
    };
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchBroadcast(this.state));
    this.props.dispatch(fetchDummyData(this.state));
    var hlp = initHelpHero("artQ7EmmPkC");
    hlp.update({ hasViewedBroadcasts: true });
  }

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.email_type !== prevState.email_type) {
      this.props.onFilter(this.state);
    }
  }

  render() {
    var { loading, broadcast, dashdummy, loading8 } = this.props;

    if (broadcast.data && dashdummy.message) {
      var broadcastupdated = broadcast.data.map((item, key) => {
        return item;
      });
    }

    const columns = [
      {
        name: "Sender profile",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[0]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[0]
                      ? tableMeta.rowData[0].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              key={2}
              className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root MUIDataTableHeadCell-fixedHeader"
              style={{ cursor: "pointer" }}
            >
              <HtmlTooltip title={SENDER_PROFILE_TOOLTIP} placement="top-start">
                <span className="">{columnMeta.name}</span>
              </HtmlTooltip>
            </th>
          ),
        },
      },
      {
        name: "Subject",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[1]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[1]
                      ? tableMeta.rowData[1].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      {
        name: "From Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[2]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[2]
                      ? tableMeta.rowData[2].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      "Date",
      "Number Emails",
      "Inboxed Primary",
      "Inboxed Promo",
      "In Spam",

      {
        name: "Percent Inboxed",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[8]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[8] ? tableMeta.rowData[8] : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              key={5}
              className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root MUIDataTableHeadCell-fixedHeader"
              style={{ cursor: "pointer" }}
            >
              <HtmlTooltip
                title={SENDER_PRIMARYINBOXEDBROD}
                placement="top-start"
              >
                <span className="">
                  Percent Inboxed <InfoOutlinedIcon className="toottipcls" />
                </span>
              </HtmlTooltip>
            </th>
          ),
        },
      },

      {
        name: "1st Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[9]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[9]
                      ? tableMeta.rowData[9].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      {
        name: "Last Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip
                  title={tableMeta.rowData[10]}
                  placement="top-start"
                >
                  <span className="">
                    {tableMeta.rowData[10]
                      ? tableMeta.rowData[10].slice(0, 7) + "..."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
    ];

    if (loading || loading8) {
      return <LoaderUi />;
    }

    const options = {
      filterType: "checkbox",
      filter: false,
      viewColumns: false,
      elevation: 0,
      selectableRows: "none",
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: "450px",
      download: false,
      print: false,
    };

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Broadcasts </h3>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7"></div>

                  <div
                    className=" col-md-4 "
                    style={{ marginLeft: "72px", paddingBottom: "7px" }}
                  >
                    <select
                      className="form-control form-control"
                      style={{ color: "#495057", display: "none" }}
                    >
                      <option>Auto Detect</option>
                      <option>Cutomized Groupings - Coming soon</option>
                    </select>
                  </div>
                </div>

                <Modal show={this.state.showHide}>
                  <div className="modal-body">
                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <p style={{ "font-weight": "600" }}>
                        Name your new grouping or select one to edit
                      </p>
                      <p style={{ "font-weight": "600" }}>Group Name</p>
                      <p>
                        <select
                          className="form-control form-control col-lg-6"
                          style={{ color: "#495057" }}
                        >
                          <option>Select Group</option>
                        </select>
                      </p>

                      <p
                        style={{
                          "font-weight": "600",
                          margin: "10px 0 10px 0",
                        }}
                      >
                        Select Filters
                      </p>
                    </div>
                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>
                          Sender Profile
                        </label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>=</option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>Subject</label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>=</option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>Date Sent</label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>></option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>
                          1st Email Received
                        </label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>></option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>
                          Last Email Received
                        </label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>=</option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>
                          Inboxed Primary
                        </label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>>=</option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>
                          Inboxed Promotions
                        </label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>=</option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ padding: "0px", margin: "10px 0 10px 0" }}
                    >
                      <div
                        className="col-lg-6"
                        style={{ display: "inline-block" }}
                      >
                        <label style={{ color: "#495057" }}>To Spam</label>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <select
                          className="form-control"
                          style={{ color: "#495057" }}
                        >
                          <option>=</option>
                        </select>
                      </div>
                      <div
                        className="col-lg-3"
                        style={{ display: "inline-block" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <Modal.Footer>
                    <Button
                      className=" btn btn-primary"
                      variant="primary"
                      onClick={() => this.handleModalShowHide()}
                    >
                      Close
                    </Button>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => this.handleModalShowHide()}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>

                <p className="mt-1">
                  Our platform autodetects emails that we receive and groups
                  them into broadcasts. We do this based on Sender Profile,
                  Subject, From email and date. To most accurately view results,
                  we recommend you send any groups of emails within the same
                  calendar day as per your local time zone specified in your
                  settings. Additionally if you are sending multiple broadcasts
                  within the same day with the same from email, it's best to use
                  different subject lines. Using personalization in the body of
                  your email is fine and won't interfere with our broadcast
                  autodetect. DO NOT use personalization in the subject or your
                  broadcasts will not be tracked properly.
                </p>

                {dashdummy.multiple_email_type && (
                  <div className="row  ">
                    <div className="col">
                      <div className="float-right mb-2">
                        <div className="form-inline">
                          <div className="form-group">
                            <label
                              for="exampleInputPassword1"
                              style={{ marginRight: "7px", marginTop: "5px" }}
                            >
                              Email Provider
                            </label>
                            <select
                              className="form-control"
                              name="email_type"
                              onChange={this.handleInputChange}
                              value={this.state.email_type}
                            >
                              <option value="">All</option>
                              <option value="Gmail">Gmail</option>
                              <option value="Yahoo">Yahoo</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <form className="form-sample">
                  <div className="row">
                    <div className="col-md-12">
                      <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                          title={"Broadcasts"}
                          data={broadcastupdated}
                          columns={columns}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFilter: (keyword) => {
      dispatch(fetchFilterBroadcast(keyword));
    },
    dispatch,
  };
};

const mapStateToProps = (state) => ({
  broadcast: state.broadcastp.items,
  loading: state.broadcastp.loading,
  dashdummy: state.dash.items8,
  loading8: state.dash.loading8,
  error8: state.dash.error8,
});

const BroadcastRouter = withRouter(Broadcast);
export default connect(mapStateToProps, mapDispatchToProps)(BroadcastRouter);
