import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./app/reducers";

import jwtDecode from "jwt-decode";

import { setCurrentUser } from "./app/actions/auth";
import setAuthToken from "./app/utils/setAuthToken";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

if (localStorage.token) {
  setAuthToken(localStorage.token);

  store.dispatch(setCurrentUser(jwtDecode(localStorage.token)));
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
