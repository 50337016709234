export const SOME_THING_WENT_WRONG = "Something went wrong.Please try again.";
export const PASSWORD_UPDATED_SUCCESS =
  "Your password has been updated successfully.";
export const EMAIL_VERIFIED = "You have successfully verified your email.";
export const BUSINESS_EMAIL_VALIDATION = "Please enter valid business email.";
export const VALID_BUSINESS_EMAIL_VALIDATION =
  "Please enter valid business email.";
export const ONLY_BUSINESS_EMAIL_VALIDATION =
  "Please enter only business email.";
export const UNIQUE_PROFILE_VALIDATION = "Please enter unique profile name.";
export const ENTER_TEXT_OR_RANDOM_VALIDATION =
  "Please enter text Or click random.";
export const SELECT_EMAIL_VALIDATION = "Please select a from email.";
export const UNIQUE_PRO_VALIDATION =
  "Profile name you entered already exists, please enter unique profile name.";
export const FROM_EMAIL_VALIDATION = "Please select from email to continue.";
export const PROFILE_NAME_VALIDATION =
  "Please enter a unique profile name to continue.";
export const DUPLICATE_PROFILE_VALIDATION =
  "Profile name you entered already exists, please enter a unique profile name to continue";
export const INVALID_EMAIL_VALIDATION = "Please enter a valid email.";
export const PASSWORD_VALIDATION = "Please enter your password.";
export const BUSINESS_EMAIL_CONTINUE_VALIDATION =
  "Please enter your business email, as business email is required to continue.";
export const FIRST_NAME_VALIDATION = "Please enter your first name.";
export const LAST_NAME_VALIDATION = "Please enter your last name.";
export const BUSINESS_NAME_VALIDATION = "Please enter your business name.";
export const PHONE_NO_VALIDATION = "Please enter your phone number.";
export const ONLY_NO_PHONE_NO_VALIDATION =
  "Please only use numbers in your phone number.";
export const NEW_PASSWORD_VALIDATION = "Please enter the new password.";
export const PASSWORD_CONFIRM_VALIDATION =
  "Please enter the password again to confirm.";
export const AGAIN_PASSWORD_CONFIRM_VALIDATION =
  "Please enter the same password again to confirm.";
export const SELECT_PLAN_VALIDATION = "Please select a plan.";
export const UPDATE_PROFILE_SUCCESS =
  "Your profile has been updated successfully.";
export const CREATE_PROFILE_SUCCESS =
  "Your profile has been created successfully.";
export const TRIAL_MSG1 =
  "Please fill in the requested info to access your free trial. No matter which plan you selected, you will be getting a trial plan which let's you send to 50 of our seed emails per day for 7 days.";
export const TRIAL_MSG2 =
  "Note: If you require more (emails, time, etc.), please let us know more about your needs and specific use case for your trial (but still first complete this trial registration).";
export const UPDATE_SENDERPROFILE = "Update Senderprofile";
export const VIEW_SENDERPROFILE = "View Senderprofile";
export const VIEW_INSTRUCTION = "View Instruction";
export const ACTIVATE_PROFILE = "Reactivate  Profile";
export const DEACTIVATE_PROFILE = "Deactivate Profile";
export const DEACTIVATED_PROFILE = "Deactivated Profile";
export const USERSTATUSZERO =
  "Please note: Your subscription has expired, please contact support."; // For Zero case
export const USERSTATUSMINUSONE =
  "Please note: Your subscription has expired, please contact support."; // For Minus one
export const SEEDEMAIL =
  "Please note: Your subscription has expired, please contact support."; // To hide seed emails
export const SEEDEMAILFULL =
  "Please note: Your subscription has expired, please contact support."; //
export const USERSTATUSZERODUMMYFALSE =
  "Please note: Your dashboard is currently populated with sample data & Your subscription has expired,please contact support.";
export const HOWHEAR_VALIDATION = "Please enter how did you hear about us?";
