import React from "react";
import { Spinner } from "reactstrap";
import { Doughnut } from "react-chartjs-2";

export default class Donut extends React.Component {
  render() {
    if (!this.props.data) {
      return (
        <div>
          <Spinner
            color="primary"
            style={{ width: "3rem", height: "3rem", marginLeft: "45%" }}
          />
        </div>
      );
    }

    var doughnutPieData = {
      datasets: [
        {
          data: this.props.data,
          backgroundColor: [
            "rgb(93, 146, 244)",
            "rgba(255,183,15,1)",
            "rgba(255,99,88,1)",
          ],
        },
      ],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: ["Primary Inbox", "Promotions", "Spam"],
    };

    return (
      <React.Fragment>
        <h5 className="mb-2">
          Where your emails have been landing the last 7 days
        </h5>
        <div style={{ height: "220px" }}>
          <Doughnut
            data={doughnutPieData}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              cutoutPercentage: 60,
              tooltips: {
                callbacks: {
                  title: function (tooltipItem, data) {
                    return data["labels"][tooltipItem[0]["index"]];
                  },
                  label: function (tooltipItem, data) {
                    return (
                      " " +
                      data["datasets"][0]["data"][tooltipItem["index"]] +
                      "%"
                    );
                  },
                  titleFontSize: 16,
                  bodyFontSize: 14,
                },
              },
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
