import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchSenderemails, saveSE,verifySE,deleteSE,changesenderemailstatus } from "../actions/senderemails";
import { fetchSenderprofiles,fetchSenderpre} from "../actions/senderprofiles";
import { fetchMyplandetail } from "../actions/plan";
import { connect } from "react-redux";
import Alert from "../layout/Alert";
import LoaderUi from "../shared/Loader";
import initHelpHero from 'helphero';
import {BUSINESS_EMAIL_VALIDATION,VALID_BUSINESS_EMAIL_VALIDATION,ONLY_BUSINESS_EMAIL_VALIDATION} from "../utils/message";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import MAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BlockIcon from '@material-ui/icons/Block';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    padding:'0px',
    backgroundColor: theme.palette.background.paper,
  },
});






const HtmlTooltip = withStyles((theme) => ({
tooltip: {
backgroundColor: "#f5f5f9",
color: "rgba(0, 0, 0, 0.87)",
maxWidth: 220,
fontSize: theme.typography.pxToRem(12),
border: "1px solid #dadde9",
},
}))(Tooltip);




export class Senderemail extends Component {
  state = {
    email: "",
    addmore: true,
    jwt: localStorage.token,
    id:this.props.id,
    host:process.env.REACT_APP_NAME_HOST_NAME?process.env.REACT_APP_NAME_HOST_NAME:'',
    validationErrors: {},
    value: 0,
  };

  handleClick(e) {
    e.preventDefault();
    this.setState({
      addmore: false,
    });
  }
  handleClick2(e) {
    e.preventDefault();
    this.setState({
      addmore: true,
    });
  }

  validators = {
    email: (str) => this.emailvalid(str),
  };

  handleChange2 = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  emailvalid = (str) => {
    const re = /.+@.+\..+/;
    if (str === "") {
      return BUSINESS_EMAIL_VALIDATION;
    } else if (!str.match(re)) {
      return VALID_BUSINESS_EMAIL_VALIDATION;
    } 
    else 
    {
      var stru = str.split("@")[1];
      if (this.props.blockeddomain) {
        var blockeddomain = this.props.blockeddomain;
        blockeddomain = blockeddomain.filter((item) => {
          return (
            item.domain_name
              .toString()
              .toLowerCase()
              .indexOf(stru.toLowerCase()) !==-1
          );
        });

        if (blockeddomain.length > 0) {
          return ONLY_BUSINESS_EMAIL_VALIDATION;
        }
      }
    }
  };

  validate = (name) => {
    const value = this.state[name];
    let error = this.validators.hasOwnProperty(name)
      ? this.validators[name](value)
      : "";
    this.setState(({ validationErrors }) => ({
      validationErrors: { ...validationErrors, [name]: error },
    }));
    return error;
  };

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value }, () =>
      this.validate(fieldName)
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const isValid = Object.keys(this.validators)
      .map(this.validate)
      .every((err) => !err);
    if (isValid) {
      this.props.onAdd(this.state);
      window.userengage('event.Added_sender_email',{sender_email:this.state.email});

      this.setState({
        addmore: true,
      });
    }
  };

  deleteSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    this.props.ondeleteSubmit({jwt:localStorage.token,emailid:e.target.value});

  };



   chnageemailstatus = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
   this.props.onChnageemailstatus({jwt:localStorage.token,emailid:e.target.value,status:0});

  };

 unarchive = (e) => {
   e.preventDefault();
   window.scrollTo(0, 0);
   this.props.onChnageemailstatus({jwt:localStorage.token,emailid:e.target.value,status:1});
};







   handleSubmit2 = (e) => {
    e.preventDefault();
this.props.onVerifySE({jwt:localStorage.token,email:e.target.value,host:this.state.host});


  };

  
   handleSubmit3 = (e) => {
    e.preventDefault();
this.props.onVerifySE({jwt:localStorage.token,email:e.target.value,host:this.state.host,userType:localStorage.getItem('userType')});


  };




  handleReset = () => {
    this.setState({
      email: "",
      validationErrors: {},
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.dispatch(fetchSenderemails(this.state));
    this.props.dispatch(fetchSenderprofiles(this.state));
    this.props.dispatch(fetchMyplandetail(this.state));
    this.props.dispatch(fetchSenderpre(this.state));

    var hlp = initHelpHero('artQ7EmmPkC');
    hlp.update({ hasViewedSenderEmailPage: true});



  }

  render() {
  
  
var {loading, senderemails,senderprofiles, plan,preset,loading2,loading3,classes} = this.props;




  if(loading || loading2 || loading3 ) {

     return (<LoaderUi/>) 
    }

    if(senderprofiles.data)
    {
    var activeresult = senderprofiles.data.filter(senderprofile => senderprofile.[6] ==="1" );
    }




  var plandata = plan ? plan[0] : "";
  var totalasp = activeresult ? activeresult.length :'0';
  var totalsp = senderprofiles.data ? senderprofiles.data.length :'0';
  var buttonstatus = senderprofiles.data ? senderprofiles.data[0][5] : "";
  var buttonstatus2 = ((totalasp?totalasp:0)<(plandata.number_of_sender_profiles))?1:'';
  var buttonstatus3= preset[5]?(preset[5].email.length==='0'?'':preset[5].email.length):'';
  var senderarr = [];
  var senderprofile=senderprofiles.data;
  if (senderprofile && senderprofile.length > 0) {
  for (let i = 0; i <senderprofile.length; i++) {
  senderarr.push(senderprofile[i][1]);
  }
  }





if(senderemails)
 {
  var archivesenderemails = senderemails.filter(senderemail => senderemail.show_email==='0');
}
if(senderemails)
    {
       senderemails = senderemails.filter(senderemail => senderemail.show_email==='1');
 }

var userType= localStorage.getItem('userType');

return (
     <div>
<div className="page-header">
<h3 className="page-title">  Sender Emails</h3>
</div>

<div className="row ">
<div className="col-12 ">
<div className="card ">
<div className="card-body">

<p>{" "} Please enter any emails you will be sending from. This is
necessary for us to track and engage with your emails. You
will be able to choose which of these emails to send from in
your sender profile(s). The number of from emails and sender
profiles you can setup is limited by the plan you chose.
</p>

<MAlert severity="info" className="mt-1">
  Please make sure to create a sender profile for your sender emails BEFORE SENDING as we only detect and engage with emails from those sender email which have active sender profile linked to them.
</MAlert>

</div>
</div>
</div>
</div>

<div className="row">
<div className="col-12">
  
<AppBar
                        position="static"
                        color="default" 
                        style={{'padding':'0px'}}
                        className={classes.root}
                      >
                        <Tabs
                          value={this.state.value}
                          onChange={this.handleChange2}
                          variant="fullWidth"
                          indicatorColor="primary"
                          textColor="primary"
                          aria-label="icon tabs example"
                        >
                        <Tab label="Active sender emails"  />
                        <Tab label="Archived sender emails"/>
                       
                        </Tabs>
                      </AppBar>
</div></div>

<div className="row">
<div className="col-12 grid-margin">
<div className="card mycard">
<div className="card-body">


<TabPanel value={this.state.value} index={0}>


            <div className="row">
                  <div className="col-md-6">
                    <Alert msgdivid="saveSE" />
                  </div>
<div className="col-md-12">
<form className="pt-3" onSubmit={this.handleSubmit}>
                      {senderemails.map((senderemail, index) => (
                        <div 
className="form-row   mb-3">
                          <div className="col-md-3">
                            <span 
className="float-left">{senderemail.email}</span>
                          </div>

                          {senderemail.status==="1" ? (
                            <div className="col-md-9">
                              <div className="col-md-3">
                              <input
                                type="checkbox"
                                
className="form-check-input"
                                id="exampleCheck1"
                                checked
                              />
                              <span 
className="float-left ml-4">Verified</span>
                              
                              
                             </div>
                            
                            <div className="col-md-1 float-left">
      <span 
className="float-left ">
{senderprofile && senderprofile.filter(person => person[6]==='1' &&   person[1]===senderemail.email).map(person => (
<div>
<HtmlTooltip title="Have active sender profile" placement="top-start">
<PlayArrowIcon  style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}} />
</HtmlTooltip>
</div>
))}
{senderprofile && senderprofile.filter(person => person[6]==='0' &&   person[1]===senderemail.email).map(person => (
<div>
<HtmlTooltip title="Have inactive sender profile" placement="top-start">
<BlockIcon     style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}} />
</HtmlTooltip>
</div>
   ))}
</span>
</div>


                            <div className="col-md-2 float-left">
      <span 
className="float-left ">
<button type="button" 
className="btn btn-outline-primary"
onClick={this.chnageemailstatus} value={senderemail.emailid} style={{'marginTop':'-6px'}}>
   Archive  
</button>
     
     </span>
     </div>
    <div className="col-md-5 float-left">
       {((buttonstatus2 && buttonstatus3 && buttonstatus && (senderarr.indexOf(senderemail.email)===-1) ) || totalsp === '0') && (
       <span 
className="float-left "><Link to={'createsenderprofile/'+senderemail.email}>
       <button type="button"  
className="btn btn-outline-primary " style={{'marginTop':'-6px'}}>
       Create Sender Profile
       </button> 
</Link>
</span> 
       )}
</div>
    
   


                            </div>

                          ) : (
                            <div className="col-md-7">
                              
                              <div className="col-md-6">
                                
                              <span 
className="float-left ">

                                <input
                                type="checkbox"
                                
className="mr-2"
                                id="exampleCheck1"
                                unchecked
                              />

                                <button
                                  type="button"
                                  
className="btn btn-outline-primary"
                                   onClick={this.handleSubmit2}
                                   value={senderemail.email}
                                >
                                  Resend verification mail
                                </button>
                                 </span>
                                 </div>
                                 <div className="col-md-2 float-left">
                                 <button type="button" 
className="btn btn-outline-primary"
onClick={this.deleteSubmit} value={senderemail.emailid}> Delete </button>
</div>
{(userType==='Admin') && 
<div className="col-md-4 float-left">
<button type="button" 
className="btn btn-outline-primary"
onClick={this.handleSubmit3} value={senderemail.email}> Admin Verify </button>
</div>}
                              
                                


                            </div>
                          )}
                        </div>
                      ))}

                      {!this.state.addmore && (
                        <div 
className="form-row   mb-3">
                          <div className="col-md-5">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control col-md-8"
                                name="email"
                                placeholder="Email"
                                onChange={this.handleInputChange}
                                value={this.state.email}
                              />
                              <div style={{ fontSize: 14, color: "red" }}>
                                {this.state.validationErrors.email}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <span 
className="float-left ">
                              <button type="submit" 
className="btn btn-primary">
                                Add Email
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                
className="btn btn-outline-primary"
                                onClick={(e) => this.handleClick2(e)}
                              >
                                Close
                              </button>
                            </span>
                          </div>
                        </div>
                      )}

                      {this.state.addmore && (
                        <div className="form-group">
                          <button
                            type="button"
                            
className="btn  btn-primary"
                            onClick={(e) => this.handleClick(e)}
                          >
                            Add new sender email
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>







</TabPanel>
 <TabPanel value={this.state.value} index={1}>

<div className="col-md-12">
<form className="pt-3" onSubmit={this.handleSubmit}>
                      {archivesenderemails.map((senderemail, index) => (
                        <div 
className="form-row   mb-3">
                          <div className="col-md-3">
                            <span 
className="float-left">{senderemail.email}</span>
                          </div>

                          {senderemail.status==="1" ? (
                            <div className="col-md-9">
                              <div className="col-md-3">
                              <input
                                type="checkbox"
                                
className="form-check-input"
                                id="exampleCheck1"
                                checked
                              />
                              <span 
className="float-left ml-4">Verified</span>
                             </div>
<div className="col-md-1 float-left">
      <span 
className="float-left ">
{senderprofile && senderprofile.filter(person => person[6]==='1' &&   person[1]===senderemail.email).map(person => (
<div>
<HtmlTooltip title="Have active sender profile" placement="top-start">
<PlayArrowIcon  style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}} />
</HtmlTooltip>
</div>
))}
{senderprofile && senderprofile.filter(person => person[6]==='0' &&   person[1]===senderemail.email).map(person => (
<div>
<HtmlTooltip title="Have inactive sender profile" placement="top-start">
<BlockIcon     style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}} />
</HtmlTooltip>
</div>
   ))}
</span>
</div>

                              <div className="col-md-2 float-left">
      <span 
className="float-left ">
<button type="button" 
className="btn btn-outline-primary"
onClick={this.unarchive} value={senderemail.emailid} style={{'marginTop':'-6px'}}>
   Unarchive  
</button>
     
     </span>
     </div>
    
   


                            </div>

                          ) : (
                            <div className="col-md-7">
                              
                              

                            </div>
                          )}
                        </div>
                      ))}

                      

                      
                    </form>
                  </div>

 </TabPanel>


</div>
</div>
</div>
</div>
</div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: (userdata) => {
      dispatch(saveSE(userdata));
    },
  onVerifySE: (userdata) => {
      dispatch(verifySE(userdata));
    },
    ondeleteSubmit: (userdata) => {
      dispatch(deleteSE(userdata));
    },
    onChnageemailstatus: (userdata) => {
    dispatch(changesenderemailstatus(userdata));
    },
    dispatch,
  };
};

const mapStateToProps = (state) => ({
  senderemails: state.senderemails.items,
  loading: state.senderemails.loading,
  error: state.senderemails.error,
  senderprofiles: state.senderprofiles.items,
  loading2: state.senderprofiles.loading, 
  error2: state.senderprofiles.error,
  plan: state.plan.items.data,
  loading3: state.plan.loading,
  error3: state.plan.error,
  preset: state.senderprofiles.items2,
  loading4: state.senderprofiles.loading2,
  error4: state.senderprofiles.error2,

});




export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Senderemail));


