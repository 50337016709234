import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchSenderprofilesea } from "../actions/senderprofiles";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import LoaderUi from "../shared/Loader";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "../utils/theme";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { MOVE_FROM_INBOX_ASSAFE, MOVE_FROM_PROMO } from "../utils/tooltip";
import {
  UPDATE_PROFILE_SUCCESS,
  CREATE_PROFILE_SUCCESS,
} from "../utils/message";

import MAlert from "@material-ui/lab/Alert";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export class Engagementinstruction extends Component {
  state = {
    id: this.props.match.params.id,
    id2: this.props.match.params.id2,
    jwt: localStorage.token,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchSenderprofilesea(this.state));
    window.userengage("event.Viewed_engagement_instructions");
  }

  render() {
    var { loading, emailinstruction } = this.props;

    const options = {
      filterType: "checkbox",
      filter: false,
      viewColumns: false,
      elevation: 0,
      selectableRows: "none",
      responsive: "standard",
      download: false,
      print: false,
      fixedHeader: true,
      tableBodyHeight: "550px",
    };

    const columns = [
      {
        name: "Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[0]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[0]
                      ? tableMeta.rowData[0].slice(0, 7) + ".."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      "First Name",
      "Last Name",
      "Open",
      "Scroll Down",
      {
        name: "Move from spam to inbox and mark as safe",
        options: {
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              key={2}
              className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root MUIDataTableHeadCell-fixedHeader"
              style={{ cursor: "pointer" }}
            >
              <HtmlTooltip title={MOVE_FROM_INBOX_ASSAFE} placement="top-start">
                <span className="">
                  Remove from Spam <InfoOutlinedIcon className="toottipcls" />
                </span>
              </HtmlTooltip>
            </th>
          ),
        },
      },
      {
        name: "Move from Promo to Inbox",
        options: {
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              key={2}
              className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root MUIDataTableHeadCell-fixedHeader"
              style={{ cursor: "pointer" }}
            >
              <HtmlTooltip title={MOVE_FROM_PROMO} placement="top-start">
                <span className="">
                  Move from Promo <InfoOutlinedIcon className="toottipcls" />
                </span>
              </HtmlTooltip>
            </th>
          ),
        },
      },
      "Reply",
      "Mark as Important",
      "Click Link",
    ];

    if (loading) {
      return <LoaderUi />;
    }

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Engagement Instructions List </h3>
          <Link to="/senderprofiles" className="btn btn-primary float-right">
            Back to Sender Profiles
          </Link>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <form className="form-sample">
                  <div className="row">
                    {this.state.id2 && this.state.id2 === 2 && (
                      <MAlert severity="success" className="mt-1">
                        {UPDATE_PROFILE_SUCCESS}
                      </MAlert>
                    )}
                    {this.state.id2 && this.state.id2 === "1" && (
                      <MAlert severity="success" className="mt-1">
                        {CREATE_PROFILE_SUCCESS}
                      </MAlert>
                    )}
                  </div>
                  <div className="row">
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={
                          "Sender profile : " + emailinstruction.profile_name
                        }
                        data={emailinstruction.data}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  emailinstruction: state.senderprofiles.items3,
  loading: state.senderprofiles.loading3,
  error: state.senderprofiles.error3,
});

export default connect(mapStateToProps, null)(Engagementinstruction);
