import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { logout } from "../actions/auth";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    const { user, location } = this.props;

    return (
      <nav
        className="sidebar sidebar-offcanvas "
        id="sidebar"
        style={{
          backgroundImage:
            "url(" + require("../../assets/images/sidebar.jpg") + ")",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
          transition: "all 200ms ease 0s",
        }}
      >
        <ul className="nav">
          <li
            className={this.isPathActive("/") ? "nav-item active" : "nav-item"}
          >
            <Link
              className="nav-link text-left sidebar-brand-wrapper d-flex align-items-left"
              to="/"
            >
              <span className="sidebar-brand brand-logo">
                <img
                  src={require("../../assets/images/" +
                    process.env.REACT_APP_NAME_REACT_LOGO)}
                  alt="logo"
                />
              </span>
              <span className="sidebar-brand brand-logo-mini pt-3">
                <img
                  src={require("../../assets/images/" +
                    process.env.REACT_APP_NAME_MINI_LOGO)}
                  alt="logo"
                />
              </span>
            </Link>
          </li>
          <li className="nav-item sidebar-brand brand-logo ">
            <div className="nav-link">
              <Dropdown>
                <div className="d-flex justify-content-center align-items-start">
                  <div className="text-left ml-1 mt-3">
                    <p className="profile-name menu-title">
                      Welcome {user.first_name} {user.last_name}
                    </p>
                  </div>
                </div>
              </Dropdown>
            </div>
          </li>

          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/dashboard"
              onClick={() => window.userengage("event.Viewed_dashboard")}
            >
              <i className="mdi mdi-view-dashboard menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/myaccount") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/myaccount"
              onClick={() => window.userengage("event.Viewed_my_account")}
            >
              <i className="mdi mdi-account menu-icon"></i>

              <span className="menu-title">My Account</span>
            </Link>
          </li>

          {/* <li
            className={
              this.isPathActive("/billing") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/billing">
              <i 
className="fa fa-money menu-icon"></i>

              <span className="menu-title">Billing</span>
            </Link>
          </li> */}

          <li
            className={
              this.isPathActive("/seedemails") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/seedemails"
              onClick={() => window.userengage("event.Viewed_seed_emails")}
            >
              <i className="mdi mdi-email menu-icon"></i>

              <span className="menu-title">Seed Emails</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/senderemails")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/senderemails"
              onClick={() => window.userengage("event.Viewed_sender_emails")}
            >
              <i className="mdi mdi-arrange-send-backward menu-icon"></i>

              <span className="menu-title">Sender Emails</span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/senderprofiles")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/senderprofiles"
              onClick={() =>
                window.userengage("event.Viewed_sender_profiles_list")
              }
            >
              <i className="mdi mdi-send menu-icon"></i>

              <span className="menu-title">Sender Profiles</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/deliverabilitytester")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/deliverabilitytester">
              <i className="mdi mdi-inbox menu-icon"></i>
              <span className="menu-title">Deliverability Tester</span> &nbsp;
              <Chip
                label="Beta"
                color="secondary"
                style={{ backgroundColor: "red" }}
                size="small"
              />
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/broadcasts") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to={{
                pathname: "/broadcasts",
                state: { prevPath: location.pathname },
              }}
              onClick={() => window.userengage("event.Viewed_broadcasts")}
            >
              <i className="mdi mdi-cast-connected menu-icon"></i>

              <span className="menu-title">Broadcasts</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/reports") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/reports"
              onClick={() => window.userengage("event.Viewed_reports")}
            >
              <i className="mdi mdi-elevation-rise menu-icon"></i>
              <span className="menu-title">Reports</span>
            </Link>
          </li>

          {/* <li
            className={
              this.isPathActive("/emailinboxpm")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link className="nav-link" to="/emailinboxpm">
             <i 
className="mdi mdi-email menu-icon"></i>

              <span className="menu-title">
                Email Mix &nbsp; &nbsp; &nbsp; */}
          {/*<Chip label="Coming Soon" color="secondary"  style={{backgroundColor:'red'}} size="small" />{" "}*/}
          {/* </span>
            </Link>
          </li>  */}

          {/* <li
            className={
              this.isPathActive("/desktopmm") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/desktopmm">
              <i 
className="mdi  mdi-cellphone-link menu-icon"></i>

              <span className="menu-title">Device Mix &nbsp; &nbsp; </span>
              
            </Link>
          </li> */}

          {/*  <li
            className={
              this.isPathActive("/user-pages") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.userPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("userPagesMenuOpen")}
              data-toggle="collapse"
            >
              <i className="mdi mdi-lock-outline menu-icon"></i>
              <span className="menu-title">Coming Soon Pages</span>
            </div>
            <Collapse in={this.state.userPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/emailinboxpm">
                    <span className="menu-title">Email Inbox Provide Mix</span>
                  </Link>
                </li>

                <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/desktopmm">
                    <span className="menu-title">Desktop Mobile Mix</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
*/}

          {process.env.REACT_APP_NAME_KNOWLEDGE_BUTTON_ENABLE === "1" && (
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://docs.inboxally.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="mdi mdi-book-open-variant menu-icon"></i>
                <span className="menu-title">Knowledge Base</span>
              </a>
            </li>
          )}

          <li className="nav-item">
            <Link
              className="nav-link"
              to="/#"
              onClick={() => {
                this.props.logout();
                window.userengage("event.Loggedout");
              }}
            >
              <i className="fa fa-sign-out menu-icon"></i>
              <span className="menu-title">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

const mapStateToProps = function (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user,
  };
};

const SidebarWithRouter = withRouter(Sidebar);

export default connect(mapStateToProps, { logout })(SidebarWithRouter);
