import React, { PureComponent } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../utils/themeDash";
import {
  SENDER_PROFILE_TOOLTIP,
  SENDER_PRIMARYINBOXED,
} from "../utils/tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
export default class Latestbroadcast extends PureComponent {
  render() {
    var { broadcast } = this.props;
    var tablelength =
      broadcast.data && broadcast.data.length < 3 ? broadcast.data.length : 3;
    const options = {
      filterType: "checkbox",
      filter: false,
      viewColumns: false,
      elevation: 0,
      selectableRows: false,
      rowsPerPage: tablelength,
      download: false,
      pagination: false,
      print: false,
      search: false,
    };

    if (broadcast.data) {
      var broadcastupdated = [];
      for (var i = 0; i < tablelength; i++) {
        broadcastupdated[i] = [
          broadcast.data[i][0],
          broadcast.data[i][1],
          broadcast.data[i][2],
          broadcast.data[i][3],
          broadcast.data[i][4],
          broadcast.data[i][8],
        ];
      }
      //var broadcastupdatedcol= new Array('Sender profile',broadcast.columns[1],broadcast.columns[2],broadcast.columns[3],'Number Emails','Percent Inboxed');

      var broadcastupdatedcol = [
        {
          name: "Sender profile",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <HtmlTooltip
                    title={tableMeta.rowData[0]}
                    placement="top-start"
                  >
                    <span className="">
                      {tableMeta.rowData[0]
                        ? tableMeta.rowData[0].slice(0, 7) + "..."
                        : ""}
                    </span>
                  </HtmlTooltip>
                </div>
              );
            },
            customHeadRender: (columnMeta, updateDirection) => (
              <th
                key={2}
                className="MuiTableCell-head MuiTableCell-root"
                style={{ cursor: "pointer" }}
              >
                <HtmlTooltip
                  title={SENDER_PROFILE_TOOLTIP}
                  placement="top-start"
                >
                  <span className="">{columnMeta.name}</span>
                </HtmlTooltip>
              </th>
            ),
          },
        },

        {
          name: broadcast.columns[1],
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <HtmlTooltip
                    title={tableMeta.rowData[1]}
                    placement="top-start"
                  >
                    <span className="">
                      {tableMeta.rowData[1]
                        ? tableMeta.rowData[1].slice(0, 7) + "..."
                        : ""}
                    </span>
                  </HtmlTooltip>
                </div>
              );
            },
          },
        },

        {
          name: broadcast.columns[2],
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <HtmlTooltip
                    title={tableMeta.rowData[2]}
                    placement="top-start"
                  >
                    <span className="">
                      {tableMeta.rowData[2]
                        ? tableMeta.rowData[2].slice(0, 12) + ".."
                        : ""}
                    </span>
                  </HtmlTooltip>
                </div>
              );
            },
          },
        },
        broadcast.columns[3],
        "Number Emails",

        {
          name: "Percent Inboxed",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <HtmlTooltip
                    title={tableMeta.rowData[5]}
                    placement="top-start"
                  >
                    <span className="">
                      {tableMeta.rowData[5] ? tableMeta.rowData[5] : ""}
                    </span>
                  </HtmlTooltip>
                </div>
              );
            },
            customHeadRender: (columnMeta, updateDirection) => (
              <th
                key={2}
                className="MuiTableCell-head MuiTableCell-root"
                style={{ cursor: "pointer" }}
              >
                <HtmlTooltip
                  title={SENDER_PRIMARYINBOXED}
                  placement="top-start"
                >
                  <span className="">
                    Percent Inboxed <InfoOutlinedIcon className="toottipcls" />
                  </span>
                </HtmlTooltip>
              </th>
            ),
          },
        },
      ];
    }

    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Latest broadcasts received"}
          data={broadcastupdated}
          columns={broadcastupdatedcol}
          options={options}
        />
      </MuiThemeProvider>
    );
  }
}
