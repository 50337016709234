import React, { Component } from "react";
import Report from "./Report";

import { fetchSubject } from "../actions/subject";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import { fetchSenderprofiles } from "../actions/senderprofiles";

class ReportsContainter extends Component {
  state = {
    jwt: localStorage.token,
    sender_profile_name: this.props.match.params.id,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.dispatch(fetchSubject());
    this.props.dispatch(fetchSenderprofiles(this.state));
  }

  render() {
    var { loading2, subjects, senderprofiles, loading3 } = this.props;

    if (loading2 || loading3) {
      return <LoaderUi />;
    }

    return (
      <Report
        subjects={subjects}
        senderprofiles={senderprofiles}
        sender_profile_name={this.state.sender_profile_name}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  subjects: state.subjects.items.data,
  loading2: state.subjects.loading2,
  senderprofiles: state.senderprofiles.items.data,
  loading3: state.senderprofiles.loading,
});

export default connect(mapStateToProps, null)(ReportsContainter);
