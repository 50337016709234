import { createMuiTheme } from "@material-ui/core/styles";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        root: {
          color: "#ffffff !important",
        },
        paper: {
          boxShadow: "none",
        },
      },
      MuiTableCell: {
        head: {
          backgroundColor: "#f4f7fa !important",
          color: "#000 !important",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#d5f4fe",
            textColor: "#ffffff !important",
          },
          "&:nth-child(even)": {
            backgroundColor: "#FFF4E5",
          },
          "&:nth-child(odd):hover": {
            backgroundColor: "#d5f4fe !important",
          },
          "&:nth-child(even):hover": {
            backgroundColor: "#FEF4E5 !important",
          },

          "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#ffffff00 !important",
          },
        },
      },
      MUIDataTableBodyCell: {},
    },
  });
export default getMuiTheme;
