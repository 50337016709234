import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import { updateprofile, paymentsuccess } from "../actions/auth";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "../layout/Alert";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { EDIT_BUSINESS_EMAIL, EDIT_BUSINESS_NAME } from "../utils/tooltip";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const TimeZonesdata = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay",
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Calcutta",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export class MyAccount extends Component {
  state = {
    first_name: "",
    last_name: "",
    password: "",
    business_name: "",
    websiteurl: "",
    phone: "",
    timezone: "",
    edit: false,
    validationErrors: {},
    id: this.props.match.params.id,
  };

  constructor(props) {
    super(props);

    this.state = {
      first_name: this.firstInput ? this.firstInput.value : null,
      last_name: this.lastInput ? this.lastInput.value : null,
      password: this.passwordInput ? this.passwordInput.value : null,
      business_name: this.businessInput ? this.businessInput.value : null,
      websiteurl: this.websiteurlInput ? this.websiteurlInput.value : null,
      phone: this.phoneInput ? this.phoneInput.value : null,
      timezone: this.timezoneInput ? this.timezoneInput : null,
      validationErrors: {},
    };
  }

  validators = {
    first_name: (str) => (str === "" ? "Please enter your first name" : ""),
    last_name: (str) => (str === "" ? "Please enter your last name" : ""),
    password: (str) =>
      str === "" ? "Please enteryour password, if you wish to change" : "",
    websiteurl: (str) => (str === "" ? "Please enter your website url" : ""),
    phone: (str) => (str === "" ? "Please enter your phone number" : ""),
    timezone: (str) => (str === "" ? "Please choose your time zone" : ""),
  };

  validate = (name) => {
    const value = this.state[name];
    let error = this.validators.hasOwnProperty(name)
      ? this.validators[name](value)
      : "";
    this.setState(({ validationErrors }) => ({
      validationErrors: { ...validationErrors, [name]: error },
    }));
    return error;
  };

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value }, () =>
      this.validate(fieldName)
    );
  };

  onEdit = (e) => {
    e.preventDefault();
    this.setState({ edit: !this.state.edit });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = Object.keys(this.validators)
      .map(this.validate)
      .every((err) => !err);
    if (isValid) {
      window.scrollTo(0, 0);
      const first_name = this.state.first_name
        ? this.state.first_name
        : this.props.user.first_name;
      const last_name = this.state.last_name
        ? this.state.last_name
        : this.props.user.last_name;
      const business_name = this.state.business_name
        ? this.state.business_name
        : this.props.user.business_name;
      const websiteurl = this.state.websiteurl
        ? this.state.websiteurl
        : this.props.user.websiteurl;
      const phone = this.state.phone ? this.state.phone : this.props.user.phone;
      const timezone = this.state.timezone
        ? this.state.timezone
        : this.props.user.timezone;
      const password = this.state.password
        ? this.state.password
        : this.props.user.password;
      const updatedprofile = {
        first_name: first_name,
        last_name: last_name,
        password: password,
        business_name: business_name,
        websiteurl: websiteurl,
        phone: phone,
        timezone: timezone,
        jwt: localStorage.token,
      };
      this.props.onUpdatePro(updatedprofile);
      this.setState({ edit: !this.state.edit });
    }
  };

  componentDidMount() {
    this.props.dispatch(
      paymentsuccess({
        id: this.props.match.params.id,
        jwt: localStorage.token,
      })
    );
  }

  render() {
    const { loading, user } = this.props;

    if (loading) {
      return <LoaderUi />;
    }

    return (
      <div>
        <div className="page-header">
          <h1 className="page-title"> My Account </h1>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card mycard">
              <div className="card-body">
                <div className="row ">
                  <div className="col-8 mb-1">
                    <Alert msgdivid="udpatediv" />
                  </div>

                  <div className="col-md-10">
                    <form className="pt-3" onSubmit={this.handleSubmit}>
                      <div className="form-row  mb-3  ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Name</b>
                          </span>
                        </div>

                        <div className="col-md-6">
                          {!this.state.edit && (
                            <span className="float-left ">
                              {user.first_name} {user.last_name}
                            </span>
                          )}
                          {this.state.edit && (
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  placeholder="Enter First Name"
                                  onChange={this.handleInputChange}
                                  defaultValue={user.first_name}
                                  ref={(element) => (this.firstInput = element)}
                                />
                                <div style={{ fontSize: 14, color: "red" }}>
                                  {this.state.validationErrors.first_name}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                  placeholder="Enter Last Name"
                                  onChange={this.handleInputChange}
                                  defaultValue={user.last_name}
                                  ref={(element) => (this.lastInput = element)}
                                />
                                <div style={{ fontSize: 14, color: "red" }}>
                                  {this.state.validationErrors.last_name}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-row  mb-3">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Business email</b>
                          </span>
                        </div>

                        <div className="col-md-4">
                          <span className="float-left">
                            {user.email}
                            {this.state.edit && (
                              <HtmlTooltip
                                title={EDIT_BUSINESS_EMAIL}
                                placement="top-start"
                              >
                                <span className="">
                                  <InfoOutlinedIcon className="toottipcls ml-1" />
                                </span>
                              </HtmlTooltip>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="form-row  mb-3">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Password</b>
                          </span>
                        </div>

                        <div className="col-md-6">
                          {!this.state.edit && (
                            <span className="float-left">*******</span>
                          )}
                          {this.state.edit && (
                            <div className="row">
                              <div className="col-md-12">
                                <input
                                  type="password"
                                  className="form-control"
                                  name="password"
                                  placeholder="Enter Password"
                                  onChange={this.handleInputChange}
                                  defaultValue={user.password}
                                  ref={(element) =>
                                    (this.passwordInput = element)
                                  }
                                />
                                <div style={{ fontSize: 14, color: "red" }}>
                                  {this.state.validationErrors.password}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-row  mb-3">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Business name</b>
                          </span>
                        </div>

                        <div className="col-md-6">
                          <span className="float-left">
                            {user.business_name}

                            {this.state.edit && (
                              <HtmlTooltip
                                title={EDIT_BUSINESS_NAME}
                                placement="top-start"
                              >
                                <span className="">
                                  <InfoOutlinedIcon className="toottipcls ml-1" />
                                </span>
                              </HtmlTooltip>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="form-row  mb-3">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Website URL</b>
                          </span>
                        </div>
                        <div className="col-md-6">
                          {!this.state.edit && (
                            <span className="float-left">
                              {user.websiteurl}
                            </span>
                          )}

                          {this.state.edit && (
                            <div className="row">
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="websiteurl"
                                  placeholder="Enter WebsitURL"
                                  onChange={this.handleInputChange}
                                  defaultValue={user.websiteurl}
                                  ref={(element) =>
                                    (this.websiteurlInput = element)
                                  }
                                />
                                <div style={{ fontSize: 14, color: "red" }}>
                                  {this.state.validationErrors.websiteurl}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-row  mb-3">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Phone number</b>
                          </span>
                        </div>
                        <div className="col-md-6">
                          {!this.state.edit && (
                            <span className="float-left">{user.phone}</span>
                          )}
                          {this.state.edit && (
                            <div className="row">
                              <div className="col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="phone"
                                  placeholder="Enter Phone"
                                  onChange={this.handleInputChange}
                                  defaultValue={user.phone}
                                  ref={(element) => (this.phoneInput = element)}
                                />
                                <div style={{ fontSize: 14, color: "red" }}>
                                  {this.state.validationErrors.phone}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-row  mb-3">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Time zone</b>
                          </span>
                        </div>
                        <div className="col-md-6">
                          {!this.state.edit && (
                            <span className="float-left">{user.timezone}</span>
                          )}
                          {this.state.edit && (
                            <div className="row">
                              <div className="col-md-12">
                                <select
                                  className="form-control form-control-lg"
                                  style={{ color: "#495057" }}
                                  name="timezone"
                                  onChange={this.handleInputChange}
                                  defaultValue={user.timezone}
                                  ref={(element) =>
                                    (this.timezoneInput = element)
                                  }
                                >
                                  <option value="">Select Timezone</option>
                                  {TimeZonesdata.map((TimeZone, index) => (
                                    <option value={TimeZone} key={index}>
                                      {TimeZone}
                                    </option>
                                  ))}
                                </select>
                                <div style={{ fontSize: 14, color: "red" }}>
                                  {this.state.validationErrors.timezone}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-row  mb-3">
                        <div className="col-md-5"></div>

                        {!this.state.edit && (
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <button
                                    className="btn btn-primary"
                                    onClick={this.onEdit}
                                  >
                                    Edit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {this.state.edit && (
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <button
                                    className="btn btn-primary"
                                    onClick={this.onEdit}
                                    type="button"
                                  >
                                    Back
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdatePro: (distdata) => {
      dispatch(updateprofile(distdata));
    },
    dispatch,
  };
};

const mapStateToProps = function (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
