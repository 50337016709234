import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MAlert from "@material-ui/lab/Alert";
const Alert = ({ alerts, msgdivid, props }) => {
  if (alerts !== null && alerts.length > 0) {
    return alerts.map((alert) => {
      if (alert.divid === msgdivid)
        return (
          <MAlert key={alert.id} severity={alert.alertType}>
            {alert.msg}
          </MAlert>
        );
      else {
        return null;
      }
    });
  }

  return <div></div>;
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert.slice(-1),
});

export default connect(mapStateToProps)(Alert);
