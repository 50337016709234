import React, { PureComponent } from "react";
import { Badge } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";

export default class Latestactions extends PureComponent {
  render() {
    var { dashler } = this.props;

    return (
      <div>
        {" "}
        <h4>
          Latest engagement actions taken{" "}
          <span style={{ fontSize: "10px" }} className="mb-1">
            {" "}
            Live Feed
          </span>{" "}
        </h4>
        <div className="activity-widget ">
          <Scrollbars
            className="rct-scroll"
            autoHeight
            autoHeightMin={100}
            autoHeightMax={440}
            autoHide
          >
            <ul className="list-unstyled px-3 mt-3 ml-5">
              {dashler && dashler.data && dashler.data[0] && (
                <li key="0">
                  <Badge color="dark" className="rounded-circle p-0">
                    .
                  </Badge>
                  <span className="activity-time font-xs text-muted">
                    {dashler ? dashler.data[0][3] : ""}{" "}
                  </span>
                  <p className="mb-0">{dashler ? dashler.data[0][1] : ""}</p>
                </li>
              )}

              {dashler && dashler.data && dashler.data[1] && (
                <li key="1">
                  <Badge color="dark" className="rounded-circle p-0">
                    .
                  </Badge>
                  <span className="activity-time font-xs text-muted">
                    {dashler ? dashler.data[1][3] : ""}{" "}
                  </span>
                  <p className="mb-0">{dashler ? dashler.data[1][1] : ""}</p>
                </li>
              )}

              {dashler && dashler.data && dashler.data[2] && (
                <li key="2">
                  <Badge color="dark" className="rounded-circle p-0">
                    .
                  </Badge>
                  <span className="activity-time font-xs text-muted">
                    {dashler ? dashler.data[2][3] : ""}{" "}
                  </span>
                  <p className="mb-0">{dashler ? dashler.data[2][1] : ""}</p>
                </li>
              )}

              {dashler && dashler.data && dashler.data[3] && (
                <li key="3">
                  <Badge color="dark" className="rounded-circle p-0">
                    .
                  </Badge>
                  <span className="activity-time font-xs text-muted">
                    {dashler ? dashler.data[3][3] : ""}{" "}
                  </span>
                  <p className="mb-0">{dashler ? dashler.data[3][1] : ""}</p>
                </li>
              )}

              {dashler && dashler.data && dashler.data[4] && (
                <li key="4">
                  <Badge color="dark" className="rounded-circle p-0">
                    .
                  </Badge>
                  <span className="activity-time font-xs text-muted">
                    {dashler ? dashler.data[4][3] : ""}{" "}
                  </span>
                  <p className="mb-0">{dashler ? dashler.data[4][1] : ""}</p>
                </li>
              )}

              {dashler && dashler.data && dashler.data[5] && (
                <li key="5">
                  <Badge color="dark" className="rounded-circle p-0">
                    .
                  </Badge>
                  <span className="activity-time font-xs text-muted">
                    {dashler ? dashler.data[5][3] : ""}{" "}
                  </span>
                  <p className="mb-0">{dashler ? dashler.data[5][1] : ""}</p>
                </li>
              )}
            </ul>
          </Scrollbars>
        </div>
      </div>
    );
  }
}
