import {
  FETCH_SUBJECT_BEGIN,
  FETCH_SUBJECT_SUCCESS,
  FETCH_SUBJECT_FAILURE,
  BASE_URL_NODE_LIVE,
} from "./types";
import axios from "axios";

//const apiUrl = BASE_URL_NODE;
const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const fetchSubjectBegin = () => ({
  type: FETCH_SUBJECT_BEGIN,
});

export const fetchSubjectSuccess = (sub) => {
  return {
    type: FETCH_SUBJECT_SUCCESS,
    payload: { sub },
  };
};

export const fetchSubjectFailure = (error) => ({
  type: FETCH_SUBJECT_FAILURE,
  payload: { error },
});

export const fetchSubject = () => {
  return (dispatch) => {
    dispatch(fetchSubjectBegin());
    return axios
      .post(
        apiUrlLive + "broadcast_subject.php",
        qs.stringify({ jwt: localStorage.token })
      )
      .then((response) => {
        dispatch(fetchSubjectSuccess(response.data));
      })
      .catch((error) => dispatch(fetchSubjectFailure(error)));
  };
};
