import {
  FETCH_SENDERE_BEGIN,
  FETCH_SENDERE_SUCCESS,
  FETCH_SENDERE_FAILURE,
  SAVE_SENDERE_BEGIN,
  SAVE_SENDERE_SUCCESS,
  SAVE_SENDERE_FAILURE,
  BASE_URL_NODE_LIVE,
  VERIFY_SENDERE_BEGIN,
  VERIFY_SENDERE_SUCCESS,
  VERIFY_SENDERE_FAILURE,
  DELETE_SENDERE_BEGIN,
  DELETE_SENDERE_SUCCESS,
  DELETE_SENDERE_FAILURE,
} from "./types";
import axios from "axios";
import { setAlert } from "./alert";

const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const fetchSEBegin = () => ({
  type: FETCH_SENDERE_BEGIN,
});

export const fetchSESuccess = (senderemails) => {
  return {
    type: FETCH_SENDERE_SUCCESS,
    payload: { senderemails },
  };
};

export const fetchSEFailure = (error) => ({
  type: FETCH_SENDERE_FAILURE,
  payload: { error },
});

export const fetchSenderemails = (jwtdata) => {
  return (dispatch) => {
    dispatch(fetchSEBegin());
    return axios
      .post(apiUrlLive + "fetchemails.php", qs.stringify(jwtdata))
      .then((response) => {
        if (jwtdata && jwtdata.id !== "" && jwtdata.id !== undefined) {
          window.history.pushState(null, "", "/senderemail");
          dispatch(
            setAlert(
              "You have successfully verified your email.",
              "success",
              "saveSE"
            )
          );
        }

        dispatch(fetchSESuccess(response.data));
      })
      .catch((error) => dispatch(fetchSEFailure(error)));
  };
};

export const saveSEBegin = () => ({
  type: SAVE_SENDERE_BEGIN,
});

export const saveSESuccess = (sender) => {
  return {
    type: SAVE_SENDERE_SUCCESS,
    payload: { sender },
  };
};

export const saveSEFailure = (error) => ({
  type: SAVE_SENDERE_FAILURE,
  payload: { error },
});

export const saveSE = (senderemails) => {
  return (dispatch) => {
    dispatch(saveSEBegin());
    return axios
      .post(`${apiUrlLive}add_email.php`, qs.stringify(senderemails))
      .then((response) => {
        dispatch(saveSESuccess(response.data));
        dispatch(fetchSenderemails({ jwt: localStorage.token }));
        dispatch(setAlert(response.data.message, response.data.type, "saveSE"));
      })
      .catch((error) => dispatch(saveSEFailure(error)));
  };
};

export const verifySEBegin = () => ({
  type: VERIFY_SENDERE_BEGIN,
});

export const verifySESuccess = (vsender) => {
  return {
    type: VERIFY_SENDERE_SUCCESS,
    payload: { vsender },
  };
};

export const verifySEFailure = (error) => ({
  type: VERIFY_SENDERE_FAILURE,
  payload: { error },
});

export const verifySE = (senderemails) => {
  return (dispatch) => {
    dispatch(verifySEBegin());
    return axios
      .post(`${apiUrlLive}resend_verify.php`, qs.stringify(senderemails))
      .then((response) => {
        dispatch(verifySESuccess(response.data));
        dispatch(fetchSenderemails({ jwt: localStorage.token }));
        dispatch(setAlert(response.data.message, response.data.type, "saveSE"));
      })
      .catch((error) => dispatch(verifySEFailure(error)));
  };
};

export const deleteSEBegin = () => ({
  type: DELETE_SENDERE_BEGIN,
});

export const deleteSESuccess = (dsender) => {
  return {
    type: DELETE_SENDERE_SUCCESS,
    payload: { dsender },
  };
};

export const ddeleteSEFailure = (error) => ({
  type: DELETE_SENDERE_FAILURE,
  payload: { error },
});

export const deleteSE = (senderemails) => {
  return (dispatch) => {
    dispatch(deleteSEBegin());
    return axios
      .post(`${apiUrlLive}deletsender_profile.php`, qs.stringify(senderemails))
      .then((response) => {
        dispatch(deleteSESuccess(response.data));
        dispatch(fetchSenderemails({ jwt: localStorage.token }));
        dispatch(setAlert(response.data.message, response.data.type, "saveSE"));
      })
      .catch((error) => dispatch(ddeleteSEFailure(error)));
  };
};

export const changesenderemailstatus = (jwtdata) => {
  return (dispatch) => {
    dispatch(fetchSEBegin());
    return axios
      .post(apiUrlLive + "change_email_status.php", qs.stringify(jwtdata))
      .then((response) => {
        dispatch(fetchSenderemails({ jwt: localStorage.token }));
      })
      .catch((error) => dispatch(fetchSEFailure(error)));
  };
};
