import {
  FETCH_REPORTS_BEGIN,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  FETCH_CHART_REPORTS_BEGIN,
  FETCH_CHART_REPORTS_SUCCESS,
  FETCH_CHART_REPORTS_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
  items2: [],
  error2: null,
};

export default function myReportsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.reports,
      };

    case FETCH_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case FETCH_CHART_REPORTS_BEGIN:
      return {
        ...state,
        loading2: true,
        error2: null,
      };

    case FETCH_CHART_REPORTS_SUCCESS:
      return {
        ...state,
        loading2: false,
        items2: action.payload.reportschart,
      };

    case FETCH_CHART_REPORTS_FAILURE:
      return {
        ...state,
        loading2: false,
        items2: action.payload.error,
      };

    default:
      return state;
  }
}
