import React, { Component } from "react";

import DatePicker from "react-datepicker";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "../utils/theme";
import Pagination from "../utils/Pagination";
import { Doughnut,HorizontalBar} from "react-chartjs-2";
import {
  fetchReports,
  filterReports,
  fetchChartReports,
  filterChartReports,
} from "../actions/reports";
import Multilinechart from "../charts/MultilinereportchartContainter";
import LoaderUi from "../shared/Loader";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { fetchDummyData } from "../actions/dashboard";
import { SENDER_PROFILE_TOOLTIP } from "../utils/tooltip";
import MAlert from "@material-ui/lab/Alert";
import initHelpHero from "helphero";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AssessmentIcon from '@material-ui/icons/Assessment';
import TocIcon from '@material-ui/icons/Toc';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 300,
    float: "right",
    backgroundColor: theme.palette.background.paper,
  },
});






const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: "",
      endDate: "",
      jwt: localStorage.token,
      sender_profile_name: this.props.sender_profile_name,
      from_email: "",
      subject: "",
      totalRecords: "",
      totalPages: "",
      pageLimit: 10,
      currentPage: 1,
      value: 0,
      email_type:'',
    };
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleChange1 = (date2) => {
    this.setState({
      endDate: date2,
    });
  };

  handleChange2 = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;

    if (fieldName === "pageLimit") {
      this.setState({ currentPage: 1 });
    }

      if (fieldName === "sender_profile_name") {
      
let selectedemail = this.props.senderprofiles.filter(function (j) {
    return j.[0] === e.currentTarget.value;
});


if(selectedemail && selectedemail[0] && selectedemail[0][1]) {
this.setState({ from_email:selectedemail[0][1]});
}
if(e.currentTarget.value===''){
this.setState({ from_email:""}); 
}


}
    if (fieldName === "from_email") {
      
let selectedprofilename = this.props.senderprofiles.filter(function (j) {
    return j.[1] === e.currentTarget.value;
});

if(selectedprofilename && selectedprofilename[0] && selectedprofilename[0][0]) {
this.setState({ sender_profile_name:selectedprofilename[0][0]});

}

if(e.currentTarget.value===''){
this.setState({ sender_profile_name:""}); 
}

}


    this.setState({ [fieldName]: e.currentTarget.value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchReports(this.state));
    this.props.dispatch(fetchDummyData(this.state));
    this.props.dispatch(fetchChartReports(this.state));
    var hlp = initHelpHero("artQ7EmmPkC");
    hlp.update({ hasViewedReports: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentPage !== prevState.currentPage ||
      this.state.pageLimit !== prevState.pageLimit
    ) {
      this.props.dispatch(fetchReports(this.state));
    }
  }

  onFilterSearch = (e) => {
    e.preventDefault();
    var date1 = new Date(this.state.startDate);
    var date2 = new Date(this.state.endDate);
    if (date1 && date2) {
      var start_date =
        (date1.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date1.getDate().toString().padStart(2, "0") +
        "/" +
        date1.getFullYear();
      var end_date =
        (date2.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date2.getDate().toString().padStart(2, "0") +
        "/" +
        date2.getFullYear();
    }

    start_date = this.state.startDate ? start_date : "";
    end_date = this.state.endDate ? end_date : "";
    const createdata = {
      jwt: localStorage.token,
      start_date: start_date,
      end_date: end_date,
      from_email: this.state.from_email,
      subject: this.state.subject,
      sender_profile_name: this.state.sender_profile_name,
      totalRecords: this.state.totalRecords,
      totalPages: "",
      pageLimit: this.state.pageLimit,
      email_type: this.state.email_type,
      currentPage: 1,
    };

    this.props.onFilter(createdata);

    var hlp = initHelpHero("artQ7EmmPkC");
    hlp.update({ hasRanReport: true });
    hlp.update({ hasViewedReports: true });
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
  };

  render() {
    var {
      reports,
      subjects,
      senderprofiles,
      loading,
      dashdummy,
      loading8,
      classes,
      chartloading,
      chartreports,
    } = this.props;

    if (loading || loading8 || chartloading) {
      return <LoaderUi />;
    }

    if (!dashdummy.message) {
      reports.data = [];
    }

    var totalcount = reports.count;


  

       var doughnutPieData = {
        datasets: [
        {
        data:[chartreports.total_primary_inbox,chartreports.total_promotions,chartreports.total_spam],
        backgroundColor: [
        "rgb(93, 146, 244)",
        "rgba(255,183,15,1)",
        "rgba(255,99,88,1)",
        ],
        },
        ],
        labels: ["Primary Inbox",'Promotions','Spam'],
        
        };



  const horizontalbar = {
  labels: ['Inbox','Primary Inbox', 'Promotions','Spam','Total Emails'],
  
  datasets: [
    {
      label: '',
      backgroundColor:[
        "rgb(0, 208, 189)",
        "rgb(93, 146, 244)",
        "rgba(255,183,15,1)",
        "rgba(255,99,88,1)",
        "rgb(128, 128, 128)",
      ],
    data:[chartreports.total_inbox,chartreports.total_primary_inbox,chartreports.total_promotions,chartreports.total_spam,chartreports.total_email]
    }
  ]
};





    const columns = [
      {
        name: "Sender profile",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[0]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[0]
                      ? tableMeta.rowData[0].slice(0, 7) + ".."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root MUIDataTableHeadCell-fixedHeader"
              style={{ cursor: "pointer" }}
            >
              <HtmlTooltip title={SENDER_PROFILE_TOOLTIP} placement="top-start">
                <span className="">{columnMeta.name}</span>
              </HtmlTooltip>
            </th>
          ),
        },
      },
      {
        name: "From Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[1]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[1]
                      ? tableMeta.rowData[1].slice(0, 7) + ".."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      {
        name: "Broadcast Email",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[2]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[2]
                      ? tableMeta.rowData[2].slice(0, 7) + ".."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      {
        name: "Subject",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[3]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[3]
                      ? tableMeta.rowData[3].slice(0, 7) + ".."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      "Folder Name",
      "Arrival Time",
      "Opened",
      "Scroll Down",
      "Spam To Inbox",
      {
        name: "Spam Reason",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip title={tableMeta.rowData[9]} placement="top-start">
                  <span className="">
                    {tableMeta.rowData[9]
                      ? tableMeta.rowData[9].slice(0, 7) + ".."
                      : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      "Promo To Inbox",
      {
        name: "Replied",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <HtmlTooltip
                  title={tableMeta.rowData[11] ? tableMeta.rowData[11] : ""}
                  placement="top-start"
                >
                  <span className="">
                    {tableMeta.rowData[11] ? tableMeta.rowData[11] : ""}
                  </span>
                </HtmlTooltip>
              </div>
            );
          },
        },
      },
      "Link Opened",
      "Link Text",
    ];

    const options = {
      filterType: "checkbox",
      filter: false,
      viewColumns: false,
      elevation: 0,
      selectableRows: "none",
      download: false,
      print: false,
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: "450px",
      pagination: false,
    };

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Reports</h3>
        </div>

  <div className="row">
          <div className="col-12 grid-margin">
            <div className="card mycard">
              <div className="card-body">



                 {!dashdummy.multiple_email_type && <div className="row ">
                  <div className="col-md-2">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1">Sending Profile</label>
                      <select
                        
className="form-control"
                        name="sender_profile_name"
                        onChange={this.handleInputChange}
                        value={this.state.sender_profile_name}
                      >
                        <option value="">All</option>
                        {senderprofiles &&
                          senderprofiles.map((senderprofile, index) => (
                            <option value={senderprofile[0]}>
                              {senderprofile[0]}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1">From Email</label>
                      <select
                        
className="form-control"
                        name="from_email"
                        onChange={this.handleInputChange}
                        value={this.state.from_email}
                      >
                        <option value="" >All</option>
                        {senderprofiles &&
                          senderprofiles.map((senderprofile, index) => (
                            <option value={senderprofile[1]}>
                              {senderprofile[1]}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div 
className="form-row row">
                      <div 
className="col form-group">
                        <label for="exampleInputPassword1">Start Date</label>
                        <div className="clearfix"></div>
                        <DatePicker
                          name="startDate"
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                        />
                      </div>
                      <div 
className="col form-group">
                        <div className="clearfix"></div>
                        <label for="exampleInputPassword1">End Date</label>
                        <div className="clearfix"></div>
                        <DatePicker
                          name="endDate"
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.endDate}
                          onChange={this.handleChange1}
                          minDate={this.state.startDate}
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1">Subject</label>
                      <select
                        className="form-control"
                        name="subject"
                        onChange={this.handleInputChange}
                        value={this.state.subject}
                      >
                        <option value="">All</option>
                        {subjects &&
                          subjects.map((subject, index) => (
                            <option value={subject}>{subject}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-1">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1"></label>
                      <div className="clearfix"></div>
                      <div className="clearfix"></div>
                      <button
                        type="button"
                        
className="btn btn-primary"
                        onClick={this.onFilterSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>}




 {dashdummy.multiple_email_type && <div className="row ">
                  <div className="col-md-4">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1">Sending Profile</label>
                      <select
                        
className="form-control"
                        name="sender_profile_name"
                        onChange={this.handleInputChange}
                        value={this.state.sender_profile_name}
                      >
                        <option value="">All</option>
                        {senderprofiles &&
                          senderprofiles.map((senderprofile, index) => (
                            <option value={senderprofile[0]}>
                              {senderprofile[0]}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1">From Email</label>
                      <select
                        
className="form-control"
                        name="from_email"
                        onChange={this.handleInputChange}
                        value={this.state.from_email}
                      >
                        <option value="" >All</option>
                        {senderprofiles &&
                          senderprofiles.map((senderprofile, index) => (
                            <option value={senderprofile[1]}>
                              {senderprofile[1]}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                    <div className="col-md-4">


                      <div 
className="form-group">
                      <label for="exampleInputPassword1">Subject</label>
                      <select
                        className="form-control"
                        name="subject"
                        onChange={this.handleInputChange}
                        value={this.state.subject}
                      >
                        <option value="">All</option>
                        {subjects &&
                          subjects.map((subject, index) => (
                            <option value={subject}>{subject}</option>
                          ))}
                      </select>
                    </div>
                  
                  </div>

                  <div className="col-md-8">
                    <div 
className="form-row row">
                      <div 
className="col form-group">
                        <label for="exampleInputPassword1">Start Date</label>
                        <div className="clearfix"></div>
                        <DatePicker
                          name="startDate"
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                        />
                      </div>
                      <div 
className="col form-group">
                        <div className="clearfix"></div>
                        <label for="exampleInputPassword1">End Date</label>
                        <div className="clearfix"></div>
                        <DatePicker
                          name="endDate"
                          dateFormat="MM/dd/yyyy"
                          selected={this.state.endDate}
                          onChange={this.handleChange1}
                          minDate={this.state.startDate}
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1">Email Provider</label>
                      <select  
className="form-control" name="email_type" onChange={this.handleInputChange} value={this.state.email_type}>
     <option value="">All</option>
     <option value="Gmail">Gmail</option>
     <option value="Yahoo">Yahoo</option>
     </select>
                    </div>  
                  </div>

                  <div className="col-md-1">
                    <div 
className="form-group">
                      <label for="exampleInputPassword1"></label>
                      <div className="clearfix"></div>
                      <div className="clearfix"></div>
                      <button
                        type="button"
                        
className="btn btn-primary"
                        onClick={this.onFilterSearch}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>}






                <MAlert severity="info" className="mt-1">
                  If you are seeing much lower open rates in your email sending
                  program than below, it's likely that a lot of your emails are
                  going to the spam. when we open emails in spam your program
                  will not detect them as opened even though GMail will. To
                  learn more, contact us.
                </MAlert>

                {/*<div className="row mt-3" >
<div className="col-md-6 col-sm-12">

<Reportchart1  name={'Average Inboxing'} data={averagesprofile} />
</div>
<div className="col-md-6 col-sm-12">
<Reportchart4 name={'Top links clicked'}/>
</div>
</div>


<div className="row mt-3" >
<div className="col-md-6 col-sm-12">
<Reportchart1  name={'Top Spam Reasons'}/>
</div>
<div className="col-md-6 col-sm-12">
<Reportchart1 name={'All Sender Profiles'}/>
</div>
</div>

<div className="row mt-3" >
<div className="col-md-6 col-sm-12">
<Reportchart2 name={'Total Sender Profile sends'}/>
</div>
<div className="col-md-6 col-sm-12">
<Reportchart3 name={'Grouped Bar Chart'}/>
</div>
</div>*/}

                <div className="row mt-5">
                  <div className="col-md-12 col-sm-12">
                    
                      <AppBar
                        position="static"
                        color="default"
                        className={classes.root}
                      >
                        <Tabs
                          value={this.state.value}
                          onChange={this.handleChange2}
                          variant="fullWidth"
                          indicatorColor="primary"
                          textColor="primary"
                          aria-label="icon tabs example"
                          
className="mainTabClass"
                        >
                          <Tab icon={<AssessmentIcon />} />
                          <Tab icon={<TocIcon />} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={this.state.value} index={0}>

{chartreports.total_email >0 && <div className="row marginTop60" >
<div className="col-md-6  ">
<Doughnut data={doughnutPieData}    height={250}/>


   
   </div>
  <div className="col-md-6  ">
  <HorizontalBar data={horizontalbar}  height={250} options={{legend: {display: false}}}/>
  </div>

<div className="col-md-12">
<Multilinechart   allsenderprofile={chartreports.by_date} senderprofile={chartreports.by_profile}
senderprofiles={senderprofiles} selectedprofile={this.state.sender_profile_name?this.state.sender_profile_name:'All'} />
</div>
</div>}

{chartreports.total_email === '0' && <div className="row marginTop60">
<div className="col-md-12 text-center">
<h4>Sorry, no matching records found.</h4>
</div>
</div>}





                      </TabPanel>
                      <TabPanel value={this.state.value} index={1}>
                        <div className="row marginTop60">
                          <MuiThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                              title={""}
                              data={reports.data}
                              columns={columns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-9">
                            <div className="section product_list_mng">
                              <div className="container-fluid">
                                <div className="box_pagination">
                                  <div className="row justify-content-center">
                                    <div className="col-xs-12 text-center">
                                      <Pagination
                                        totalRecords={totalcount}
                                        pageLimit={this.state.pageLimit}
                                        initialPage={this.state.currentPage}
                                        pagesToShow={10}
                                        onChangePage={this.onChangePage}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            Rows per page:
                            <select
                              
className="form-control paginationselect"
                              name="pageLimit"
                              defaultValue={this.state.pageLimit}
                              onChange={this.handleInputChange}
                            >
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                        </div>
                      </TabPanel>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports.items,
  loading: state.reports.loading,
  chartreports: state.reports.items2,
  chartloading: state.reports.loading,
  dashdummy: state.dash.items8,
  loading8: state.dash.loading8,
  error8: state.dash.error8,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onFilter: (keyword) => {
      dispatch(filterReports(keyword));
      dispatch(filterChartReports(keyword));
    },
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Report));
