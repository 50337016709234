import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LoaderUi from "../shared/Loader";
import { fetchMyplandetail } from "../actions/plan";

export class Billing extends Component {
  state = {
    dwdno: 1,
    jwt: localStorage.token,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchMyplandetail(this.state));
  }

  render() {
    var { loading, plan } = this.props;

    if (loading) {
      return <LoaderUi />;
    }

    var plandata = plan ? plan[0] : "";

    return (
      <div>
        <div className="page-header">
          <h1 className="page-title"> Billing </h1>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row mt-1">
                      <div className="col-md-5">
                        <p>
                          <b>Your Current Plan</b>
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p>{plandata.plan_name}</p>
                      </div>
                      <div className="col-md-3">
                        <Link to="/pricing">
                          {" "}
                          <button type="button" className="btn btn-primary">
                            Upgrade Now
                          </button>
                        </Link>
                      </div>
                    </div>

                    <hr />

                    <form className="pt-3">
                      <div className="form-row  mb-3">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Account Status</b>
                          </span>
                        </div>

                        <div className="col-md-4">
                          <span className="float-left">Active</span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Number of unique emails</b>
                          </span>
                        </div>

                        <div className="col-md-4">
                          <span className="float-left">
                            {plandata.number_of_emails}
                          </span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Number of emails per day </b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">
                            {plandata.number_of_emails_per_day}
                          </span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Number of emails per month</b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">
                            {plandata.number_of_emails_per_month}
                          </span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Monthly price </b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">
                            {plandata.plan_monthly_price}
                          </span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Last invoice date paid </b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">xxx</span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Amount paid </b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">xxx</span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Next invoice due date </b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">xxx</span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Amount due </b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">xxx</span>
                        </div>
                      </div>

                      <div className="form-row mb-3 ">
                        <div className="col-md-5">
                          <span className="float-left">
                            <b>Billing method </b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          <span className="float-left">xxx</span>
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <Link to="/change-billing">
                          {" "}
                          <button type="button" className="btn  btn-primary">
                            Change billing method
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  plan: state.plan.items.data,
  loading: state.plan.loading,
  error: state.plan.error,
});

export default connect(mapStateToProps, null)(Billing);
