import {
  FETCH_PLAN_BEGIN,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILURE,
  BASE_URL_NODE_LIVE,
  FETCH_APLAN_BEGIN,
  FETCH_APLAN_SUCCESS,
  FETCH_APLAN_FAILURE,
} from "./types";
import axios from "axios";
import { setAlert } from "./alert";
import { PAYMENT_FAILED } from "../utils/tooltip";
const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const fetchMYplanBegin = () => ({
  type: FETCH_PLAN_BEGIN,
});

export const fetchMYplanSuccess = (plan) => {
  return {
    type: FETCH_PLAN_SUCCESS,
    payload: { plan },
  };
};

export const fetchMYplanFailure = (error) => ({
  type: FETCH_PLAN_FAILURE,
  payload: { error },
});

export const fetchMyplandetail = (userdata) => {
  return (dispatch) => {
    dispatch(fetchMYplanBegin());
    return axios
      .post(apiUrlLive + "plan_details.php", qs.stringify(userdata))
      .then((response) => {
        dispatch(fetchMYplanSuccess(response.data));
      })
      .catch((error) => dispatch(fetchMYplanFailure(error)));
  };
};

export const fetchAllplanBegin = () => ({
  type: FETCH_APLAN_BEGIN,
});

export const fetchAllplanSuccess = (plans) => {
  return {
    type: FETCH_APLAN_SUCCESS,
    payload: { plans },
  };
};

export const fetchAllplanFailure = (error) => ({
  type: FETCH_APLAN_FAILURE,
  payload: { error },
});

export const fetchAllplandetail = (id) => {
  return (dispatch) => {
    dispatch(fetchAllplanBegin());
    return axios
      .post(
        apiUrlLive + "table_columnsdata.php",
        qs.stringify({ table_name: "plans" })
      )
      .then((response) => {
        dispatch(fetchAllplanSuccess(response.data));
        if (id === "1") {
          dispatch(setAlert(PAYMENT_FAILED, "error", "plan"));
        }
      })
      .catch((error) => dispatch(fetchAllplanFailure(error)));
  };
};
