import React, { Component } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { fetchSenderprofiles,deactiveprofile ,fetchSenderpre,setengagement} from "../actions/senderprofiles";
import { connect } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LoaderUi from "../shared/Loader";
import { fetchMyplandetail } from "../actions/plan";
import MAlert from "@material-ui/lab/Alert";
import BlockIcon from '@material-ui/icons/Block';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import {DEACTIVATED_PROFILE,UPDATE_SENDERPROFILE,VIEW_SENDERPROFILE,VIEW_INSTRUCTION,ACTIVATE_PROFILE,DEACTIVATE_PROFILE} from "../utils/message";
import Alert from "../layout/Alert";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import ListAltIcon from '@material-ui/icons/ListAlt';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AssessmentIcon from '@material-ui/icons/Assessment';




const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);


export class Senderprofile extends Component {
state = {
jwt: localStorage.token,
setengagement:this.props.dashdummy.slidervalue,
};



componentDidMount() {
window.scrollTo(0, 0);
this.props.dispatch(fetchSenderprofiles(this.state));
this.props.dispatch(fetchMyplandetail(this.state));
this.props.dispatch(fetchSenderpre(this.state));
}


handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value });
  };

onChangeStatus = (id,page)=> {
   
this.props.onDelete({
                id: id,
                jwt: localStorage.token,
                page:page
              });


}

    setengagementClick = (e) => {
    e.preventDefault();

this.props.onSetengagement({jwt:localStorage.token,'setting_name':'max_value','setting_value':this.state.setengagement});
  

  };






render() {
var {  loading, senderprofiles, plan, loading2,preset,loading3,loading4,loading6 } = this.props;


var userType= localStorage.getItem('userType');





if (loading || loading2 || loading3 || loading4 || loading6) {
return <LoaderUi />;
}


var data=senderprofiles.data;

var  hideaction=(this.props.user.status==='0')?'':1;






if(senderprofiles.data)
{
var activeresult = senderprofiles.data.filter(senderprofile => senderprofile.[6] ==="1" );



}



var plandata = plan ? plan[0] : "";
var totalasp = activeresult ? activeresult.length :'0';
var totalsp = senderprofiles.data ? senderprofiles.data.length :'0';


var buttonstatus = senderprofiles.data ? senderprofiles.data[0][5] : "";
var buttonstatus2= ((totalasp?totalasp:0)<(plandata.number_of_sender_profiles))?1:'';
 

var buttonstatus3= preset[5]?(preset[5].email.length==='0'?'':preset[5].email.length):'';




const ActionCustom = row => {

  return (
    <React.Fragment>
   {(hideaction && <div>


 
    {row[6]==='1' &&
    <React.Fragment>
   
     <HtmlTooltip
                        title={UPDATE_SENDERPROFILE}
                          placement="top-start"
                        >
                          <Link to={/updatesenderprofile/ + row[4]}>
    <EditIcon  style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}}/>
    </Link>
                        </HtmlTooltip>
   
   
    <HtmlTooltip
                        title={VIEW_SENDERPROFILE}
                          placement="top-start"
                        >
    <Link to={/viewsenderprofile/ +row[4]}>
    <VisibilityIcon style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}}/>
    </Link>
    </HtmlTooltip>
   
    <HtmlTooltip
                        title={VIEW_INSTRUCTION}
                          placement="top-start"
                        >
    <Link to={/engagementinstruction/ +row[4]}>
    <ListAltIcon style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}}/>
    </Link>
    </HtmlTooltip>
  
  
    <HtmlTooltip title={DEACTIVATE_PROFILE}
                          placement="top-start"
                        >
   



    <BlockIcon onClick={() => this.onChangeStatus(row[4],'senderprofile')}  style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}}  />
  </HtmlTooltip>
  
  
   
    </React.Fragment>
    }
   
    {row[6]==='0' &&
    <React.Fragment>
  
  
  {(buttonstatus2  &&
      <HtmlTooltip
                          title={ACTIVATE_PROFILE}
                            placement="top-start"
                          >
     
      <PlayArrowIcon onClick={() => this.onChangeStatus(row[4],'senderprofile')}  style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}}  />
    </HtmlTooltip>)}
  
    {((buttonstatus2==='') &&
      <HtmlTooltip
                          title={DEACTIVATED_PROFILE}
                            placement="top-start"
                          >
     
      <PlayArrowIcon classsName="senderprofileicon"  style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}}/>
    </HtmlTooltip>)}
  
  
    <HtmlTooltip title="View senderprofile" placement="top-start"
                        >
    <Link to={/viewsenderprofile/ + row[4]}>
    <VisibilityIcon classsName="senderprofileicon" style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}} />
    </Link>
    </HtmlTooltip>
    </React.Fragment>
    }
    </div> )}

    {(!hideaction && <div>


      <HtmlTooltip title="View senderprofile" placement="top-start"
                        >
    <Link to={/viewsenderprofile/ + row[4]}>
    <VisibilityIcon classsName="senderprofileicon" style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}} />
    </Link>
    </HtmlTooltip>


    

  

      </div> )}



      {(row[3]!=='NA' && <div>


<HtmlTooltip title="View Reports" placement="top-start"
                  >
<Link to={/reports/ + row[0]}>
<AssessmentIcon classsName="senderprofileicon" style={{'cursor': 'pointer','color':'#127ced','text-align':'center','marginRight':'3px'}} />
</Link>
</HtmlTooltip>






</div> )}



    </React.Fragment>





  )

};

const columns = [
  {
    name: 'Sender Profile Name',
    selector: '0',
    sortable: true,
    maxWidth: '120px',
  },
  {
    name: 'From Email',
    selector: '1',
    sortable: true,
    maxWidth: '120px',
  },
  {
    name: 'Date Created',
    selector: '2',
    maxWidth: '120px',
   
  },
  {
    name: 'First Email Date',
    selector: '9',
    maxWidth: '120px',
   
   
  },
  {
    name: 'Last Email Date',
    selector: '3',
    maxWidth: '120px',
   
  },
  {
    name: 'Status',
    selector: '7',
    sortable: true,
    maxWidth: '120px',

  },
  {
    name: ' Action',
    style: {
    minWidth: '180px !important',
    },
    cell: row => <ActionCustom {...row}  />
  },
 
  
];

const tableData = {
  columns,
  data,
  print:false,
  export:false,
  filterPlaceholder:'Search by sender profile name',
};





const conditionalRowStyles = [
  {
    when: row => row[8]%2==='0',
    style: {
      backgroundColor: '#d5f4fe',
      
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  {
    when: row =>row[8]%2==='1',
    style: {
      backgroundColor: '#FFF4E5',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  {
    when: row =>row[8]===-1,
    style: {
      backgroundColor: '#EEEEEE',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
];





return (
<div>
<div className="page-header">
<h3 className="page-title"> Sender Profiles </h3>
</div>

<div className="row">
<div className="col-12 grid-margin">
<div className="card">
<div className="card-body">
<div className="row grid-margin">




<p>
A Sender Profile enables you to define a set of attributes for
all sending you do from a particular from email or IP address.
It will also enable you to see your emails grouped as separate
broadcasts when viewing reports. The number of from emails and
sender profiles you can setup is limited by the plan you
chose.
</p>




<div className="col-md-4">
{((buttonstatus2 && buttonstatus3 && buttonstatus) || totalsp === '0') && (
<Link to="/createsenderprofile">
<button type="button" 
className=" btn btn-primary">
Create Sender Profile
</button>
</Link>
)}
</div>

{(userType==='Admin') && 
<React.Fragment>
<div className="col-md-2">
</div>
<div className="col-md-4">
<button type="button" 
className=" btn btn-primary float-right" onClick={this.setengagementClick}>Set Max Open/Engagement Rate</button>
</div>
<div className="col-md-2">
<input type="text" style={{'float':'right'}} className="form-control form-control float-right col-md-9" name="setengagement" onChange={this.handleInputChange} value={this.state.setengagement}  />
</div>
</React.Fragment>
}

<div className="col-md-12">
<p className="mt-2">
{" "}
Currently using {totalasp ? totalasp : "0"} out of{" "}
{plandata.number_of_sender_profiles} available profiles.
</p>
</div>
<div className="col-md-8"><Alert msgdivid="updatesenderp" style={{'marginLeft':'5px'}} /></div>



</div>


<div className="row">
<div className="col-md-12">
<DataTableExtensions {...tableData}>

<DataTable
    title={"Sender profiles"}
    columns={columns}
    data={senderprofiles.data}
    pagination
    noHeader
    defaultSortField="6"
    defaultSortAsc={false}
    conditionalRowStyles={conditionalRowStyles}
  />
</DataTableExtensions>


</div>
</div>

<div className="row grid-margin mt-4">
<div className="col-md-12">
{totalsp !== '0' && !buttonstatus && (
<MAlert severity="error">
You must have at least 1 sender email that is not being
used in another sender profile in order to create a new
one. To add another sender email see "My Emails" tab on
the left nav bar or{" "}
<Link
to="/senderemail"
style={{
color: "#000",
"text-decoration": "underline",
}}
>
click here{" "}
</Link>
</MAlert>
)}
</div>
{(process.env.REACT_APP_NAME_PRICING_BUTTON_ENABLE==='1') &&
<div className="col-md-5 mt-2">
<Link to={"/pricing"}>
<button type="button" 
className="btn btn-primary">
Upgrade for more profiles
</button>
</Link>
</div>}

</div>
</div>
</div>
</div>
</div>
</div>
);
}
}

const mapStateToProps = (state) => ({
senderprofiles: state.senderprofiles.items,
loading: state.senderprofiles.loading,
error: state.senderprofiles.error,
plan: state.plan.items.data,
loading2: state.plan.loading,
error2: state.plan.error,
preset: state.senderprofiles.items2,
loading3: state.senderprofiles.loading2,
error3: state.senderprofiles.error2,
createprofiles: state.senderprofiles.items4,
loading4: state.senderprofiles.loading4,
error4: state.senderprofiles.error4,
isAuthenticated: state.auth.isAuthenticated,
loading5: state.auth.loading,
user: state.auth.user,
loading6: state.senderprofiles.loading6,
error6: state.senderprofiles.error6,
dashdummy: state.dash.items8,
loading8: state.dash.loading8,
error8: state.dash.error8,

});


const mapDispatchToProps = dispatch => {
  return {
   onDelete: (userdata) => {
      dispatch(deactiveprofile(userdata));
    },
   onSetengagement: (userdata) => {
      dispatch(setengagement(userdata));
    },

    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Senderprofile);