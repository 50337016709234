import {
  FETCH_MYEMAIL_BEGIN,
  FETCH_MYEMAIL_SUCCESS,
  FETCH_MYEMAIL_FAILURE,
  BASE_URL_NODE_LIVE,
  SHUFFLE_BEGIN,
  SHUFFLE_SUCCESS,
  SHUFFLE_FAILURE,
} from "./types";
import axios from "axios";
import { fetchDummyData } from "./dashboard";
const apiUrlLive = BASE_URL_NODE_LIVE;
const qs = require("qs");

export const fetchMYemailsBegin = () => ({
  type: FETCH_MYEMAIL_BEGIN,
});

export const fetchMYemailsSuccess = (myemails) => {
  return {
    type: FETCH_MYEMAIL_SUCCESS,
    payload: { myemails },
  };
};

export const fetchMYemailsFailure = (error) => ({
  type: FETCH_MYEMAIL_FAILURE,
  payload: { error },
});

export const fetchMyemails = (userdata) => {
  return (dispatch) => {
    dispatch(fetchMYemailsBegin());
    return axios
      .post(apiUrlLive + "myemails.php", qs.stringify(userdata))
      .then((response) => {
        dispatch(fetchMYemailsSuccess(response.data));
      })
      .catch((error) => dispatch(fetchMYemailsFailure(error)));
  };
};

export const shuffleBegin = () => ({
  type: SHUFFLE_BEGIN,
});

export const shuffleSuccess = (shuffle) => {
  return {
    type: SHUFFLE_SUCCESS,
    payload: { shuffle },
  };
};

export const shuffleSuccessFailure = (error) => ({
  type: SHUFFLE_FAILURE,
  payload: { error },
});

export const shuffleseedemails = (keyword) => {
  return (dispatch) => {
    dispatch(shuffleBegin());
    return axios
      .post(apiUrlLive + "shuffle_seed_emails.php", qs.stringify(keyword))
      .then((response) => {
        dispatch(shuffleSuccess(response.data));
        dispatch(fetchDummyData(keyword));
      })
      .catch((error) => dispatch(shuffleSuccessFailure(error)));
  };
};
