import { combineReducers } from "redux";
import auth from "./authReducer";
import blockeddomain from "./blockedDomainReducer";
import dash from "./dashReducer";
import alert from "./alert";
import senderemails from "./senderEmailsReducer";
import myemails from "./myEmailsReducer";
import senderprofiles from "./senderProfilesReducer";
import plan from "./planReducer";
import reports from "./myReportsReducer";
import subjects from "./mySubjectReducer";
import broadcast from "./mybroadcastReducer";
import broadcastp from "./mybroadcastpReducer";
import invitecode from "./invitecodeReducer";

export default combineReducers({
  auth: auth,
  blockeddomain: blockeddomain,
  dash: dash,
  alert: alert,
  senderemails: senderemails,
  myemails: myemails,
  senderprofiles: senderprofiles,
  plan: plan,
  reports: reports,
  subjects: subjects,
  broadcast: broadcast,
  broadcastp: broadcastp,
  invitecode: invitecode,
});
