import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  ResponsiveContainer,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Spinner } from "reactstrap";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-22)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

class CustomTooltip extends PureComponent {
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;

      return (
        <div
          className="recharts-default-tooltip"
          style={{ border: "3px solid #fff", backgroundColor: "#fff" }}
        >
          <p className="intro">{label}</p>
          <p
            className="label"
            style={{ color: "#00d0bd" }}
          >{`Inbox: ${payload[0].value}%`}</p>
          <p
            className="label"
            style={{ color: "#5d92f4" }}
          >{`Primary Inbox: ${payload[1].value}%`}</p>
          <p
            className="label"
            style={{ color: "#ffb70f" }}
          >{`Promotions: ${payload[2].value}%`}</p>
          <p
            className="label"
            style={{ color: "#FF6358" }}
          >{`Spam: ${payload[3].value}%`}</p>
          <p className="label" style={{ color: "#000000" }}>{`Total Emails:${
            payload[0] ? payload[0].payload.totalemails : ""
          }`}</p>
        </div>
      );
    }

    return null;
  }
}

export default class MultilinereportchartContainter extends PureComponent {
  renderObject = (senderprofile) => {
    return Object.entries(senderprofile).map(([key, value], i) => {
      return <div key={key}>id is: {key}</div>;
    });
  };

  render() {
    var { allsenderprofile, senderprofile, selectedprofile } = this.props;

    if (!allsenderprofile) {
      return (
        <div>
          <Spinner
            color="primary"
            style={{ width: "3rem", height: "3rem", marginLeft: "50%" }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        {selectedprofile === "All" && (
          <React.Fragment>
            <h4>All Senderprofile</h4>
            <br />
            <ResponsiveContainer width="100%" height={360}>
              <LineChart
                data={allsenderprofile ? allsenderprofile : ""}
                margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  height={60}
                  tick={<CustomizedAxisTick />}
                />
                <YAxis tickFormatter={(tick) => `${tick}%`} />

                <Tooltip content={<CustomTooltip />} />
                <Legend />

                <Line type="monotone" dataKey="Inbox" stroke="#00d0bd" />
                <Line
                  type="monotone"
                  dataKey="Primary Inbox"
                  stroke="#5d92f4"
                />
                <Line type="monotone" dataKey="Promotions" stroke="#ffb70f" />
                <Line type="monotone" dataKey="Spam" stroke="#FF6358" />
              </LineChart>
            </ResponsiveContainer>
          </React.Fragment>
        )}

        {Object.entries(senderprofile).map(([key, value], i) => {
          if (key === selectedprofile || selectedprofile === "All") {
            return (
              <React.Fragment>
                <h4>{key}</h4>
                <br />
                <ResponsiveContainer width="100%" height={360}>
                  <LineChart
                    data={senderprofile[key] ? senderprofile[key] : ""}
                    margin={{ top: 5, right: 30, left: 20, bottom: 25 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      height={60}
                      tick={<CustomizedAxisTick />}
                    />
                    <YAxis tickFormatter={(tick) => `${tick}%`} />

                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Line type="monotone" dataKey="Inbox" stroke="#00d0bd" />
                    <Line
                      type="monotone"
                      dataKey="Primary Inbox"
                      stroke="#5d92f4"
                    />
                    <Line
                      type="monotone"
                      dataKey="Promotions"
                      stroke="#ffb70f"
                    />
                    <Line type="monotone" dataKey="Spam" stroke="#FF6358" />
                  </LineChart>
                </ResponsiveContainer>
              </React.Fragment>
            );
          } else {
            return null;
          }
        })}
      </React.Fragment>
    );
  }
}
