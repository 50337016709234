import {
  SET_CURRENT_USER,
  LOGIN,
  LOGIN_SUBMIT,
  LOGOUT,
  LOGIN_FAIL,
  USER_REG_BEGIN,
  USER_REG_SUCCESS,
  USER_REG_FAILURE,
  USER_REGCOM_BEGIN,
  USER_REGCOM_SUCCESS,
  USER_REGCOM_FAILURE,
  USER_REGRESET_STATE,
  USER_REGRESETC_STATE,
} from "../actions/types";

const initialState = {
  loading: "",
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  user: {},
  items: [],
  items2: [],
  loading2: "",
};

export default function signupReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case LOGIN_SUBMIT:
      return {
        ...state,
        loading: true,
      };

    case LOGIN:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };

    case USER_REG_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        items: [],
      };
    case USER_REG_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.reg,
      };
    case USER_REG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case USER_REGCOM_BEGIN:
      return {
        ...state,
        loading2: true,
        error2: null,
        items2: [],
      };
    case USER_REGCOM_SUCCESS:
      return {
        ...state,
        loading2: false,
        items2: action.payload.reg,
      };
    case USER_REGCOM_FAILURE:
      return {
        ...state,
        loading2: false,
        error2: action.payload.error,
        items2: [],
      };

    case USER_REGRESETC_STATE:
      return {
        ...state,
        loading2: false,
        error2: null,
        items2: [],
        loading: false,
        error: null,
        items: [],
      };
    case USER_REGRESET_STATE:
      return {
        ...state,
        loading: false,
        error: null,
        items: [],
        loading2: false,
        error2: null,
        items2: [],
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: "",
      };

    default:
      return state;
  }
}
