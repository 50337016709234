import React, { Component } from "react";
import ViewSenderprofile from "./ViewSenderprofile";
import { fetchUSenderpre } from "../actions/senderprofiles";
import { connect } from "react-redux";
import { fetchSenderprofiles } from "../actions/senderprofiles";
import LoaderUi from "../shared/Loader";
import {  Redirect } from "react-router-dom";
import { fetchMyplandetail } from "../actions/plan";
class UpdateSenderprofileContainter extends Component {

state = {
   jwt:localStorage.token,
   id:this.props.match.params.id
 };

 componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchUSenderpre(this.state));
    this.props.dispatch(fetchSenderprofiles(this.state));
    this.props.dispatch(fetchMyplandetail(this.state));

  }


buildname =(senderprofiles) => {

var defualt = 'Untitled';

if (senderprofiles && senderprofiles.length > 0) {
for (let i =1; i <=10; i++) {
if (senderprofiles.includes(defualt)) {

}
else
{
return defualt;
}
defualt='Untitled'+i;
}
}
}



buildsparr =(senderprofiles) => {
var arr = [];
if (senderprofiles && senderprofiles.length > 0) {
for (let i = 0; i < senderprofiles.length; i++) {
            arr.push(senderprofiles[i]?senderprofiles[i][0]:'');
  }
}
return arr;
 }




  render() {
var { loading, preset,loading2,loading4,createprofiles,redirecttoview,senderprofiles,plan,loading5,loading6 } = this.props;

var userstatus=(this.props.user.status
==='0')?'':1;

if(senderprofiles.data)
{
var activeresult = senderprofiles.data.filter(senderprofile => senderprofile.[6] ==="1" ); 
}
var plandata = plan ? plan[0] : "";
var totalasp = activeresult ? activeresult.length :'0';
//var totalsp = senderprofiles.data ? senderprofiles.data.length :'0';
var buttonstatus= ((totalasp?totalasp:0)<(plandata.number_of_sender_profiles))?1:'';




if(senderprofiles.data)
{
var currentsenderprofile = senderprofiles.data.filter(senderprofile => senderprofile.[4] ===this.props.match.params.id ); 
var currentstatus=currentsenderprofile[0][6];

}

 
 if(loading || loading2 || loading4 || loading5 || loading6) {

     return (<LoaderUi/>) 
    }

if(createprofiles &&  redirecttoview) {

return <Redirect to={/engagementinstruction/+this.props.match.params.id} />;
 }

var senderprofilesarr=this.buildsparr(senderprofiles.data);
var defultbuildname=this.buildname(senderprofilesarr);


if(preset[0] && preset[4])
{

var totalemail=(preset[0][1].totalemail);
var scrolldown=(preset[5][6].scrolldown);
var spaminbox=(preset[5][6].spaminbox);
var promotoinbox=(preset[5][6].promotoinbox);
var reply=(preset[5][6].reply);
var clicklink=(preset[5][6].clicklink);
var marksimp=(preset[5][6].marksimp);
var emailpercentage=(preset[5][6].emailpercentage);
var linkname=(preset[5][6].linkname==='random' && preset[5][6].linkrandom==="true"?'':preset[5][6].linkname);
var linkrandam=(preset[5][6].linkrandom==="true"?true:false);
var name=(preset[5][6].profilename);
var fromemail=(preset[5][6].fromemail);
var engagement=(preset[5][6].engagement);

}



const defaultdata = {totalemail: totalemail,scrolldown:scrolldown,spaminbox:spaminbox,promotoinbox:promotoinbox,reply:reply,clicklink:clicklink,marksimp:marksimp,emailpercentage:emailpercentage,
linkname:linkname,linkrandam:linkrandam,name:name,fromemail:fromemail,engagement:engagement};




    

    return (
      <ViewSenderprofile userstatus={userstatus} buttonstatus={buttonstatus} currentstatus={currentstatus} preset={preset} defaultdata={defaultdata}  senderprofiles={senderprofilesarr} defultbuildname={defultbuildname}  id={this.props.match.params.id} />
    );
  }
}


const mapStateToProps = (state) => ({
  preset: state.senderprofiles.items5,
  loading: state.senderprofiles.loading5,
  error: state.senderprofiles.error5,
  senderprofiles: state.senderprofiles.items,
  loading2: state.senderprofiles.loading,
  error2: state.senderprofiles.error,
  createprofiles: state.senderprofiles.items4,
  loading4: state.senderprofiles.loading4,
  error4: state.senderprofiles.error4,
  redirecttoview: state.senderprofiles.redirecttoview,
  plan: state.plan.items.data,
  loading5: state.plan.loading,
  error5: state.plan.error,
  isAuthenticated: state.auth.isAuthenticated,
  loading6: state.auth.loading,
  user: state.auth.user,



});

export default connect(mapStateToProps, null)(UpdateSenderprofileContainter);





