import React, { Component } from "react";
import UpdateSenderprofile from "./UpdateSenderprofile";
import { fetchUSenderpre } from "../actions/senderprofiles";
import { connect } from "react-redux";
import { fetchSenderprofiles } from "../actions/senderprofiles";
import LoaderUi from "../shared/Loader";
import { Redirect } from "react-router-dom";
import { fetchDummyData } from "../actions/dashboard";

class UpdateSenderprofileContainter extends Component {
  state = {
    jwt: localStorage.token,
    id: this.props.match.params.id,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchUSenderpre(this.state));
    this.props.dispatch(fetchSenderprofiles(this.state));
    this.props.dispatch(fetchDummyData(this.state));
  }

  buildname = (senderprofiles) => {
    var defualt = "Untitled";

    if (senderprofiles && senderprofiles.length > 0) {
      for (let i = 1; i <= 10; i++) {
        if (senderprofiles.includes(defualt)) {
        } else {
          return defualt;
        }

        defualt = "Untitled" + i;
      }
    }
  };

  buildsparr = (senderprofiles) => {
    var arr = [];
    if (senderprofiles && senderprofiles.length > 0) {
      for (let i = 0; i < senderprofiles.length; i++) {
        arr.push(senderprofiles[i] ? senderprofiles[i][0] : "");
      }
    }
    return arr;
  };

  render() {
    var {
      loading,
      preset,
      loading2,
      loading4,
      createprofiles,
      redirecttoview,
      senderprofiles,
      dashdummy,
    } = this.props;

    if (loading || loading2 || loading4) {
      return <LoaderUi />;
    }

    if (createprofiles && redirecttoview) {
      return (
        <Redirect
          to={/engagementinstruction/ + this.props.match.params.id + "/2"}
        />
      );
    }

    var senderprofilesarr = this.buildsparr(senderprofiles.data);
    var defultbuildname = this.buildname(senderprofilesarr);

    if (preset[0] && preset[4]) {
      var totalemail = preset[0][1].totalemail;
      var scrolldown = preset[5][6].scrolldown;
      var spaminbox = preset[5][6].spaminbox;
      var promotoinbox = preset[5][6].promotoinbox;
      var reply = preset[5][6].reply;
      var clicklink = preset[5][6].clicklink;
      var marksimp = preset[5][6].marksimp;
      var emailpercentage = preset[5][6].emailpercentage;
      var linkname =
        preset[5][6].linkname === "random" && preset[5][6].linkrandom === "true"
          ? ""
          : preset[5][6].linkname;
      var linkrandam = preset[5][6].linkrandom === "true" ? true : false;
      var name = preset[5][6].profilename;
      var fromemail = preset[5][6].fromemail;
      var engagement = preset[5][6].engagement;
    }

    const defaultdata = {
      totalemail: totalemail,
      scrolldown: scrolldown,
      spaminbox: spaminbox,
      promotoinbox: promotoinbox,
      reply: reply,
      clicklink: clicklink,
      marksimp: marksimp,
      emailpercentage: emailpercentage,
      linkname: linkname,
      linkrandam: linkrandam,
      name: name,
      fromemail: fromemail,
      engagement: engagement,
    };

    return (
      <UpdateSenderprofile
        dashdummy={dashdummy}
        preset={preset}
        defaultdata={defaultdata}
        senderprofiles={senderprofilesarr}
        defultbuildname={defultbuildname}
        id={this.props.match.params.id}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  preset: state.senderprofiles.items5,
  loading: state.senderprofiles.loading5,
  error: state.senderprofiles.error5,
  senderprofiles: state.senderprofiles.items,
  loading2: state.senderprofiles.loading,
  error2: state.senderprofiles.error,
  createprofiles: state.senderprofiles.items4,
  loading4: state.senderprofiles.loading4,
  error4: state.senderprofiles.error4,
  redirecttoview: state.senderprofiles.redirecttoview,
  dashdummy: state.dash.items8,
  loading8: state.dash.loading8,
  error8: state.dash.error8,
});

export default connect(mapStateToProps, null)(UpdateSenderprofileContainter);
