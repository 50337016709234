import {
  FETCH_BLOCKED_DOMAIN_BEGIN,
  FETCH_BLOCKED_DOMAIN_SUCCESS,
  FETCH_BLOCKED_DOMAIN_FAILURE,
} from "../actions/types";
const initialState = {
  items: [],
  error: null,
};

export default function blockedDomainReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BLOCKED_DOMAIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_BLOCKED_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.blockeddomain,
      };

    case FETCH_BLOCKED_DOMAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
