import React from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Alert from "../layout/Alert";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

class AutomateSenderprofile extends React.PureComponent<{}, IBarGaugeProps> {
  state = {
    name: "",
    fromemail: "",
    jwt: localStorage.token,
    rowscount: "",
    rampup: true,
    rampupschedule: "",
    timespan: "",
    template_id: 1,
    rampup_email_count: 1000,
    rampupdays: 7,
    email_count: 0,
    timetosend: new Date(),
    validationErrors: {},
  };

  validators = {
    timetosend: (str) => (str === "" ? "Please Enter time" : ""),
    timespan: (str) => (str === "" ? "Select timespan" : ""),
  };

  validate = (name) => {
    const value = this.state[name];
    let error = this.validators.hasOwnProperty(name)
      ? this.validators[name](value)
      : "";
    this.setState(({ validationErrors }) => ({
      validationErrors: { ...validationErrors, [name]: error },
    }));
    return error;
  };

  handleInputChange = (e) => {
    const fieldName = e.currentTarget.name;
    this.setState({ [fieldName]: e.currentTarget.value }, () =>
      this.validate(fieldName)
    );
  };

  handleInputRChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
      rampup: e.target.checked,
    });

    if (e.target.checked) {
      this.setState({
        rampup_email_count: 1000,
        rampupdays: 7,
        email_count: 0,
      });
    } else {
      this.setState({
        email_count: 1000,
        rampup_email_count: 0,
        rampupdays: 0,
      });
    }
  };

  handletimeChange = (date) => {
    this.setState({
      timetosend: date,
    });
  };

  buildOptions = (myemail) => {
    var arr = [];

    if (myemail && myemail.length > 0) {
      for (let i = myemail.length; i > 0; i--) {
        arr.push(
          <option value={i} key={i}>
            {i}
          </option>
        );
      }
    }
    return arr;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const isValid = Object.keys(this.validators)
      .map(this.validate)
      .every((err) => !err);
    if (isValid) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    var { myemails } = this.props;

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title"> Automate Sender Profile</h3>
          <Link to="/senderprofiles" className="btn btn-primary float-right">
            Back to Sender Profiles
          </Link>
        </div>

        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card mycard">
              <div className="card-body">
                <div className="row ">
                  <div className="col-8 mb-1">
                    <h4>Add SMTP details</h4>

                    <Alert msgdivid="smtpform" />
                  </div>

                  <div className="col-md-12">
                    <form className="pt-3">
                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="hostname"
                            placeholder="Enter Hostname"
                            onChange={this.handleInputChange}
                            value={this.state.hostname}
                          />
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.hostname}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="userfromname"
                            placeholder="Enter Sender Email Name (From Name)"
                            onChange={this.handleInputChange}
                            value={this.state.userfromname}
                          />
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.userfromname}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="username"
                            placeholder="Enter Username"
                            onChange={this.handleInputChange}
                            value={this.state.username}
                          />
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.username}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="fromemail1"
                            placeholder="Enter Sender Email"
                            value={this.state.fromemail}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            name="password"
                            placeholder="Enter Password"
                            onChange={this.handleInputChange}
                            value={this.state.password}
                          />
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.password}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <select
                            className="form-control form-control"
                            name="provider"
                            onChange={this.handleInputChange}
                            defaultValue={this.state.protocol}
                          >
                            <option>Select Email Provider</option>
                            <option value="Google Workspace">
                              Google Workspace
                            </option>
                            <option value="Office 365">Office 365</option>
                            <option value="Other">Other</option>
                          </select>

                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.provider}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          <select
                            className="form-control form-control"
                            name="protocol"
                            onChange={this.handleInputChange}
                            defaultValue={this.state.protocol}
                          >
                            <option>Select Protocol</option>
                            <option value="none">None</option>
                            <option value="ssl">SSL</option>
                            <option value="tls">TLS</option>
                          </select>

                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.protocol}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="port"
                            placeholder="Enter Port"
                            onChange={this.handleInputChange}
                            value={this.state.port}
                          />
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.port}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-8">
                    <h4>Question</h4>
                  </div>

                  <div className="col-md-12">
                    <form className="pt-3" autoComplete="new-password">
                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          Will you like to ramp up?
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <Typography component="div">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>Off</Grid>
                              <Grid item>
                                <AntSwitch
                                  checked={this.state.rampup}
                                  onChange={this.handleInputRChange}
                                  name="rampup"
                                />
                              </Grid>
                              <Grid item>On</Grid>
                            </Grid>
                          </Typography>
                        </div>
                      </div>

                      {this.state.rampup && (
                        <div className="row mt-3">
                          <div className="col-md-6 col-sm-12">
                            Please choose ramp-up schedule .{" "}
                            <HtmlTooltip
                              title="Please choose ramp-up schedule"
                              placement="top-start"
                            >
                              <span className="h1lineheight">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <select
                              className="form-control form-control-lg"
                              name="rampupdays"
                              onChange={this.handleInputChange}
                            >
                              <option value="7">7 Days rampup </option>
                              <option value="15">15 Days rampup </option>
                              <option value="30">30 Days rampup </option>
                              <option value="45">45 Days rampup </option>
                            </select>
                          </div>
                        </div>
                      )}

                      {this.state.rampup && (
                        <div className="row mt-3">
                          <div className="col-md-6 col-sm-12">
                            How many emails do you want to ramp up to ?{" "}
                            <HtmlTooltip
                              title="How many emails do you want to ramp up to ?"
                              placement="top-start"
                            >
                              <span className="h1lineheight">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <select
                              name="rampup_email_count"
                              className="form-control"
                              onChange={this.handleInputChange}
                            >
                              {this.buildOptions(myemails.data)}
                            </select>
                          </div>
                        </div>
                      )}

                      {!this.state.rampup && (
                        <div className="row mt-3">
                          <div className="col-md-6 col-sm-12">
                            How many emails do you want to send ?{" "}
                            <HtmlTooltip
                              title="How many emails do you want to send ?"
                              placement="top-start"
                            >
                              <span className="h1lineheight">
                                <InfoOutlinedIcon className="toottipcls" />
                              </span>
                            </HtmlTooltip>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <select
                              className="form-control form-control-lg"
                              name="email_count"
                              onChange={this.handleInputChange}
                            >
                              {this.buildOptions(myemails.data)}
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          At what time your email should go out{" "}
                          <HtmlTooltip
                            title="At what time your email should go out"
                            placement="top-start"
                          >
                            <span className="h1lineheight">
                              <InfoOutlinedIcon className="toottipcls" />
                            </span>
                          </HtmlTooltip>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <DatePicker
                            name="timetosend"
                            className="form-control form-control-lg"
                            selected={this.state.timetosend}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            autoComplete="none"
                            onChange={this.handletimeChange}
                          />
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.timetosend}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6 col-sm-12">
                          Desired sending timespan ?{" "}
                          <HtmlTooltip title="TimeSpan" placement="top-start">
                            <span className="h1lineheight">
                              <InfoOutlinedIcon className="toottipcls" />
                            </span>
                          </HtmlTooltip>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <select
                            className="form-control form-control-lg"
                            name="timespan"
                            onChange={this.handleInputChange}
                          >
                            <option>Select a timespan</option>
                            <option value="30">30 min</option>
                            <option value="60">1 hr</option>
                            <option value="120">2 hr</option>
                            <option value="150">2.5 hr</option>
                          </select>
                          <div style={{ fontSize: 14, color: "red" }}>
                            {this.state.validationErrors.timespan}
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-md-12 col-sm-12 text-right">
                          {this.props.text}

                          <div className="mt-3 text-right">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              onClick={this.handleSubmit}
                            >
                              Validate and Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(AutomateSenderprofile);
