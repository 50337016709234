import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="content-wrapper">
          <div className="card ">
            <div className="card-body text-xs-right">
              <div className="text-xs-right text-sm-center">
                <span className="pull-right">
                  Copyright © 2021 All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
