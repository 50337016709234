import React, { Component } from "react";
import CreateSenderprofile from "./CreateSenderprofile";
import { fetchSenderpre } from "../actions/senderprofiles";
import { connect } from "react-redux";
import { fetchSenderprofiles } from "../actions/senderprofiles";
import LoaderUi from "../shared/Loader";
import { Redirect } from "react-router-dom";
import { fetchDummyData } from "../actions/dashboard";

class CreateSenderprofileContainter extends Component {
  state = {
    jwt: localStorage.token,
    senderemail: this.props.match.params.id,
    id: this.props.match.params.q ? this.props.match.params.q : "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchSenderpre(this.state));
    this.props.dispatch(fetchSenderprofiles(this.state));
    this.props.dispatch(fetchDummyData(this.state));
  }

  buildname = (senderprofiles) => {
    var defualt = "Untitled";

    if (senderprofiles && senderprofiles.length > 0) {
      for (let i = 1; i <= 10; i++) {
        if (senderprofiles.includes(defualt)) {
        } else {
          return defualt;
        }
        defualt = "Untitled" + i;
      }
    }
  };

  buildsparr = (senderprofiles) => {
    var arr = [];
    if (senderprofiles && senderprofiles.length > 0) {
      for (let i = 0; i < senderprofiles.length; i++) {
        arr.push(senderprofiles[i] ? senderprofiles[i][0] : "");
      }
    }
    return arr;
  };

  render() {
    var {
      loading,
      preset,
      loading2,
      loading4,
      createprofiles,
      redirecttoview,
      senderprofiles,
      loading8,
      dashdummy,
    } = this.props;

    if (loading || loading2 || loading4 || loading8) {
      return <LoaderUi />;
    }

    if (createprofiles && redirecttoview) {
      return (
        <Redirect to={/engagementinstruction/ + createprofiles.id + "/1"} />
      );
    }

    var senderprofilesarr = this.buildsparr(senderprofiles.data);
    var defultbuildname = this.buildname(senderprofilesarr);

    if (preset[0]) {
      var totalemail = preset[0][1].totalemail;
      var scrolldown = preset[0][1].scrolldown;
      var spaminbox = preset[0][1].spaminbox;
      var promotoinbox = preset[0][1].promotoinbox;
      var reply = preset[0][1].reply;
      var clicklink = preset[0][1].clicklink;
      var marksimp = preset[0][1].marksimp;
      var emailpercentage = preset[0][1].emailpercentage;
    }

    const defaultdata = {
      totalemail: totalemail,
      scrolldown: scrolldown,
      spaminbox: spaminbox,
      promotoinbox: promotoinbox,
      reply: reply,
      clicklink: clicklink,
      marksimp: marksimp,
      emailpercentage: emailpercentage,
    };

    return (
      <CreateSenderprofile
        dashdummy={dashdummy}
        senderemail={this.state.senderemail}
        preset={preset}
        defaultdata={defaultdata}
        senderprofiles={senderprofilesarr}
        defultbuildname={defultbuildname}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  preset: state.senderprofiles.items2,
  loading: state.senderprofiles.loading2,
  error: state.senderprofiles.error2,
  senderprofiles: state.senderprofiles.items,
  loading2: state.senderprofiles.loading,
  error2: state.senderprofiles.error,
  createprofiles: state.senderprofiles.items4,
  loading4: state.senderprofiles.loading4,
  error4: state.senderprofiles.error4,
  redirecttoview: state.senderprofiles.redirecttoview,
  dashdummy: state.dash.items8,
  loading8: state.dash.loading8,
  error8: state.dash.error8,
});

export default connect(mapStateToProps, null)(CreateSenderprofileContainter);
